/*----------------------------------------*/
/*  11. CURSOR CSS START
/*----------------------------------------*/


.cursor {
	position: fixed;
	left: 0;
	pointer-events: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
	color: var(--white);
	background: var(--black-2);
	text-transform: capitalize;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	border-radius: 100%;
	transform: translate(-50%, -50%);
	z-index: 999;
	opacity: 0;
	mix-blend-mode: hard-light;
	transition: all 0.3s;

	&.large {
		width: 180px;
		height: 180px;
		text-align: center;
		font-size: 19px;
		font-weight: 400;
	}
}


.cursor1 {
	position: fixed;
	width: 40px;
	height: 40px;
	border: 1px solid var(--primary);
	border-radius: 50%;
	left: 0;
	top: 0;
	pointer-events: none;
	transform: translate(-50%, -50%);
	transition: 0.15s;
	z-index: 999;
	// mix-blend-mode: difference;

	@media (max-width: 1200px) {
		display: none;
	}

	&.hide {
		opacity: 0;
		visibility: hidden;
	}
}

.cursor2 {
	position: fixed;
	width: 8px;
	height: 8px;
	background-color: var(--primary);
	border-radius: 50%;
	left: 0;
	top: 0;
	pointer-events: none;
	transform: translate(-50%, -50%);
	transition: 0.2s;
	z-index: 999;
	// mix-blend-mode: difference;

	@media (max-width: 1200px) {
		display: none;
	}

	&.hide {
		opacity: 0;
		visibility: hidden;
	}

	&.circle {
		width: 60px;
		height: 60px;
	}
}

.cursor-testi {
	position: fixed;
	width: 80px;
	height: 80px;
	background-color: var(--black-2);
	border-radius: 50%;
	left: 0;
	top: 0;
	pointer-events: none;
	transform: translate(-50%, -50%);
	transition: 0.2s;
	z-index: 999;

	@media (max-width: 1200px) {
		display: none;
	}

	&.hide {
		opacity: 0;
		visibility: hidden;
	}

	&.play {
		width: 100px;
		height: 100px;
		mix-blend-mode: unset;
		background-color: var(--black-2);

		&::after {
			position: absolute;
			content: "Play";
			color: var(--white);
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}


#featured_cursor {
	background: var(--white);
	color: var(--black-2);
}

#client_cursor {
	@media (max-width: 1200px) {
		display: none;
	}
}