/*----------------------------------------*/
/*  36. 404 CSS START
/*----------------------------------------*/



.error {
  &__content {
    padding-top: 80px;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
h1{
  font-size: 200px;
  font-weight: 900;
}
    @media #{$md} {
      padding-top: 100px;
    }

    &::before {
      position: absolute;
      content: "";
      width: 38px;
      height: 38px;
      top: 70%;
      left: 10%;

      background-repeat: no-repeat;
      animation: wcZoom 2s infinite;
    }

    &::after {
      position: absolute;
      content: "";
      width: 38px;
      height: 38px;
      top: 10%;
      right: 10%;

      background-repeat: no-repeat;
      animation: wcZoom 2s infinite;

      @media #{$md} {
        top: 20%;
      }

      @media #{$sm} {
        top: 15%;
      }
    }

    h2 {
      font-weight: 500;
      font-size: 36px;
      line-height: 1.5;
      color: var(--black-2);
      padding-top: 25px;
      padding-bottom: 10px;

      @media #{$sm} {
        font-size: 30px;
        line-height: 1.3;
      }
    }

    p {
      max-width: 340px;
      margin: 0 auto;
      font-size: 18px;
      line-height: 1.4;
      padding-bottom: 50px;

      @media #{$md} {
        padding-bottom: 30px;
      }

      @media #{$sm} {
        padding-bottom: 30px;
      }
    }

    a {
      margin: 0 auto;
    }

    img {
      max-width: 100%;
    }

    #btn_wrapper,
    .btn_wrapper {
      margin-left: 0;
      margin-top: -40px;

      @media (max-width: 1400px) {
        margin-top: 0;
      }
    }
  }
}