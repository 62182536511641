/*----------------------------------------*/
/*  33. FEATURE CSS START
/*----------------------------------------*/


.feature {
  &__area {
    &-2 {
      background-color: var(--pink);
    }
  }

  &__top {
    padding-bottom: 65px;
    border-bottom: 1px solid var(--black);

    @media #{$sm} {
      border-bottom: none;
    }
  }

  &__btm {
    @media #{$sm} {
      padding-bottom: 15px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 85px;

    @media #{$xl} {
      padding-left: 70px;
    }

    @media #{$lg} {
      padding-left: 30px;
    }

    @media #{$md} {
      padding-left: 0;
    }

    @media #{$sm} {
      padding-left: 0;
      padding-top: 30px;
    }

    p {
      font-size: 18px;
      line-height: 1.4;
      color: var(--black-2);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @media #{$md} {
      grid-template-columns: 1fr 1fr;
      gap: 0;
    }

    @media #{$sm} {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    border-right: 1px solid var(--black-2);
    padding: 145px 30px;

    @media #{$xl} {
      padding: 90px 20px;
    }

    @media #{$lg} {
      padding: 90px 10px;
    }

    @media #{$md} {
      padding: 50px 30px;
      border-right: none;
    }

    @media #{$sm} {
      border-right: none;
      padding: 0 0 50px 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
      padding-right: 0;
    }

    @media #{$md} {
      &:nth-child(odd) {
        border-right: 1px solid var(--black-2);
      }
    }

    img {
      margin-bottom: 35px;
    }

    p {
      color: var(--black-2);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 1.4;
    color: var(--black-2);
    padding-bottom: 12px;
  }



}