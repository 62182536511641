@charset "UTF-8";
/*-----------------------------------------------------------------------------------

  Theme Name: Axtra - Digital Agency HTML5 Template
  Author: Crowdyflow
  Support: habib.crowdyflow@gmail.com
  Description: BUILDY – is a  Constructor HTML5 template that comes with high-quality 3 Pre build Home Pages and many built-in awesome inner pages  which are perfect for any creative agencies, designers, digital agencies, freelancers, developers, designers, marketing, startup, blog and portfolio.
  Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

  01. VARIBALES CSS START
  02. MIXIN CSS START
  03. SPACE CSS START
  04. ANIMATION CSS START
  05. THEME CSS START
  06. THEME DARK CSS START
  07. BUTTON CSS START
  08. TITLE CSS START
  09. MENU CSS START
  10. MODAL CSS START
  11. CURSOR CSS START
  12. HEADER CSS START
  13. OFFCANVAS CSS START
  14. HERO CSS START
  15. SLIDER CSS START
  16. ABOUT CSS START
  17. SERVICE CSS START
  18. COUNTER CSS START
  19. WORKFLOW CSS START
  20. BRAND CSS START
  21. TESTIMONIAL CSS START
  22. PORTFOLIO CSS START
  23. PORTFOLIO 2 CSS START
  24. BLOG CSS START
  25. CTA CSS START
  26. STORY CSS START
  27. TEAM CSS START
  28. SOLUTION CSS START
  29. DEVELOPMENT CSS START
  30. AWARD CSS START
  31. FAQ CSS START
  32. CAREER CSS START
  33. FEATURE CSS START
  34. CONTACT CSS START
  35. PRICE CSS START
  36. ERROR CSS START
  37. HOME 5 CSS START
  38. FOOTER CSS START
**********************************************/
/*----------------------------------------*/
/*  01. VARIBALES CSS START
/*----------------------------------------*/
:root {
  --primary: #f7cb3c;
  --secondary: #2c2c2c;
  --black: #000;
  --black-2: #121212;
  --black-3: #555;
  --black-4: #1d1d1d;
  --black-5: #343434;
  --black-6: #262626;
  --black-7: #555555;
  --black-13: #666;
  --white: #fff;
  --white-2: #efefef;
  --white-3: #e9e9e9;
  --white-4: #f0f0f0;
  --white-5: #fbfbfb;
  --white-6: #d7d7d7;
  --gray: #c2c2c2;
  --gray-2: #999;
  --gray-3: #a8a8a8;
  --gray-4: #f6f6f6;
  --gray-5: #bbb;
  --gray-6: #2b2b2b;
  --gray-7: #b9b9b9;
  --gray-8: #8E8E8E;
  --gray-9: #aaa;
  --gray-10: #7c7c7c;
  --gray-11: #D9D9D9;
  --gray-12: #383838;
  --gray-13: #ccc;
  --gray-14: #dfdfdf;
  --gray-15: #C0C0C0;
  --pink: #FAEDE9;
  --pink-2: #FF9776;
  --pink-3: #F3ECEC;
  --pink-4: #FFFAF0;
  --pink-5: #E0E3CC;
  --bg-line: #1e1e1e;
  --d-gray: #6A6A6A;
  --d-black: #1A1A1A;
  --d-black-2: #171717;
  --d-black-3: #1A1A1A;
}

@font-face {
  font-family: newYork;
  src: url("/fonts/newyork/FontsFree-Net-NewYork.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Poppins;
  src: url("/fonts/poppins/Poppins-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-regular";
  src: url("/fonts/poppins/Poppins-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-medium";
  src: url("/fonts/poppins/Poppins-Medium.ttf");
  font-weight: 400;
  font-display: swap;
}
/*----------------------------------------*/
/*  02. MIXIN CSS START
/*----------------------------------------*/
/*----------------------------------------*/
/*  03. SPACE CSS START
/*----------------------------------------*/
.pb-10 {
  padding-bottom: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-100 {
  padding-bottom: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-100 {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-100 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 60px;
  }
}

.pb-110 {
  padding-bottom: 110px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-110 {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-110 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 60px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-130 {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-130 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .pb-130 {
    padding-bottom: 60px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-140 {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-140 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .pb-140 {
    padding-bottom: 60px;
  }
}

.pb-150 {
  padding-bottom: 150px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-150 {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-150 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .pb-150 {
    padding-bottom: 60px;
  }
}

.pb-200 {
  padding-bottom: 200px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-200 {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-200 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .pb-200 {
    padding-bottom: 60px;
  }
}

.pt-90 {
  padding-top: 90px;
}
@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-90 {
    padding-top: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-90 {
    padding-top: 70px;
  }
}

.pt-42 {
  padding-top: 40px;
}

.pt-100 {
  padding-top: 100px;
}
@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-100 {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-100 {
    padding-top: 90px;
  }
}

.pt-110 {
  padding-top: 110px;
}
@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-110 {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-110 {
    padding-top: 90px;
  }
}

.pt-120 {
  padding-top: 120px;
}
@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-120 {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-120 {
    padding-top: 90px;
  }
}

.pt-130 {
  padding-top: 130px;
}
@media only screen and (max-width: 767px) {
  .pt-130 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-130 {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-130 {
    padding-top: 90px;
  }
}

.pt-140 {
  padding-top: 140px;
}
@media only screen and (max-width: 767px) {
  .pt-140 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-140 {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-140 {
    padding-top: 90px;
  }
}

.pt-150 {
  padding-top: 150px;
}
@media only screen and (max-width: 767px) {
  .pt-150 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-150 {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-150 {
    padding-top: 90px;
  }
}

.sp-x {
  padding-left: 100px;
  padding-right: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sp-x {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sp-x {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .sp-x {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mt-60 {
  margin-top: 60px;
}

.ml-60 {
  margin-left: 60% !important;
}

.ml-45 {
  margin-left: 45% !important;
}

.ht-200 {
  padding-top: 200px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-200 {
    padding-top: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-200 {
    padding-top: 160px;
  }
}
@media only screen and (max-width: 767px) {
  .ht-200 {
    padding-top: 130px;
  }
}

.pr-20 {
  padding-right: 20px;
}

.pr-40 {
  padding-right: 40px;
}

@media only screen and (max-width: 767px) {
  .pb-sm-0 {
    padding-bottom: 0px;
  }
}
@media (max-width: 575px) {
  .pb-sm-0 {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-sm-20 {
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .pb-sm-20 {
    padding-bottom: 20px;
  }
}

/*----------------------------------------*/
/*  04. ANIMATION CSS START
/*----------------------------------------*/
@keyframes wcRotate {
  0% {
    transform: rotate(0deg) scale(1.1);
  }
  50% {
    transform: rotate(5deg) scale(1.17);
  }
  100% {
    transform: rotate(0deg) scale(1.1);
  }
}
@keyframes wcBubble {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.5;
  }
  100% {
    scale: 1;
  }
}
@keyframes wcZoom {
  0% {
    scale: 1;
  }
  50% {
    scale: 0.5;
  }
  100% {
    scale: 1;
  }
}
@keyframes wcSlideBottom {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes reveal {
  to {
    opacity: 1;
    filter: blur(0px);
  }
}
@keyframes wcfadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes wcSpinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*----------------------------------------*/
/*  05. THEME CSS START
/*----------------------------------------*/
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins";
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0;
  font-family: "Poppins-medium";
}

ul,
ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  position: relative;
  z-index: 1;
}

a {
  text-decoration: none;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
}

button {
  background-color: transparent;
  border: 0;
}

p {
  padding: 0;
  margin: 0;
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
  color: var(--black-3);
  position: relative;
  z-index: 1;
}

video,
iframe,
img {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
}

section {
  padding: 100px 0;
}

b {
  font-weight: 600;
  font-family: "Poppins-medium";
}

.line {
  position: relative;
}
.line::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--white-4);
}
.line::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: calc(50% - 1px);
  top: 0;
  background: var(--white-4);
}
.line-3 {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  right: 0;
  top: 0;
  background: var(--white-4);
}

.shape {
  position: absolute;
  left: -90px;
  bottom: -350px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shape {
    left: 200%;
    bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .shape {
    left: 70%;
    bottom: 0;
  }
}
.shape .primary {
  width: 54px;
  height: 56px;
  background-color: var(--primary);
  margin-top: -37px;
  margin-left: 24px;
}
.shape .secondary {
  width: 53px;
  height: 56px;
  background-color: var(--secondary);
}

.pos-inherit {
  position: inherit;
}

@media only screen and (max-width: 767px) {
  .g-0 {
    padding-right: 15px;
    padding-left: 15px;
  }
  br {
    display: none;
  }
}
.sec-title-wrapper {
  position: relative;
  z-index: 9;
}

.footer-line {
  position: relative;
}
.footer-line::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 500px;
  left: 0;
  bottom: -400px;
  background: var(--white-4);
}
@media (max-width: 1023px) {
  .footer-line::after {
    height: 0;
  }
}

.scroll-top {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9991;
  border-radius: 100px;
  color: var(--white);
  background: var(--white);
  transition: all 0.3s;
  display: none;
  mix-blend-mode: exclusion;
}
.scroll-top i {
  font-size: 18px;
  color: var(--black-2);
}
.scroll-top:hover {
  color: var(--gray-2);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .scroll-top {
    bottom: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .scroll-top {
    right: 10px;
    bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .scroll-top {
    right: 5px;
    bottom: 55px;
  }
}

.logo-light {
  display: none;
}
.logo-dark {
  display: block;
}

.font-20 {
  font-size: 20px !important;
}

strong {
  font-family: "Poppins-medium";
  font-weight: 600;
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
  text-decoration: none;
}

.terms-content ul {
  padding-bottom: 10px;
  padding-top: 10px;
  display: block;
}
.terms-content ul li {
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.privacy-content ul {
  padding-bottom: 10px;
  padding-top: 10px;
  display: block;
}
.privacy-content ul li {
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.hidden-form {
  opacity: 0;
  visibility: hidden;
}

#msgSubmit {
  position: fixed;
  bottom: 55%;
  right: 10px;
  width: 370px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 25px 20px;
  z-index: 999;
}
#msgSubmit img {
  border-radius: 50%;
  position: absolute;
  left: 20px;
  width: 70px;
}
#msgSubmit h5 {
  font-size: 18px;
  color: #777;
  line-height: 23px;
  font-weight: 500;
  padding-bottom: 8px;
  padding-left: 80px;
  text-align: left;
}
#msgSubmit p {
  font-size: 15px;
  color: #777;
  line-height: 18px;
  font-weight: 400;
  font-style: italic;
  padding-bottom: 0;
  padding-left: 80px;
  text-align: left;
}
#msgSubmit i {
  font-size: 16px;
  color: #999;
  position: absolute;
  right: 15px;
  top: 15px;
  line-height: 10px;
  /* font-family: "Fontawesome"; */
  cursor: pointer;
  transition: all 0.2s ease 0s;
}
#msgSubmit i:hover {
  color: #c75c5c;
}

/*----------------------------------------*/
/*  06. THEME DARK CSS START
/*----------------------------------------*/
.dark {
  background-color: var(--black-2);
}
.dark .logo-light {
  display: block;
}
.dark .logo-dark {
  display: none;
}
.dark .portfolio__page section {
  margin-top: 0;
}
.dark .portfolio__page .wc-btn-dark:hover {
  color: var(--black-2);
  background-color: var(--white);
  border-color: var(--white);
}
.dark .wc-btn-primary, .dark .wc-btn-black, .dark .wc-btn-light, .dark .wc-btn-pink,
.dark .wc-btn-secondary {
  border-color: var(--secondary);
  color: var(--gray-2);
}
.dark .wc-btn-black:hover {
  border-color: var(--white);
  color: var(--black-2);
}
.dark .wc-btn-black:hover span {
  background-color: var(--white);
}
.dark .line::before,
.dark .line::after,
.dark .line-3 {
  background-color: var(--bg-line);
}
.dark p {
  color: var(--gray-2);
}
.dark .sec-title,
.dark .sec-title-2,
.dark .sec-title-3,
.dark .sec-sub-title {
  color: var(--white);
}
.dark .main-dropdown {
  background-color: var(--white);
}
.dark .main-dropdown .sub-dropdown {
  background-color: var(--white);
}
.dark .main-dropdown li a {
  color: var(--black-2) !important;
}
.dark .main-dropdown li a .menu-text {
  text-shadow: 0 16px 0 var(--black-2) !important;
}
.dark .main-dropdown li a:hover {
  letter-spacing: 1px;
}
.dark .mega-menu, .dark .mega-menu-2 {
  background-color: var(--white);
}
.dark .mega-menu a:not([href]), .dark .mega-menu-2 a:not([href]),
.dark .mega-menu a:not([href]):hover {
  color: var(--black-2) !important;
}
.dark .mega-menu li a, .dark .mega-menu-2 li a {
  color: var(--black-2) !important;
}
.dark .mega-menu li a .menu-text, .dark .mega-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--black-2) !important;
}
.dark .mega-menu li a:hover, .dark .mega-menu-2 li a:hover {
  letter-spacing: 1px;
}
.dark .header__area-3.sticky-3 {
  background-color: var(--black-2);
  border-color: var(--secondary);
}
.dark .header__area-5.sticky-5 {
  background-color: var(--black-2);
  border-color: var(--secondary);
}
.dark .hero__area-3 {
  background-color: var(--d-black-2);
}
.dark .hero__area-3::before {
  filter: invert(0.9);
}
.dark .hero__area-3 .sec-sub-title,
.dark .hero__area-3 .sec-title,
.dark .hero__area-3 .intro-title .video-title {
  color: var(--white);
}
.dark .hero__area-3 .scroll-down img {
  filter: invert(1);
}
.dark .hero__area-3 .sec-sub-title::after {
  background-color: var(--white);
}
.dark .hero__area-5 {
  background-color: var(--black-2);
}
.dark .hero__area-5 img {
  filter: invert(0);
}
.dark .hero__title-5, .dark .hero__content-5 p {
  color: var(--white);
}
.dark .hero-title {
  color: var(--white);
}
.dark .hero__inner-3 .video-intro-title {
  color: var(--gray-2);
}
.dark .hero__inner-3 .video-intro-title span {
  color: var(--white);
}
.dark .hero__about {
  background-color: var(--d-black-2);
  background-blend-mode: color-burn;
}
.dark .hero__about-award::before {
  background-color: var(--secondary);
}
.dark .hero__about-award img {
  filter: invert(1);
}
.dark .about__area {
  background: var(--black-2);
}
.dark .about__area .sec-title {
  color: var(--white);
}
.dark .about__area-2 {
  background-color: var(--d-black-2);
}
.dark .about__content p {
  color: var(--gray-2);
}
.dark .counter__area {
  background-color: var(--black-2);
}
@media (max-width: 1200px) {
  .dark .counter__area-3 {
    background-color: var(--black-2);
  }
}
.dark .counter__area-3 .counter__number {
  color: var(--white);
}
.dark .counter__about {
  background-color: var(--d-black-2);
}
.dark .counter__number {
  color: var(--white);
}
.dark .counter__item {
  border-color: var(--secondary);
}
.dark .counter__item::before {
  background-color: transparent;
}
.dark .counter__item::after {
  background-color: transparent;
}
.dark .counter__img-3 img {
  filter: contrast(0.5);
}
.dark .workflow__area {
  background-color: var(--black-2);
}
.dark .workflow__area .sec-title {
  color: var(--white);
}
.dark .workflow__area-3 {
  background-color: var(--black-2);
}
.dark .workflow__area-6 {
  background-color: var(--d-black-2);
}
.dark .workflow__slide::before {
  background-color: var(--bg-line);
}
.dark .workflow__slide::after {
  border-color: var(--black-2);
}
.dark .workflow__wrapper-6 {
  border-color: var(--secondary);
}
.dark .workflow__number {
  color: var(--d-black-3);
}
.dark .workflow__title {
  color: var(--white);
}
.dark .workflow__title-6 {
  color: var(--white);
}
.dark .workflow__step {
  color: var(--white);
}
.dark .brand__area {
  background-color: var(--black-2);
}
.dark .brand__area .sec-title {
  color: var(--white);
}
.dark .brand__about {
  background-color: var(--d-black-2);
}
.dark .brand__title-3 {
  color: var(--white);
}
.dark .brand__list, .dark .brand__list-3, .dark .brand__list-2 {
  background-color: transparent;
  border-color: var(--bg-line);
}
.dark .brand__item {
  border-color: var(--bg-line);
}
.dark .service__area {
  background-color: var(--d-black-2);
}
.dark .service__area-2 {
  background-color: var(--black-2);
}
.dark .service__area-2.service-v3 {
  background-color: var(--d-black-2);
}
.dark .service__area-2 .sec-text p {
  color: var(--white);
}
.dark .service__area-3 {
  background-color: var(--d-black-2);
}
.dark .service__area-3 .sec-title-wrapper::after {
  background-color: var(--secondary);
}
.dark .service__area-3 .sec-sub-title {
  background-color: var(--d-black-2);
}
.dark .service__area-3 .sec-title {
  background-color: var(--d-black-2);
}
.dark .service__area-6 {
  background-color: var(--d-black-2);
}
.dark .service__item-2::before {
  background-color: var(--secondary);
}
.dark .service__item-2 p,
.dark .service__item-2 ul li {
  color: var(--gray-2);
}
.dark .service__item-3, .dark .service__item-3:first-child {
  border-color: var(--secondary);
}
.dark .service__title-3:hover, .dark .service__title-2, .dark .service__title-3 {
  color: var(--white);
}
.dark .service__content-3 ul li {
  color: var(--gray-2);
}
.dark .service__content-3 p {
  color: var(--white);
}
.dark .service__detail {
  background-color: var(--d-black-2);
}
.dark .service-v4.pb-150 {
  padding-bottom: 150px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .service-v4.pb-150 {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .service-v4.pb-150 {
    padding-bottom: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .dark .service-v4.pb-150 {
    padding-bottom: 60px;
  }
}
.dark .portfolio__area {
  background-color: var(--d-black-2);
}
.dark .portfolio__area-2 {
  background-color: var(--black-2);
}
.dark .portfolio__area-2::after {
  background: var(--secondary);
}
.dark .portfolio__area-5 {
  background-color: var(--black-2);
  border-color: var(--secondary);
}
.dark .portfolio__area-5::before, .dark .portfolio__area-5::after,
.dark .portfolio__area-5 .sec-line-1,
.dark .portfolio__area-5 .sec-line-2 {
  filter: invert(0);
}
.dark .portfolio__area-6 {
  background-color: var(--black-2);
}
.dark .portfolio__area-6 .pb-140 {
  padding-bottom: 0;
}
.dark .portfolio__area-6 .sec-title-wrapper p::before {
  background-color: var(--white);
}
.dark .portfolio-v4 {
  background-color: var(--d-black-2);
}
.dark .portfolio__slider-2::after {
  background: var(--secondary);
}
.dark .portfolio__slider-2-pagination .swiper-pagination-bullet {
  border-color: var(--secondary);
}
.dark .portfolio__slider-2-pagination .swiper-pagination-bullet::after {
  background-color: var(--secondary);
}
.dark .portfolio__slider-2-pagination .swiper-pagination-bullet-active, .dark .portfolio__slider-2-pagination .swiper-pagination-bullet:hover {
  border-color: var(--white);
}
.dark .portfolio__slider-2-pagination .swiper-pagination-bullet-active::after, .dark .portfolio__slider-2-pagination .swiper-pagination-bullet:hover::after {
  background-color: var(--white);
}
.dark .portfolio__slide-2 {
  background-color: var(--black-2);
}
.dark .portfolio__slide-2 .sec-title a {
  color: var(--white);
}
.dark .portfolio__slide-2 .btn-common {
  color: var(--gray);
}
.dark .portfolio__slide-2 .btn-common:hover {
  color: var(--white);
}
.dark .portfolio__detail {
  background-color: var(--black-2);
}
.dark .portfolio__detail-info ul li {
  color: var(--gray-2);
}
.dark .portfolio__detail-info ul li a,
.dark .portfolio__detail-info ul li span {
  color: var(--gray-2);
}
.dark .portfolio__detail-title {
  color: var(--white);
}
.dark .portfolio__detail-text p,
.dark .portfolio__detail-text li {
  color: var(--gray-2);
}
.dark .portfolio__detail-text .fonts img {
  filter: invert(1);
}
.dark .portfolio__detail-text .fonts ul .semibold,
.dark .portfolio__detail-text .fonts ul .medium {
  color: var(--gray-2);
}
.dark .portfolio__title-6, .dark .portfolio__current {
  color: var(--white);
}
.dark .portfolio__date, .dark .portfolio__pagination-6 {
  color: var(--gray-2);
}
.dark .portfolio__project {
  background-color: var(--black-2);
}
.dark .portfolio__project::after {
  filter: invert(1);
}
.dark .portfolio__project .pp-title,
.dark .portfolio__project .pp-slide-title,
.dark .portfolio__project .swipper-btn {
  color: var(--white);
}
.dark .portfolio__project .pp-slide-thumb p {
  color: var(--gray-2);
}
.dark .portfolio__project .pp-prev::after,
.dark .portfolio__project .pp-next::after {
  background-color: var(--white);
}
.dark .portfolio__footer {
  border-color: var(--secondary);
}
.dark .portfolio__footer-area {
  background-color: var(--black-2);
  background-blend-mode: overlay;
}
.dark .portfolio__footer-area::before {
  filter: invert(0.8);
}
.dark .portfolio__footer-area::after {
  filter: invert(1);
}
.dark .portfolio__footer-area .pf-title,
.dark .portfolio__footer-area .pf-contact h3,
.dark .portfolio__footer-area .pf-contact ul li a,
.dark .portfolio__footer-area .pf-social ul li a {
  color: var(--white);
}
.dark .portfolio__footer-area .pf-social ul li a:hover {
  color: var(--black-2);
  background-color: var(--white);
}
.dark .portfolio__service {
  background-color: var(--d-black-2);
  background-blend-mode: overlay;
}
.dark .portfolio__service .sec-text, .dark .portfolio__service-item {
  filter: invert(1);
}
.dark .portfolio__service .ps-btn a {
  color: var(--white);
  border-color: var(--white);
}
.dark .portfolio__hero .title {
  color: var(--white);
}
.dark .portfolio__hero .title.text-stroke {
  -webkit-text-stroke: 1px var(--white);
}
.dark .portfolio__hero .title.shape-circle::after {
  filter: invert(1);
}
.dark .portfolio__hero-area {
  background-color: var(--black-2);
}
.dark .portfolio__hero-area::after {
  filter: invert(1);
}
.dark .portfolio__about {
  background-color: var(--d-black-2);
}
.dark .portfolio__about::after {
  filter: invert(1);
}
.dark .portfolio__about .sec-text p {
  color: var(--gray-2);
}
.dark .portfolio__about .sec-title span::before {
  background-color: var(--white);
}
.dark .portfolio__about-left img {
  filter: invert(0.5);
}
.dark .portfolio__about .brand-title {
  color: var(--gray-2);
}
.dark .portfolio__about .about-row,
.dark .portfolio__about .brand-title-wrap {
  border-color: var(--secondary);
}
.dark .wc-btn-dark {
  color: var(--white);
  border-color: var(--white);
}
.dark .testimonial__area {
  background-color: var(--black-2);
}
.dark .testimonial__area .sec-title {
  color: var(--white);
}
.dark .testimonial__area-2 {
  background-color: var(--d-black-2);
}
.dark .testimonial__area-3 {
  background-color: var(--d-black-2);
  background-image: none;
}
.dark .testimonial__area-3 p::before {
  filter: invert(1);
}
.dark .testimonial__pagination .next-button, .dark .testimonial__pagination .prev-button {
  border-color: var(--secondary);
}
.dark .testimonial__pagination .next-button i, .dark .testimonial__pagination .prev-button i {
  color: var(--secondary);
}
.dark .testimonial__pagination .next-button:hover, .dark .testimonial__pagination .prev-button:hover {
  border-color: var(--white);
}
.dark .testimonial__pagination .next-button:hover i, .dark .testimonial__pagination .prev-button:hover i {
  color: var(--white);
}
.dark .testimonial__title, .dark .testimonial__title-2, .dark .testimonial__author {
  color: var(--white);
}
.dark .testimonial__img.b-right::after {
  border-color: var(--secondary);
  z-index: 0;
}
.dark .testimonial__img.b-left::before {
  border-color: var(--bg-line);
  z-index: 1;
}
.dark .testimonial__role {
  color: var(--gray-2);
}
.dark .testimonial__slide-3 p {
  color: var(--white);
}
.dark .testimonial__inner-2 {
  background-color: var(--d-black-2);
}
.dark .testimonial__slider-3 .swipper-btn:hover {
  transition: all 0.3s;
  border-color: var(--white);
}
.dark .client__name-3 {
  color: var(--white);
}
.dark .client__role-3 {
  color: var(--gray-2);
}
.dark .blog__area {
  background-color: var(--black-2);
}
.dark .blog__area.no-pb {
  margin-bottom: -150px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .blog__area.no-pb {
    margin-bottom: -90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .blog__area.no-pb {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .dark .blog__area.no-pb {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .blog__area .pb-140 {
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .blog__area .pb-140 {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .dark .blog__area .pb-140 {
    padding-bottom: 0;
  }
}
.dark .blog__area-2, .dark .blog__area-6 {
  background-color: var(--d-black-2);
}
.dark .blog__area .sec-title {
  color: var(--white);
}
.dark .blog__area-3 {
  background-color: var(--d-black-2);
}
.dark .blog__area-3 .sec-sub-title,
.dark .blog__area-3 .sec-title {
  background-color: var(--d-black-2);
}
.dark .blog__area-3 .sec-title-wrapper::after {
  background-color: var(--secondary);
}
.dark .blog__info-3 {
  background-color: var(--d-black-2);
}
.dark .blog__title, .dark .blog__title-3, .dark .blog__title-2 {
  color: var(--white);
  border-color: var(--secondary);
}
.dark .blog__title:hover, .dark .blog__title-3:hover, .dark .blog__title-2:hover {
  color: var(--gray-2);
}
.dark .blog__btn {
  color: var(--gray-2);
}
.dark .blog__btn:hover {
  color: var(--white);
}
.dark .blog__meta, .dark .blog__meta-2 {
  color: var(--gray-2);
}
.dark .blog__meta a, .dark .blog__meta-2 a {
  color: var(--gray-2);
}
.dark .blog__meta a:hover, .dark .blog__meta-2 a:hover {
  color: var(--white);
}
.dark .blog__detail {
  background-color: var(--d-black-2);
}
.dark .blog__detail-title, .dark .blog__detail-date {
  color: var(--white);
}
.dark .blog__detail-date span,
.dark .blog__detail ul li {
  color: var(--gray-2);
}
.dark .blog__detail-tags p {
  color: var(--white);
}
.dark .blog__detail-tags p a {
  color: var(--gray-2);
}
.dark .blog__detail-content h1,
.dark .blog__detail-content h2,
.dark .blog__detail-content h3,
.dark .blog__detail-content h4,
.dark .blog__detail-content h5,
.dark .blog__detail-content h6 {
  color: var(--white);
}
.dark .blog__detail-meta p span {
  color: var(--gray-2);
}
.dark .blog__related {
  background-color: var(--d-black-2);
}
.dark .team__area {
  background-color: var(--d-black-2);
}
.dark .team__area-6 {
  background-color: var(--black-2);
  background-blend-mode: color-burn;
}
.dark .team__about {
  background-color: var(--black-2);
}
.dark .team__btm {
  background-color: var(--black-2);
}
.dark .team__join-btn {
  background-color: var(--d-black-2);
}
.dark .team__member-name-6, .dark .team__member-name-7 {
  color: var(--white);
}
.dark .team__member-role-6, .dark .team__member-role-7 {
  color: var(--gray-2);
}
.dark .team__member-role-7 span {
  color: var(--white);
}
.dark .team__member-role-7::after {
  background-color: var(--white);
}
.dark .team__member-work li a {
  color: var(--white);
}
.dark .team__member-work li a:hover {
  color: var(--black-2);
  background-color: var(--white);
}
.dark .team__member-social li a:hover {
  color: var(--white);
}
.dark .team__detail {
  background-color: var(--black-2);
}
.dark .team__detail.pb-140 {
  padding-bottom: 60px;
}
.dark .team__detail-page .line-1,
.dark .team__detail-page .line-2,
.dark .team__detail-page .line-3 {
  background: var(--bg-line);
}
.dark .team__detail .work-title {
  color: var(--white);
}
.dark .cta__area {
  background-color: var(--black-2);
}
.dark .cta__area .line {
  padding-top: 150px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .cta__area .line {
    padding-top: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .cta__area .line {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .dark .cta__area .line {
    padding-top: 60px;
  }
}
.dark .cta__area .line.no-p {
  padding-top: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .cta__area .line.no-p {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .dark .cta__area .line.no-p {
    padding-top: 60px;
  }
}
.dark .cta__area .dark-p {
  padding-top: 150px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .cta__area .dark-p {
    padding-top: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .cta__area .dark-p {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .dark .cta__area .dark-p {
    padding-top: 60px;
  }
}
.dark .cta__area-2 {
  background-color: var(--black-2);
}
.dark .cta__area-2 .wc-btn-pink {
  color: var(--gray-2);
}
.dark .cta__area-3 {
  background-color: var(--d-black-2);
}
.dark .cta__area-5 {
  background-color: var(--black-2);
  filter: invert(0);
  margin: 0;
}
.dark .cta__title {
  color: var(--white);
}
.dark .cta__title-2 {
  color: var(--white);
}
.dark .cta__sub-title {
  color: var(--white);
}
@media only screen and (max-width: 767px) {
  .dark .cta__content {
    padding-top: 0px;
  }
}
.dark .footer__area {
  background-color: var(--black-2);
}
.dark .footer__area-2 {
  background-color: var(--black-2);
  background-blend-mode: multiply;
  background-position: 100% 1px;
}
.dark .footer__area-5 {
  background-color: var(--black-2);
}
.dark .footer__inner {
  background-color: var(--d-black-2);
}
.dark .footer__middle-2 {
  border-color: var(--secondary);
}
.dark .footer__subscribe-2::before {
  background-color: var(--secondary);
}
.dark .footer__subscribe-2 input {
  color: var(--white);
  border-color: var(--white);
  background-color: transparent;
}
.dark .footer__subscribe-2 input::placeholder {
  color: var(--white);
}
.dark .footer__subscribe-2 img {
  filter: invert(1);
}
.dark .footer-menu li a, .dark .footer-menu-2 li a {
  color: var(--white);
}
.dark .footer-menu li a .menu-text, .dark .footer-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}
.dark .footer__copyright-2 p a {
  color: var(--white);
}
.dark .footer__copyright-4 a {
  color: var(--white);
}
.dark .footer-line::after {
  background: var(--bg-line);
}
.dark .footer__location-2 .location h3 {
  color: var(--white);
}
.dark .feature__area-2 {
  background-color: var(--d-black-2);
}
.dark .feature__top {
  border-color: var(--secondary);
}
.dark .feature__item {
  border-color: var(--secondary);
}
.dark .feature__item:nth-child(odd) {
  border-color: var(--secondary);
}
.dark .feature__item img {
  filter: invert(1);
}
.dark .feature__item p {
  color: var(--gray-2);
}
.dark .feature__title {
  color: var(--white);
}
.dark .feature__text p {
  color: var(--white);
}
.dark .award__area {
  background-color: var(--d-black-2);
}
.dark .main-menu-3 li a {
  color: var(--white);
}
.dark .main-menu .mega-menu li a, .dark .main-menu .mega-menu-2 li a, .dark .main-menu-4 .mega-menu li a, .dark .main-menu-4 .mega-menu-2 li a {
  color: var(--black-2);
}
.dark .header__nav-icon-3 button {
  color: var(--white);
}
.dark .header__nav-icon-3 img {
  filter: invert(1);
}
.dark .choose-title {
  color: var(--white);
}
.dark .research__area {
  background-color: var(--d-black-2);
  background-blend-mode: exclusion;
}
@media (max-width: 1200px) {
  .dark .research__area {
    background-color: var(--black-2);
    padding-bottom: 0;
  }
}
.dark .research__area .sec-sub-title,
.dark .research__area .sec-title-wrapper p {
  color: var(--white);
}
@media only screen and (max-width: 767px) {
  .dark .research__list {
    padding-bottom: 0;
  }
}
.dark .research__item p, .dark .research__number span, .dark .research__title {
  color: var(--white);
}
.dark .research__tools li a {
  color: var(--white);
  border-color: var(--white);
}
.dark .research__tools li a:hover {
  color: var(--black-2);
  background-color: var(--white);
}
.dark .price__area {
  background-color: var(--black-2);
}
.dark .price__item:first-child {
  background-color: var(--d-black-2);
}
.dark .story__area {
  background-color: var(--d-black-2);
}
.dark .story__area .line {
  padding-bottom: 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .story__area .line {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .story__area .line {
    padding-bottom: 90px;
  }
}
.dark .story__text p {
  color: var(--gray-2);
}
.dark .error__page {
  background-color: var(--d-black-2);
}
.dark .error__content h2 {
  color: var(--white);
}
.dark .error__content img {
  filter: invert(1);
}
.dark .career__top {
  background-color: var(--black-2);
}
.dark .career__gallery {
  background-color: var(--black-2);
}
.dark .career__gallery ul li {
  color: var(--white);
}
.dark .career__gallery ul li::after {
  background-color: var(--white);
}
.dark .career__gallery p {
  color: var(--gray-2);
}
.dark .career__benefits {
  background-color: var(--black-2);
}
.dark .career__benefits-list li {
  color: var(--white);
  border-color: var(--bg-line);
}
.dark .contact__area-6 {
  background-color: var(--black-2);
}
.dark .contact__form textarea,
.dark .contact__form input,
.dark .contact__form input::placeholder,
.dark .contact__form textarea::placeholder {
  color: var(--white);
  border-color: var(--bg-line);
}
.dark .contact__form textarea:focus,
.dark .contact__form input:focus {
  border-color: var(--white);
}
.dark .contact__info h3,
.dark .contact__info ul li span,
.dark .contact__info ul li a {
  color: var(--white);
}
.dark .faq__area {
  background-color: var(--d-black-2);
}
.dark .faq__area-6 {
  background-color: var(--black-2);
}
.dark .faq__btm {
  background-color: var(--black-2);
}
.dark .faq__list .accordion-button, .dark .faq__list-3 .accordion-button, .dark .faq__list-6 .accordion-button {
  color: var(--white);
}
.dark .faq__title {
  color: var(--white);
}
.dark .faq__list .accordion-item, .dark .faq__list-6 .accordion-item, .dark .faq__list-3 .accordion-item {
  background-color: transparent;
}
.dark .faq__list-3 .accordion-item {
  border-color: var(--bg-line);
}
.dark .faq__list-3 .accordion-item:first-child {
  border-color: var(--bg-line);
}
.dark .accordion-item {
  background-color: var(--black-2);
  border-color: var(--bg-line);
}
.dark .service__faq .accordion-item {
  background-color: var(--d-black-2);
}
.dark .job__detail {
  background-color: var(--black-2);
}
.dark .job__detail-top {
  background-color: var(--d-black-2);
}
.dark .job__detail-content {
  border-color: var(--bg-line);
}
.dark .job__detail-content ul li {
  color: var(--gray-2);
}
.dark .job__detail-content h1,
.dark .job__detail-content h2,
.dark .job__detail-content h3,
.dark .job__detail-content h4,
.dark .job__detail-content h5,
.dark .job__detail-content h6 {
  color: var(--white);
}
.dark .job__detail-meta li {
  color: var(--gray-2);
}
.dark .job__detail-sidebar {
  background-color: var(--d-black-2);
}
.dark .job__detail-sidebar ul li {
  color: var(--gray-2);
}
.dark .development__area {
  background-color: var(--d-black-2);
}
.dark .development__wrapper ul li {
  color: var(--gray-2);
}
.dark .development__content {
  border-color: var(--secondary);
}
.dark .solution__area {
  background-color: var(--black-2);
}
.dark .solution__title {
  color: var(--white);
}
.dark .solution__mid p {
  color: var(--gray-2);
}
.dark .solution__mid p::before {
  background-color: var(--white);
}
.dark .solution__btm li {
  color: var(--white);
}
.dark .solution__img-1::after, .dark .solution__img-2::after, .dark .solution__img-3::after {
  border-color: var(--secondary);
  z-index: 0;
}
.dark .solution__shape .shape-5 {
  z-index: 0;
}
.dark .solution__shape .shape-2 {
  filter: invert(1);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .solution__shape .shape-4 {
    top: 55%;
  }
}
.dark .modal__apply {
  background-color: var(--d-black-2);
}
.dark .modal__apply .input-apply p,
.dark .modal__apply .form-top p {
  color: var(--white);
}
.dark .modal__apply .input-apply-2 p,
.dark .modal__apply .input-apply-2 input[type=file] {
  color: var(--white);
}
.dark .modal__apply .form-top img {
  filter: invert(1);
}
.dark .modal .close_btn-2:hover {
  color: var(--white);
}
.dark .modal__close-2:hover {
  color: var(--white);
}
.dark .menu-text-5 {
  color: var(--white);
}
.dark .circle-pagination button.swiper-pagination-bullet {
  border-color: var(--white);
}
.dark .circle-pagination button.swiper-pagination-bullet span {
  background-color: var(--white);
}
.dark .circle-pagination .circle-origin {
  stroke: var(--white);
}
.dark .circle-pagination-2 .swiper-pagination-bullet {
  border-color: var(--white);
}
.dark .circle-pagination-2 .swiper-pagination-bullet::after {
  background-color: var(--white);
}
.dark .bg-white {
  background-color: var(--black-2) !important;
}
.dark .main-menu-3 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}
.dark .header__nav-icon-5 img {
  filter: invert(1);
}
.dark .menu-text-pp {
  color: var(--white);
}
.dark .job__area {
  background-color: var(--d-black-2);
}
.dark .portfolio__area-5 .sec-line {
  background-color: #D3D3D3;
}
.dark .modal__apply .input-apply textarea,
.dark .modal__apply .input-apply-2 input {
  color: var(--white);
  background-color: var(--d-black-2);
  border-color: var(--black-3);
}
.dark .modal__apply .input-apply textarea:focus,
.dark .modal__apply .input-apply-2 input:focus {
  border-color: var(--white);
}
.dark .error__content::before {
  filter: invert(1);
}
.dark .sticky-2 .header__nav-icon-5 {
  filter: invert(0);
}
.dark .footer__menu-5 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}
.dark .hero__area-3::after {
  filter: invert(1);
}
.dark .service__hero-2 {
  background-color: var(--black-2);
  background-image: none;
}
.dark .service__hero-2 .shape-1 {
  filter: invert(1);
}
.dark .service__hero-right-2 .title {
  color: var(--white);
}
.dark .service__hero-right-2 .scroll {
  filter: invert(1);
}

/*----------------------------------------*/
/*  07. Button CSS START
/*----------------------------------------*/
.wc-btn-primary, .wc-btn-black, .wc-btn-light, .wc-btn-pink, .wc-btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: var(--black-3);
  border: 1px solid var(--gray);
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}
@media only screen and (max-width: 767px) {
  .wc-btn-primary, .wc-btn-black, .wc-btn-light, .wc-btn-pink, .wc-btn-secondary {
    padding: 30px;
  }
}
.wc-btn-primary:hover, .wc-btn-black:hover, .wc-btn-light:hover, .wc-btn-pink:hover, .wc-btn-secondary:hover {
  color: var(--white);
  border: 1px solid var(--black-2);
}
.wc-btn-primary:hover span, .wc-btn-black:hover span, .wc-btn-light:hover span, .wc-btn-pink:hover span, .wc-btn-secondary:hover span {
  width: 350px;
  height: 350px;
}
.wc-btn-primary i, .wc-btn-black i, .wc-btn-light i, .wc-btn-pink i, .wc-btn-secondary i {
  transform: rotate(-45deg);
  padding-left: 5px;
}
.wc-btn-primary span, .wc-btn-black span, .wc-btn-light span, .wc-btn-pink span, .wc-btn-secondary span {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  background-color: var(--black-2);
  z-index: -1;
  border-radius: 100%;
  transition: all 0.7s;
  transform: translate(-50%, -50%);
}
.wc-btn-secondary {
  border: 1px solid var(--secondary);
  color: var(--gray-2);
}
.wc-btn-pink {
  border: 1px solid var(--secondary);
  color: var(--gray-2);
}
.wc-btn-pink:hover {
  border-color: var(--pink-2);
}
.wc-btn-pink span {
  background-color: var(--pink-2);
}
.wc-btn-light {
  border: 1px solid var(--secondary);
  color: var(--gray-2);
}
.wc-btn-light:hover {
  border-color: var(--white);
  color: var(--black-2);
}
.wc-btn-light span {
  background-color: var(--white);
}
.wc-btn-black:hover {
  color: var(--white);
  border-color: var(--black-2);
}
.wc-btn-black span {
  background-color: var(--black-2);
}
.wc-btn-dark {
  display: inline-block;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: var(--black-2);
  border: 1.3px solid var(--black-2);
  border-radius: 33px;
  padding: 20px 54px;
  transition: all 0.3s;
}
.wc-btn-dark:hover {
  color: var(--black-2);
  background-color: var(--white);
  border-color: var(--white);
}

.btn_wrapper,
#btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 250px;
  width: 250px;
  border-radius: 100%;
  margin-left: -40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .btn_wrapper,
  #btn_wrapper {
    width: 220px;
    height: 220px;
    margin-left: -25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn_wrapper,
  #btn_wrapper {
    width: 190px;
    height: 190px;
    margin-left: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn_wrapper,
  #btn_wrapper {
    width: 180px;
    height: 180px;
    margin-left: -5px;
  }
}
@media only screen and (max-width: 767px) {
  .btn_wrapper,
  #btn_wrapper {
    height: 175px;
    width: 175px;
    margin-left: -2.5px;
  }
}

.btn-item {
  position: absolute;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__top-btn .btn_wrapper,
  .service__top-btn #btn_wrapper {
    width: 170px;
    height: 170px;
  }
  .service__top-btn .btn_wrapper .btn-item,
  .service__top-btn #btn_wrapper .btn-item {
    top: 0;
    left: 0;
  }
}
.about_btn {
  font-size: 20px !important;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .about_btn {
    font-size: 18px !important;
  }
}
.about_btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  bottom: -10px;
  left: 0;
  background-color: var(--black-7);
}

/*----------------------------------------*/
/*  08. TITLE CSS START
/*----------------------------------------*/
.sec-title {
  font-weight: 500;
  font-size: 60px;
  line-height: 1;
  color: var(--black-2);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title {
    font-size: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-title {
    font-size: 32px;
  }
}
.sec-title-2 {
  font-weight: 500;
  font-size: 120px;
  line-height: 0.9;
  color: var(--black-2);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .sec-title-2 {
    font-size: 100px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title-2 {
    font-size: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-2 {
    font-size: 72px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-2 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-title-2 {
    font-size: 45px;
  }
}
.sec-title-3 {
  font-weight: 500;
  font-size: 130px;
  line-height: 1;
  color: var(--black-2);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .sec-title-3 {
    font-size: 100px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title-3 {
    font-size: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-3 {
    font-size: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-3 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-title-3 {
    font-size: 48px;
  }
}
.sec-title-4 {
  font-size: 150px;
  line-height: 1;
  color: var(--black-2);
  text-transform: uppercase;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title-4 {
    font-size: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-4 {
    font-size: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-4 {
    font-size: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-title-4 {
    font-size: 48px;
  }
}
.sec-title-4 span {
  font-family: "newYork";
  padding-left: 298px;
  display: block;
}
@media only screen and (max-width: 767px) {
  .sec-title-4 span {
    padding-left: 0;
  }
}
.sec-title-4 img {
  margin-top: -30px;
  animation: wcSpinner 3s infinite linear;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-4 img {
    max-width: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-title-4 img {
    display: none;
  }
}
.sec-title-5 {
  padding-left: 98px;
}
@media only screen and (max-width: 767px) {
  .sec-title-5 {
    padding-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-5 {
    font-size: 120px;
    line-height: 8.3rem;
  }
}
.sec-title-7 {
  padding-left: 185px;
}
.sec-title-8 {
  font-size: 130px;
  line-height: 1;
  font-weight: 500;
  color: var(--black-2);
  text-transform: uppercase;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-8 {
    font-size: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-8 {
    font-size: 72px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-title-8 {
    font-size: 60px;
  }
}
.sec-title-8 a {
  font-family: "newYork";
  text-transform: capitalize;
}
.sec-title-16 {
  font-weight: 500;
  font-size: 120px;
  line-height: 0.9;
  color: var(--black-2);
  text-indent: 120px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title-16 {
    font-size: 100px;
    text-indent: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-16 {
    font-size: 90px;
    text-indent: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-16 {
    text-indent: 60px;
    font-size: 72px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-title-16 {
    text-indent: 20px;
    font-size: 48px;
  }
}
.sec-title-16 span {
  color: var(--black-2);
  font-family: "newYork";
  position: relative;
}
.sec-title-16 span::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 10px;
  left: 0;
  background-color: var(--black-2);
}
@media only screen and (max-width: 767px) {
  .sec-title-16 span::after {
    bottom: 0;
  }
}
.sec-sub-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: var(--black-3);
  padding-bottom: 15px;
  text-transform: capitalize;
}

.hero-title {
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  color: var(--black);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-title {
    font-size: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-title {
    font-size: 36px;
  }
}

.title-line {
  overflow: hidden;
}

@media (min-width: 1200px) {
  .img-anim {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 565px;
  }
  .img-anim img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.dis_port_4 {
  max-width: 300px;
  text-indent: 90px;
  margin-left: 100px;
  margin-top: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black-3);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dis_port_4 {
    text-indent: 50px;
    margin-left: 60px;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dis_port_4 {
    text-indent: 30px;
    margin-left: 20px;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .dis_port_4 {
    margin-left: 20px;
    text-indent: 30px;
    margin-top: 20px;
  }
}

.section_title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 130px 50px 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section_title_wrapper {
    padding: 110px 30px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title_wrapper {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  .section_title_wrapper {
    flex-wrap: wrap;
    padding: 120px 15px 0;
  }
}
.section_title_wrapper .title_right {
  padding-top: 150px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title_wrapper .title_right {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .section_title_wrapper .title_right {
    padding-top: 50px;
  }
}
.section_title_wrapper .title_right__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section_title_wrapper .title_right__inner__left {
  margin-right: 60px;
}
.section_title_wrapper .title_right__inner__left span {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.7;
  text-align: right;
  color: var(--black-3);
  display: inline-block;
  position: relative;
}
.section_title_wrapper .title_right__inner__left span::before {
  content: "";
  position: absolute;
  right: 80px;
  top: 50%;
  background-color: #D9D9D9;
  width: 1090px;
  height: 1px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .section_title_wrapper .title_right__inner__left span::before {
    width: 600px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section_title_wrapper .title_right__inner__left span::before {
    width: 500px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section_title_wrapper .title_right__inner__left span::before {
    width: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title_wrapper .title_right__inner__left span::before {
    width: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .section_title_wrapper .title_right__inner__left span::before {
    display: none;
  }
}
.section_title_wrapper .title_right__inner__right ul li {
  margin-bottom: 10px;
}
.section_title_wrapper .title_right__inner__right ul li a {
  font-size: 18px;
  color: var(--black-13);
  text-transform: capitalize;
}
.section_title_wrapper .title_right__inner__right ul li a:hover {
  letter-spacing: 1px;
  color: var(--black-2);
}

.dark .sec-title-4 {
  color: var(--white);
}
.dark .sec-title-4 img {
  filter: invert(1);
}
.dark .sec-title-8 {
  color: var(--white);
}
.dark .sec-title-16 {
  color: var(--white);
}
.dark .sec-title-16 span {
  color: var(--white);
}
.dark .sec-title-16 span::after {
  background-color: var(--white);
}
.dark .section_title_wrapper .title_right__inner__left span {
  color: var(--gray-2);
}
.dark .section_title_wrapper .title_right__inner__left span::before {
  background-color: var(--gray-2);
}
.dark .section_title_wrapper .title_right__inner__right ul li a {
  color: var(--gray-2);
}
.dark .section_title_wrapper .title_right__inner__right ul li a:hover {
  color: var(--white);
}

/*----------------------------------------*/
/*  09. MENU CSS START
/*----------------------------------------*/
.main-menu > li, .main-menu-4 > li {
  display: inline-block;
  margin: 0 38px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > li, .main-menu-4 > li {
    margin: 0 19px;
  }
}
.main-menu > li.has-megamenu, .main-menu-4 > li.has-megamenu {
  position: static;
}
.main-menu > li:hover .main-dropdown, .main-menu-4 > li:hover .main-dropdown {
  top: 75px;
  opacity: 1;
  visibility: visible;
}
.main-menu > li:hover .mega-menu, .main-menu > li:hover .mega-menu-2, .main-menu-4 > li:hover .mega-menu, .main-menu-4 > li:hover .mega-menu-2 {
  top: 75px;
  opacity: 1;
  visibility: visible;
}
.main-menu > li > a, .main-menu-4 > li > a {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: var(--white);
  padding: 29px 0;
  outline: none;
  text-transform: capitalize;
  transform: translateY(var(--y)) translateZ(0);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}
.main-menu > li > a .menu-text, .main-menu-4 > li > a .menu-text {
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--white);
}
.main-menu > li > a .menu-text span, .main-menu-4 > li > a .menu-text span {
  display: block;
  backface-visibility: hidden;
  font-style: normal;
  transition: transform 0.4s ease;
  transform: translateY(var(--m)) translateZ(0);
}
.main-menu > li > a .menu-text span:nth-child(1), .main-menu-4 > li > a .menu-text span:nth-child(1) {
  transition-delay: 0.05s;
}
.main-menu > li > a .menu-text span:nth-child(2), .main-menu-4 > li > a .menu-text span:nth-child(2) {
  transition-delay: 0.1s;
}
.main-menu > li > a .menu-text span:nth-child(3), .main-menu-4 > li > a .menu-text span:nth-child(3) {
  transition-delay: 0.15s;
}
.main-menu > li > a .menu-text span:nth-child(4), .main-menu-4 > li > a .menu-text span:nth-child(4) {
  transition-delay: 0.2s;
}
.main-menu > li > a .menu-text span:nth-child(5), .main-menu-4 > li > a .menu-text span:nth-child(5) {
  transition-delay: 0.25s;
}
.main-menu > li > a .menu-text span:nth-child(6), .main-menu-4 > li > a .menu-text span:nth-child(6) {
  transition-delay: 0.3s;
}
.main-menu > li > a .menu-text span:nth-child(7), .main-menu-4 > li > a .menu-text span:nth-child(7) {
  transition-delay: 0.35s;
}
.main-menu > li > a .menu-text span:nth-child(8), .main-menu-4 > li > a .menu-text span:nth-child(8) {
  transition-delay: 0.4s;
}
.main-menu > li > a .menu-text span:nth-child(9), .main-menu-4 > li > a .menu-text span:nth-child(9) {
  transition-delay: 0.45s;
}
.main-menu > li > a .menu-text span:nth-child(10), .main-menu-4 > li > a .menu-text span:nth-child(10) {
  transition-delay: 0.5s;
}
.main-menu > li > a .menu-text span:nth-child(11), .main-menu-4 > li > a .menu-text span:nth-child(11) {
  transition-delay: 0.55s;
}
.main-menu > li > a .menu-text span:nth-child(12), .main-menu-4 > li > a .menu-text span:nth-child(12) {
  transition-delay: 0.6s;
}
.main-menu > li > a .menu-text span:nth-child(13), .main-menu-4 > li > a .menu-text span:nth-child(13) {
  transition-delay: 0.65s;
}
.main-menu > li > a .menu-text span:nth-child(14), .main-menu-4 > li > a .menu-text span:nth-child(14) {
  transition-delay: 0.7s;
}
.main-menu > li > a .menu-text span:nth-child(15), .main-menu-4 > li > a .menu-text span:nth-child(15) {
  transition-delay: 0.75s;
}
.main-menu > li > a .menu-text span:nth-child(16), .main-menu-4 > li > a .menu-text span:nth-child(16) {
  transition-delay: 0.8s;
}
.main-menu > li > a .menu-text span:nth-child(17), .main-menu-4 > li > a .menu-text span:nth-child(17) {
  transition-delay: 0.85s;
}
.main-menu > li > a .menu-text span:nth-child(18), .main-menu-4 > li > a .menu-text span:nth-child(18) {
  transition-delay: 0.9s;
}
.main-menu > li > a .menu-text span:nth-child(19), .main-menu-4 > li > a .menu-text span:nth-child(19) {
  transition-delay: 0.95s;
}
.main-menu > li > a .menu-text span:nth-child(20), .main-menu-4 > li > a .menu-text span:nth-child(20) {
  transition-delay: 1s;
}
.main-menu > li > a .menu-text span:nth-child(21), .main-menu-4 > li > a .menu-text span:nth-child(21) {
  transition-delay: 1.05s;
}
.main-menu > li > a .menu-text span:nth-child(22), .main-menu-4 > li > a .menu-text span:nth-child(22) {
  transition-delay: 1.1s;
}
.main-menu > li > a .menu-text span:nth-child(23), .main-menu-4 > li > a .menu-text span:nth-child(23) {
  transition-delay: 1.15s;
}
.main-menu > li > a .menu-text span:nth-child(24), .main-menu-4 > li > a .menu-text span:nth-child(24) {
  transition-delay: 1.2s;
}
.main-menu > li > a .menu-text span:nth-child(25), .main-menu-4 > li > a .menu-text span:nth-child(25) {
  transition-delay: 1.25s;
}
.main-menu > li > a .menu-text span:nth-child(26), .main-menu-4 > li > a .menu-text span:nth-child(26) {
  transition-delay: 1.3s;
}
.main-menu > li > a .menu-text span:nth-child(27), .main-menu-4 > li > a .menu-text span:nth-child(27) {
  transition-delay: 1.35s;
}
.main-menu > li > a .menu-text span:nth-child(28), .main-menu-4 > li > a .menu-text span:nth-child(28) {
  transition-delay: 1.4s;
}
.main-menu > li > a .menu-text span:nth-child(29), .main-menu-4 > li > a .menu-text span:nth-child(29) {
  transition-delay: 1.45s;
}
.main-menu > li > a .menu-text span:nth-child(30), .main-menu-4 > li > a .menu-text span:nth-child(30) {
  transition-delay: 1.5s;
}
.main-menu > li > a .menu-text span:nth-child(31), .main-menu-4 > li > a .menu-text span:nth-child(31) {
  transition-delay: 1.55s;
}
.main-menu > li > a .menu-text span:nth-child(32), .main-menu-4 > li > a .menu-text span:nth-child(32) {
  transition-delay: 1.6s;
}
.main-menu > li > a .menu-text span:nth-child(33), .main-menu-4 > li > a .menu-text span:nth-child(33) {
  transition-delay: 1.65s;
}
.main-menu > li > a .menu-text span:nth-child(34), .main-menu-4 > li > a .menu-text span:nth-child(34) {
  transition-delay: 1.7s;
}
.main-menu > li > a .menu-text span:nth-child(35), .main-menu-4 > li > a .menu-text span:nth-child(35) {
  transition-delay: 1.75s;
}
.main-menu > li > a .menu-text span:nth-child(36), .main-menu-4 > li > a .menu-text span:nth-child(36) {
  transition-delay: 1.8s;
}
.main-menu > li > a .menu-text span:nth-child(37), .main-menu-4 > li > a .menu-text span:nth-child(37) {
  transition-delay: 1.85s;
}
.main-menu > li > a .menu-text span:nth-child(38), .main-menu-4 > li > a .menu-text span:nth-child(38) {
  transition-delay: 1.9s;
}
.main-menu > li > a .menu-text span:nth-child(39), .main-menu-4 > li > a .menu-text span:nth-child(39) {
  transition-delay: 1.95s;
}
.main-menu > li > a .menu-text span:nth-child(40), .main-menu-4 > li > a .menu-text span:nth-child(40) {
  transition-delay: 2s;
}
.main-menu > li > a .menu-text span:nth-child(41), .main-menu-4 > li > a .menu-text span:nth-child(41) {
  transition-delay: 2.05s;
}
.main-menu > li > a .menu-text span:nth-child(42), .main-menu-4 > li > a .menu-text span:nth-child(42) {
  transition-delay: 2.1s;
}
.main-menu > li > a .menu-text span:nth-child(43), .main-menu-4 > li > a .menu-text span:nth-child(43) {
  transition-delay: 2.15s;
}
.main-menu > li > a .menu-text span:nth-child(44), .main-menu-4 > li > a .menu-text span:nth-child(44) {
  transition-delay: 2.2s;
}
.main-menu > li > a .menu-text span:nth-child(45), .main-menu-4 > li > a .menu-text span:nth-child(45) {
  transition-delay: 2.25s;
}
.main-menu > li > a .menu-text span:nth-child(46), .main-menu-4 > li > a .menu-text span:nth-child(46) {
  transition-delay: 2.3s;
}
.main-menu > li > a .menu-text span:nth-child(47), .main-menu-4 > li > a .menu-text span:nth-child(47) {
  transition-delay: 2.35s;
}
.main-menu > li > a .menu-text span:nth-child(48), .main-menu-4 > li > a .menu-text span:nth-child(48) {
  transition-delay: 2.4s;
}
.main-menu > li > a .menu-text span:nth-child(49), .main-menu-4 > li > a .menu-text span:nth-child(49) {
  transition-delay: 2.45s;
}
.main-menu > li > a:hover, .main-menu-4 > li > a:hover {
  --y: -4px;
}
.main-menu > li > a:hover span, .main-menu-4 > li > a:hover span {
  --m: calc(16px * -1);
}
.main-menu-3 {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .main-menu-3 {
    display: none;
  }
}
.main-menu-3 > li {
  display: inline-block;
  padding: 0 40px;
  margin: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-3 > li {
    padding: 25px 0;
  }
}
.main-menu-3 > li.has-megamenu {
  position: static;
}
.main-menu-3 > li:hover .main-dropdown {
  top: 80px;
  opacity: 1;
  visibility: visible;
}
.main-menu-3 > li:hover .mega-menu, .main-menu-3 > li:hover .mega-menu-2 {
  top: 80px;
  opacity: 1;
  visibility: visible;
}
.main-menu-3 > li > a {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--black-2);
  padding: 27px 0;
  outline: none;
  text-transform: capitalize;
  transform: translateY(var(--y)) translateZ(0);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}
.main-menu-3 > li > a .menu-text {
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-2);
}
.main-menu-3 > li > a .menu-text span {
  display: block;
  backface-visibility: hidden;
  font-style: normal;
  transition: transform 0.4s ease;
  transform: translateY(var(--m)) translateZ(0);
}
.main-menu-3 > li > a .menu-text span:nth-child(1) {
  transition-delay: 0.05s;
}
.main-menu-3 > li > a .menu-text span:nth-child(2) {
  transition-delay: 0.1s;
}
.main-menu-3 > li > a .menu-text span:nth-child(3) {
  transition-delay: 0.15s;
}
.main-menu-3 > li > a .menu-text span:nth-child(4) {
  transition-delay: 0.2s;
}
.main-menu-3 > li > a .menu-text span:nth-child(5) {
  transition-delay: 0.25s;
}
.main-menu-3 > li > a .menu-text span:nth-child(6) {
  transition-delay: 0.3s;
}
.main-menu-3 > li > a .menu-text span:nth-child(7) {
  transition-delay: 0.35s;
}
.main-menu-3 > li > a .menu-text span:nth-child(8) {
  transition-delay: 0.4s;
}
.main-menu-3 > li > a .menu-text span:nth-child(9) {
  transition-delay: 0.45s;
}
.main-menu-3 > li > a .menu-text span:nth-child(10) {
  transition-delay: 0.5s;
}
.main-menu-3 > li > a .menu-text span:nth-child(11) {
  transition-delay: 0.55s;
}
.main-menu-3 > li > a .menu-text span:nth-child(12) {
  transition-delay: 0.6s;
}
.main-menu-3 > li > a .menu-text span:nth-child(13) {
  transition-delay: 0.65s;
}
.main-menu-3 > li > a .menu-text span:nth-child(14) {
  transition-delay: 0.7s;
}
.main-menu-3 > li > a .menu-text span:nth-child(15) {
  transition-delay: 0.75s;
}
.main-menu-3 > li > a .menu-text span:nth-child(16) {
  transition-delay: 0.8s;
}
.main-menu-3 > li > a .menu-text span:nth-child(17) {
  transition-delay: 0.85s;
}
.main-menu-3 > li > a .menu-text span:nth-child(18) {
  transition-delay: 0.9s;
}
.main-menu-3 > li > a .menu-text span:nth-child(19) {
  transition-delay: 0.95s;
}
.main-menu-3 > li > a .menu-text span:nth-child(20) {
  transition-delay: 1s;
}
.main-menu-3 > li > a .menu-text span:nth-child(21) {
  transition-delay: 1.05s;
}
.main-menu-3 > li > a .menu-text span:nth-child(22) {
  transition-delay: 1.1s;
}
.main-menu-3 > li > a .menu-text span:nth-child(23) {
  transition-delay: 1.15s;
}
.main-menu-3 > li > a .menu-text span:nth-child(24) {
  transition-delay: 1.2s;
}
.main-menu-3 > li > a .menu-text span:nth-child(25) {
  transition-delay: 1.25s;
}
.main-menu-3 > li > a .menu-text span:nth-child(26) {
  transition-delay: 1.3s;
}
.main-menu-3 > li > a .menu-text span:nth-child(27) {
  transition-delay: 1.35s;
}
.main-menu-3 > li > a .menu-text span:nth-child(28) {
  transition-delay: 1.4s;
}
.main-menu-3 > li > a .menu-text span:nth-child(29) {
  transition-delay: 1.45s;
}
.main-menu-3 > li > a .menu-text span:nth-child(30) {
  transition-delay: 1.5s;
}
.main-menu-3 > li > a .menu-text span:nth-child(31) {
  transition-delay: 1.55s;
}
.main-menu-3 > li > a .menu-text span:nth-child(32) {
  transition-delay: 1.6s;
}
.main-menu-3 > li > a .menu-text span:nth-child(33) {
  transition-delay: 1.65s;
}
.main-menu-3 > li > a .menu-text span:nth-child(34) {
  transition-delay: 1.7s;
}
.main-menu-3 > li > a .menu-text span:nth-child(35) {
  transition-delay: 1.75s;
}
.main-menu-3 > li > a .menu-text span:nth-child(36) {
  transition-delay: 1.8s;
}
.main-menu-3 > li > a .menu-text span:nth-child(37) {
  transition-delay: 1.85s;
}
.main-menu-3 > li > a .menu-text span:nth-child(38) {
  transition-delay: 1.9s;
}
.main-menu-3 > li > a .menu-text span:nth-child(39) {
  transition-delay: 1.95s;
}
.main-menu-3 > li > a .menu-text span:nth-child(40) {
  transition-delay: 2s;
}
.main-menu-3 > li > a .menu-text span:nth-child(41) {
  transition-delay: 2.05s;
}
.main-menu-3 > li > a .menu-text span:nth-child(42) {
  transition-delay: 2.1s;
}
.main-menu-3 > li > a .menu-text span:nth-child(43) {
  transition-delay: 2.15s;
}
.main-menu-3 > li > a .menu-text span:nth-child(44) {
  transition-delay: 2.2s;
}
.main-menu-3 > li > a .menu-text span:nth-child(45) {
  transition-delay: 2.25s;
}
.main-menu-3 > li > a .menu-text span:nth-child(46) {
  transition-delay: 2.3s;
}
.main-menu-3 > li > a .menu-text span:nth-child(47) {
  transition-delay: 2.35s;
}
.main-menu-3 > li > a .menu-text span:nth-child(48) {
  transition-delay: 2.4s;
}
.main-menu-3 > li > a .menu-text span:nth-child(49) {
  transition-delay: 2.45s;
}
.main-menu-3 > li > a:hover {
  --y: -4px;
}
.main-menu-3 > li > a:hover span {
  --m: calc(16px * -1);
}
.main-menu-4 > li > a {
  color: var(--black-2);
}
.main-menu-4 > li > a .menu-text {
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-2);
}
.main-menu-4 > li > a .menu-text span {
  display: block;
  backface-visibility: hidden;
  font-style: normal;
  transition: transform 0.4s ease;
  transform: translateY(var(--m)) translateZ(0);
}
.main-menu-4 > li > a .menu-text span:nth-child(1) {
  transition-delay: 0.05s;
}
.main-menu-4 > li > a .menu-text span:nth-child(2) {
  transition-delay: 0.1s;
}
.main-menu-4 > li > a .menu-text span:nth-child(3) {
  transition-delay: 0.15s;
}
.main-menu-4 > li > a .menu-text span:nth-child(4) {
  transition-delay: 0.2s;
}
.main-menu-4 > li > a .menu-text span:nth-child(5) {
  transition-delay: 0.25s;
}
.main-menu-4 > li > a .menu-text span:nth-child(6) {
  transition-delay: 0.3s;
}
.main-menu-4 > li > a .menu-text span:nth-child(7) {
  transition-delay: 0.35s;
}
.main-menu-4 > li > a .menu-text span:nth-child(8) {
  transition-delay: 0.4s;
}
.main-menu-4 > li > a .menu-text span:nth-child(9) {
  transition-delay: 0.45s;
}
.main-menu-4 > li > a .menu-text span:nth-child(10) {
  transition-delay: 0.5s;
}
.main-menu-4 > li > a .menu-text span:nth-child(11) {
  transition-delay: 0.55s;
}
.main-menu-4 > li > a .menu-text span:nth-child(12) {
  transition-delay: 0.6s;
}
.main-menu-4 > li > a .menu-text span:nth-child(13) {
  transition-delay: 0.65s;
}
.main-menu-4 > li > a .menu-text span:nth-child(14) {
  transition-delay: 0.7s;
}
.main-menu-4 > li > a .menu-text span:nth-child(15) {
  transition-delay: 0.75s;
}
.main-menu-4 > li > a .menu-text span:nth-child(16) {
  transition-delay: 0.8s;
}
.main-menu-4 > li > a .menu-text span:nth-child(17) {
  transition-delay: 0.85s;
}
.main-menu-4 > li > a .menu-text span:nth-child(18) {
  transition-delay: 0.9s;
}
.main-menu-4 > li > a .menu-text span:nth-child(19) {
  transition-delay: 0.95s;
}
.main-menu-4 > li > a .menu-text span:nth-child(20) {
  transition-delay: 1s;
}
.main-menu-4 > li > a .menu-text span:nth-child(21) {
  transition-delay: 1.05s;
}
.main-menu-4 > li > a .menu-text span:nth-child(22) {
  transition-delay: 1.1s;
}
.main-menu-4 > li > a .menu-text span:nth-child(23) {
  transition-delay: 1.15s;
}
.main-menu-4 > li > a .menu-text span:nth-child(24) {
  transition-delay: 1.2s;
}
.main-menu-4 > li > a .menu-text span:nth-child(25) {
  transition-delay: 1.25s;
}
.main-menu-4 > li > a .menu-text span:nth-child(26) {
  transition-delay: 1.3s;
}
.main-menu-4 > li > a .menu-text span:nth-child(27) {
  transition-delay: 1.35s;
}
.main-menu-4 > li > a .menu-text span:nth-child(28) {
  transition-delay: 1.4s;
}
.main-menu-4 > li > a .menu-text span:nth-child(29) {
  transition-delay: 1.45s;
}
.main-menu-4 > li > a .menu-text span:nth-child(30) {
  transition-delay: 1.5s;
}
.main-menu-4 > li > a .menu-text span:nth-child(31) {
  transition-delay: 1.55s;
}
.main-menu-4 > li > a .menu-text span:nth-child(32) {
  transition-delay: 1.6s;
}
.main-menu-4 > li > a .menu-text span:nth-child(33) {
  transition-delay: 1.65s;
}
.main-menu-4 > li > a .menu-text span:nth-child(34) {
  transition-delay: 1.7s;
}
.main-menu-4 > li > a .menu-text span:nth-child(35) {
  transition-delay: 1.75s;
}
.main-menu-4 > li > a .menu-text span:nth-child(36) {
  transition-delay: 1.8s;
}
.main-menu-4 > li > a .menu-text span:nth-child(37) {
  transition-delay: 1.85s;
}
.main-menu-4 > li > a .menu-text span:nth-child(38) {
  transition-delay: 1.9s;
}
.main-menu-4 > li > a .menu-text span:nth-child(39) {
  transition-delay: 1.95s;
}
.main-menu-4 > li > a .menu-text span:nth-child(40) {
  transition-delay: 2s;
}
.main-menu-4 > li > a .menu-text span:nth-child(41) {
  transition-delay: 2.05s;
}
.main-menu-4 > li > a .menu-text span:nth-child(42) {
  transition-delay: 2.1s;
}
.main-menu-4 > li > a .menu-text span:nth-child(43) {
  transition-delay: 2.15s;
}
.main-menu-4 > li > a .menu-text span:nth-child(44) {
  transition-delay: 2.2s;
}
.main-menu-4 > li > a .menu-text span:nth-child(45) {
  transition-delay: 2.25s;
}
.main-menu-4 > li > a .menu-text span:nth-child(46) {
  transition-delay: 2.3s;
}
.main-menu-4 > li > a .menu-text span:nth-child(47) {
  transition-delay: 2.35s;
}
.main-menu-4 > li > a .menu-text span:nth-child(48) {
  transition-delay: 2.4s;
}
.main-menu-4 > li > a .menu-text span:nth-child(49) {
  transition-delay: 2.45s;
}
.main-menu-4 > li > a:hover {
  --y: -4px;
}
.main-menu-4 > li > a:hover span {
  --m: calc(16px * -1);
}
.main-menu .mega-menu li a, .main-menu .mega-menu-2 li a, .main-menu-4 .mega-menu li a, .main-menu-4 .mega-menu-2 li a {
  color: var(--white);
}

.main-dropdown {
  position: absolute;
  width: 300px;
  left: 0;
  top: 85px;
  z-index: 9;
  background-color: var(--black-2);
  text-align: left;
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.main-dropdown .sub-dropdown {
  position: absolute;
  width: 300px;
  left: 100%;
  top: 0;
  z-index: 9;
  background-color: var(--black-2);
  text-align: left;
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.main-dropdown li {
  display: block;
  margin: 0;
  padding: 0;
}
.main-dropdown li:hover .sub-dropdown {
  opacity: 1;
  visibility: visible;
}
.main-dropdown li a {
  color: var(--white);
  display: block;
  padding: 10px 20px;
  text-transform: capitalize;
}
.main-dropdown li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}
.main-dropdown li a:hover {
  letter-spacing: 1px;
}

.mega-menu, .mega-menu-2 {
  position: absolute;
  width: 100%;
  left: 0;
  top: 90px;
  z-index: 9;
  background-color: var(--black-2);
  text-align: left;
  padding: 40px 200px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mega-menu, .mega-menu-2 {
    padding: 40px 100px;
  }
}
.mega-menu li, .mega-menu-2 li {
  padding: 0;
  margin: 0;
}
.mega-menu li a, .mega-menu-2 li a {
  color: var(--white);
  font-size: 20px;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.mega-menu li a .menu-text, .mega-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}
.mega-menu li a:hover, .mega-menu-2 li a:hover {
  letter-spacing: 1px;
}
.mega-menu li a:not([href]):not([class]), .mega-menu-2 li a:not([href]):not([class]) {
  color: var(--white);
}
.mega-menu li a:not([href]):not([class]):hover, .mega-menu-2 li a:not([href]):not([class]):hover {
  letter-spacing: 0;
  background-color: transparent;
}
.mega-menu li ul, .mega-menu-2 li ul {
  text-align: left;
}
.mega-menu li ul li, .mega-menu-2 li ul li {
  display: block;
  margin: 0;
}
.mega-menu li ul li a, .mega-menu-2 li ul li a {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 12px;
  display: block;
  margin-bottom: 0;
  text-transform: capitalize;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mega-menu li ul li a, .mega-menu-2 li ul li a {
    padding: 6px 12px;
  }
}
.mega-menu-2 {
  width: 1160px;
  padding: 40px;
  left: 50%;
  transform: translateX(-50%);
  grid-template-columns: repeat(4, 1fr);
}

.menu-heading {
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  padding-bottom: 10px;
  text-transform: capitalize;
}

.footer-menu, .footer-menu-2 {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .footer-menu, .footer-menu-2 {
    text-align: center;
  }
}
.footer-menu li, .footer-menu-2 li {
  display: inline-block;
  padding-left: 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer-menu li, .footer-menu-2 li {
    padding-left: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu li, .footer-menu-2 li {
    padding-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu li, .footer-menu-2 li {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-menu li, .footer-menu-2 li {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.footer-menu li a, .footer-menu-2 li a {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: var(--black-2);
  text-transform: capitalize;
}
.footer-menu li a .menu-text, .footer-menu-2 li a .menu-text {
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-2);
}
.footer-menu li a .menu-text span, .footer-menu-2 li a .menu-text span {
  display: block;
  backface-visibility: hidden;
  font-style: normal;
  transition: transform 0.4s ease;
  transform: translateY(var(--m)) translateZ(0);
}
.footer-menu li a .menu-text span:nth-child(1), .footer-menu-2 li a .menu-text span:nth-child(1) {
  transition-delay: 0.05s;
}
.footer-menu li a .menu-text span:nth-child(2), .footer-menu-2 li a .menu-text span:nth-child(2) {
  transition-delay: 0.1s;
}
.footer-menu li a .menu-text span:nth-child(3), .footer-menu-2 li a .menu-text span:nth-child(3) {
  transition-delay: 0.15s;
}
.footer-menu li a .menu-text span:nth-child(4), .footer-menu-2 li a .menu-text span:nth-child(4) {
  transition-delay: 0.2s;
}
.footer-menu li a .menu-text span:nth-child(5), .footer-menu-2 li a .menu-text span:nth-child(5) {
  transition-delay: 0.25s;
}
.footer-menu li a .menu-text span:nth-child(6), .footer-menu-2 li a .menu-text span:nth-child(6) {
  transition-delay: 0.3s;
}
.footer-menu li a .menu-text span:nth-child(7), .footer-menu-2 li a .menu-text span:nth-child(7) {
  transition-delay: 0.35s;
}
.footer-menu li a .menu-text span:nth-child(8), .footer-menu-2 li a .menu-text span:nth-child(8) {
  transition-delay: 0.4s;
}
.footer-menu li a .menu-text span:nth-child(9), .footer-menu-2 li a .menu-text span:nth-child(9) {
  transition-delay: 0.45s;
}
.footer-menu li a .menu-text span:nth-child(10), .footer-menu-2 li a .menu-text span:nth-child(10) {
  transition-delay: 0.5s;
}
.footer-menu li a .menu-text span:nth-child(11), .footer-menu-2 li a .menu-text span:nth-child(11) {
  transition-delay: 0.55s;
}
.footer-menu li a .menu-text span:nth-child(12), .footer-menu-2 li a .menu-text span:nth-child(12) {
  transition-delay: 0.6s;
}
.footer-menu li a .menu-text span:nth-child(13), .footer-menu-2 li a .menu-text span:nth-child(13) {
  transition-delay: 0.65s;
}
.footer-menu li a .menu-text span:nth-child(14), .footer-menu-2 li a .menu-text span:nth-child(14) {
  transition-delay: 0.7s;
}
.footer-menu li a .menu-text span:nth-child(15), .footer-menu-2 li a .menu-text span:nth-child(15) {
  transition-delay: 0.75s;
}
.footer-menu li a .menu-text span:nth-child(16), .footer-menu-2 li a .menu-text span:nth-child(16) {
  transition-delay: 0.8s;
}
.footer-menu li a .menu-text span:nth-child(17), .footer-menu-2 li a .menu-text span:nth-child(17) {
  transition-delay: 0.85s;
}
.footer-menu li a .menu-text span:nth-child(18), .footer-menu-2 li a .menu-text span:nth-child(18) {
  transition-delay: 0.9s;
}
.footer-menu li a .menu-text span:nth-child(19), .footer-menu-2 li a .menu-text span:nth-child(19) {
  transition-delay: 0.95s;
}
.footer-menu li a .menu-text span:nth-child(20), .footer-menu-2 li a .menu-text span:nth-child(20) {
  transition-delay: 1s;
}
.footer-menu li a .menu-text span:nth-child(21), .footer-menu-2 li a .menu-text span:nth-child(21) {
  transition-delay: 1.05s;
}
.footer-menu li a .menu-text span:nth-child(22), .footer-menu-2 li a .menu-text span:nth-child(22) {
  transition-delay: 1.1s;
}
.footer-menu li a .menu-text span:nth-child(23), .footer-menu-2 li a .menu-text span:nth-child(23) {
  transition-delay: 1.15s;
}
.footer-menu li a .menu-text span:nth-child(24), .footer-menu-2 li a .menu-text span:nth-child(24) {
  transition-delay: 1.2s;
}
.footer-menu li a .menu-text span:nth-child(25), .footer-menu-2 li a .menu-text span:nth-child(25) {
  transition-delay: 1.25s;
}
.footer-menu li a .menu-text span:nth-child(26), .footer-menu-2 li a .menu-text span:nth-child(26) {
  transition-delay: 1.3s;
}
.footer-menu li a .menu-text span:nth-child(27), .footer-menu-2 li a .menu-text span:nth-child(27) {
  transition-delay: 1.35s;
}
.footer-menu li a .menu-text span:nth-child(28), .footer-menu-2 li a .menu-text span:nth-child(28) {
  transition-delay: 1.4s;
}
.footer-menu li a .menu-text span:nth-child(29), .footer-menu-2 li a .menu-text span:nth-child(29) {
  transition-delay: 1.45s;
}
.footer-menu li a .menu-text span:nth-child(30), .footer-menu-2 li a .menu-text span:nth-child(30) {
  transition-delay: 1.5s;
}
.footer-menu li a .menu-text span:nth-child(31), .footer-menu-2 li a .menu-text span:nth-child(31) {
  transition-delay: 1.55s;
}
.footer-menu li a .menu-text span:nth-child(32), .footer-menu-2 li a .menu-text span:nth-child(32) {
  transition-delay: 1.6s;
}
.footer-menu li a .menu-text span:nth-child(33), .footer-menu-2 li a .menu-text span:nth-child(33) {
  transition-delay: 1.65s;
}
.footer-menu li a .menu-text span:nth-child(34), .footer-menu-2 li a .menu-text span:nth-child(34) {
  transition-delay: 1.7s;
}
.footer-menu li a .menu-text span:nth-child(35), .footer-menu-2 li a .menu-text span:nth-child(35) {
  transition-delay: 1.75s;
}
.footer-menu li a .menu-text span:nth-child(36), .footer-menu-2 li a .menu-text span:nth-child(36) {
  transition-delay: 1.8s;
}
.footer-menu li a .menu-text span:nth-child(37), .footer-menu-2 li a .menu-text span:nth-child(37) {
  transition-delay: 1.85s;
}
.footer-menu li a .menu-text span:nth-child(38), .footer-menu-2 li a .menu-text span:nth-child(38) {
  transition-delay: 1.9s;
}
.footer-menu li a .menu-text span:nth-child(39), .footer-menu-2 li a .menu-text span:nth-child(39) {
  transition-delay: 1.95s;
}
.footer-menu li a .menu-text span:nth-child(40), .footer-menu-2 li a .menu-text span:nth-child(40) {
  transition-delay: 2s;
}
.footer-menu li a .menu-text span:nth-child(41), .footer-menu-2 li a .menu-text span:nth-child(41) {
  transition-delay: 2.05s;
}
.footer-menu li a .menu-text span:nth-child(42), .footer-menu-2 li a .menu-text span:nth-child(42) {
  transition-delay: 2.1s;
}
.footer-menu li a .menu-text span:nth-child(43), .footer-menu-2 li a .menu-text span:nth-child(43) {
  transition-delay: 2.15s;
}
.footer-menu li a .menu-text span:nth-child(44), .footer-menu-2 li a .menu-text span:nth-child(44) {
  transition-delay: 2.2s;
}
.footer-menu li a .menu-text span:nth-child(45), .footer-menu-2 li a .menu-text span:nth-child(45) {
  transition-delay: 2.25s;
}
.footer-menu li a .menu-text span:nth-child(46), .footer-menu-2 li a .menu-text span:nth-child(46) {
  transition-delay: 2.3s;
}
.footer-menu li a .menu-text span:nth-child(47), .footer-menu-2 li a .menu-text span:nth-child(47) {
  transition-delay: 2.35s;
}
.footer-menu li a .menu-text span:nth-child(48), .footer-menu-2 li a .menu-text span:nth-child(48) {
  transition-delay: 2.4s;
}
.footer-menu li a .menu-text span:nth-child(49), .footer-menu-2 li a .menu-text span:nth-child(49) {
  transition-delay: 2.45s;
}
.footer-menu li a:hover, .footer-menu-2 li a:hover {
  --y: -4px;
}
.footer-menu li a:hover span, .footer-menu-2 li a:hover span {
  --m: calc(16px * -1);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu-2 {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-menu-2 {
    margin-bottom: 30px;
  }
}
.footer-menu-2 li a {
  color: var(--white);
  text-transform: uppercase;
}
.footer-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}

/* mean menu customize */
.offcanvas__menu-wrapper.mean-container {
  padding-top: 160px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 142px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 93px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 93px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 40px;
  }
}
.offcanvas__menu-wrapper.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}
.offcanvas__menu-wrapper.mean-container .mean-nav {
  background: none;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0 10px 100px;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.5;
  color: var(--white);
  text-transform: lowercase;
  border-top: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  transform: translateY(var(--y)) translateZ(0);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text {
  display: flex;
  overflow: hidden;
  text-shadow: 0 80px 0 var(--white);
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span {
  display: block;
  backface-visibility: hidden;
  font-style: normal;
  transition: transform 0.4s ease;
  transform: translateY(var(--m)) translateZ(0);
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(1) {
  transition-delay: 0.0333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(2) {
  transition-delay: 0.0666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(3) {
  transition-delay: 0.1s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(4) {
  transition-delay: 0.1333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(5) {
  transition-delay: 0.1666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(6) {
  transition-delay: 0.2s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(7) {
  transition-delay: 0.2333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(8) {
  transition-delay: 0.2666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(9) {
  transition-delay: 0.3s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(10) {
  transition-delay: 0.3333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(11) {
  transition-delay: 0.3666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(12) {
  transition-delay: 0.4s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(13) {
  transition-delay: 0.4333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(14) {
  transition-delay: 0.4666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(15) {
  transition-delay: 0.5s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(16) {
  transition-delay: 0.5333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(17) {
  transition-delay: 0.5666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(18) {
  transition-delay: 0.6s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(19) {
  transition-delay: 0.6333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(20) {
  transition-delay: 0.6666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(21) {
  transition-delay: 0.7s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(22) {
  transition-delay: 0.7333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(23) {
  transition-delay: 0.7666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(24) {
  transition-delay: 0.8s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(25) {
  transition-delay: 0.8333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(26) {
  transition-delay: 0.8666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(27) {
  transition-delay: 0.9s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(28) {
  transition-delay: 0.9333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(29) {
  transition-delay: 0.9666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(30) {
  transition-delay: 1s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(31) {
  transition-delay: 1.0333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(32) {
  transition-delay: 1.0666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(33) {
  transition-delay: 1.1s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(34) {
  transition-delay: 1.1333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(35) {
  transition-delay: 1.1666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(36) {
  transition-delay: 1.2s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(37) {
  transition-delay: 1.2333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(38) {
  transition-delay: 1.2666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(39) {
  transition-delay: 1.3s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(40) {
  transition-delay: 1.3333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(41) {
  transition-delay: 1.3666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(42) {
  transition-delay: 1.4s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(43) {
  transition-delay: 1.4333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(44) {
  transition-delay: 1.4666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(45) {
  transition-delay: 1.5s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(46) {
  transition-delay: 1.5333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(47) {
  transition-delay: 1.5666666667s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(48) {
  transition-delay: 1.6s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span:nth-child(49) {
  transition-delay: 1.6333333333s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a:hover {
  --y: -0px;
  background-color: transparent;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a:hover span {
  --m: calc(80px * -1);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 40px;
    padding-left: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 30px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 30px;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 30px;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 30px;
    padding-left: 15px;
  }
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
  width: 60px;
  height: 96px;
  justify-content: center;
  font-weight: 300;
  border: none !important;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 76px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 64px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 57px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 46px;
    width: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 36px;
    width: 36px;
    padding: 11px 12px 13px !important;
  }
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand:hover {
  background: var(--secondary);
  opacity: 1;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li li:first-child {
  border-top: 1px solid var(--black-4);
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
  font-size: 24px;
  text-transform: capitalize;
  border-top: none !important;
  padding: 20px 0 20px 150px;
  transition: all 0.3s;
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li li a:hover {
  letter-spacing: 1px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-left: 90px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-left: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-left: 60px;
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    font-size: 20px;
    padding: 15px 0 15px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    font-size: 18px;
    padding: 15px 0 15px 30px;
  }
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li li a.mean-expand {
  height: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a.mean-expand {
    height: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a.mean-expand {
    height: 23px;
  }
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li li li:last-child {
  border-bottom: 1px solid var(--black-4);
}
.offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
  padding-left: 180px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 30px;
  }
}
.offcanvas__menu-wrapper.mean-container .mean-bar {
  padding: 0;
  background: none;
}
.offcanvas__menu-wrapper.mean-container a.meanmenu-reveal {
  display: none !important;
}

.header__area-2 .main-dropdown {
  background-color: var(--white);
}
.header__area-2 .main-dropdown li a {
  color: var(--black-2);
}
.header__area-2 .main-dropdown li a:hover {
  letter-spacing: 1px;
}
.header__area-2 .main-dropdown li a .menu-text {
  text-shadow: 0 16px 0 var(--black-2);
}
.header__area-2 .main-dropdown .sub-dropdown {
  background-color: var(--white);
}
.header__area-2 .mega-menu, .header__area-2 .mega-menu-2 {
  background-color: var(--white);
}
.header__area-2 .mega-menu li a, .header__area-2 .mega-menu-2 li a {
  color: var(--black-2);
}
.header__area-2 .mega-menu li a:hover, .header__area-2 .mega-menu-2 li a:hover {
  letter-spacing: 1px;
}
.header__area-2 .mega-menu li a:not([href]):not([class]), .header__area-2 .mega-menu-2 li a:not([href]):not([class]) {
  color: var(--black-2);
}
.header__area-2 .mega-menu li a .menu-text, .header__area-2 .mega-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--black-2);
}

.dark .menu-heading {
  color: var(--black-2);
}

/*----------------------------------------*/
/*  10. MODAL CSS START
/*----------------------------------------*/
.modal__testimonial {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  z-index: 9991;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}
.modal__testimonial-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  border-radius: 5px;
  width: 760px;
  height: 500px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__testimonial-content {
    width: 700px;
    height: 480px;
  }
}
@media only screen and (max-width: 767px) {
  .modal__testimonial-content {
    width: 320px;
    height: 300px;
  }
}
.modal__testimonial-content iframe {
  width: 100%;
  height: 100%;
}
.modal__close {
  background: var(--secondary);
  color: var(--white);
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 100%;
  font-size: 18px;
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .modal__close {
    right: -5px;
    top: -5px;
  }
}
.modal__close:hover {
  color: var(--primary);
}
.modal__close-2 {
  background-color: transparent;
  color: var(--black-3);
  width: 30px;
  height: 30px;
  border: none;
  font-size: 24px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  transition: all 0.3s;
}
@media only screen and (max-width: 767px) {
  .modal__close-2 {
    right: 20px;
    top: 15px;
  }
}
.modal__close-2:hover {
  color: var(--black-2);
}
.modal__application {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 9991;
}
.modal__apply {
  padding: 100px 50px;
  max-width: 1130px;
  height: 96vh;
  margin: 0 auto;
  margin-top: 2vh;
  overflow-y: auto;
  position: relative;
  background-color: var(--white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal__apply {
    max-width: 960px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__apply {
    max-width: 720px;
  }
}
@media only screen and (max-width: 767px) {
  .modal__apply {
    padding: 50px 20px;
    margin-top: 0;
    height: 100vh;
  }
}
.modal__apply .wc-btn-primary, .modal__apply .wc-btn-secondary, .modal__apply .wc-btn-pink, .modal__apply .wc-btn-light, .modal__apply .wc-btn-black {
  width: 120px;
  height: 120px;
}
.modal__apply .form-top {
  text-align: center;
  padding-bottom: 50px;
}
.modal__apply .form-top img {
  margin-bottom: 40px;
}
.modal__apply .form-top p {
  font-size: 18px;
  color: var(--black-2);
  border: 1px solid var(--black-3);
  border-radius: 30px;
  display: inline-block;
  padding: 5px 35px;
  margin-top: 20px;
}
.modal__apply .input-apply {
  display: grid;
  grid-template-columns: 1fr 2.2fr;
  grid-gap: 90px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .modal__apply .input-apply {
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal__apply .input-apply {
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__apply .input-apply {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .modal__apply .input-apply {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
  }
}
.modal__apply .input-apply-2 {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 50px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .modal__apply .input-apply-2 {
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal__apply .input-apply-2 {
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__apply .input-apply-2 {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .modal__apply .input-apply-2 {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
  }
}
.modal__apply .input-apply-2 p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black);
}
.modal__apply .input-apply-2 input {
  width: 100%;
  border: 1px solid var(--white-4);
  outline: none;
  padding: 15px;
  transition: all 0.3s;
}
.modal__apply .input-apply-2 input:focus {
  border: 1px solid var(--black-3);
}
.modal__apply .input-apply p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black);
}
.modal__apply .input-apply textarea {
  width: 100%;
  height: 125px;
  border: 1px solid var(--white-4);
  outline: none;
  padding: 15px;
  transition: all 0.3s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__apply .input-apply textarea {
    height: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .modal__apply .input-apply textarea {
    height: 90px;
  }
}
.modal__apply .input-apply textarea:focus {
  border: 1px solid var(--black-3);
}
.modal__apply .form-btn button {
  margin-left: auto;
  margin-top: 30px;
  background-color: transparent;
  z-index: 1;
}
.modal__apply .form-btn-2 {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}
.modal__apply .form-btn-2 button {
  background-color: transparent;
  z-index: 1;
}
.modal-show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

/*----------------------------------------*/
/*  11. CURSOR CSS START
/*----------------------------------------*/
.cursor {
  position: fixed;
  left: 0;
  pointer-events: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: var(--white);
  background: var(--black-2);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  opacity: 0;
  mix-blend-mode: hard-light;
  transition: all 0.3s;
}
.cursor.large {
  width: 180px;
  height: 180px;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
}

.cursor1 {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.15s;
  z-index: 999;
}
@media (max-width: 1200px) {
  .cursor1 {
    display: none;
  }
}
.cursor1.hide {
  opacity: 0;
  visibility: hidden;
}

.cursor2 {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: var(--primary);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  z-index: 999;
}
@media (max-width: 1200px) {
  .cursor2 {
    display: none;
  }
}
.cursor2.hide {
  opacity: 0;
  visibility: hidden;
}
.cursor2.circle {
  width: 60px;
  height: 60px;
}

.cursor-testi {
  position: fixed;
  width: 80px;
  height: 80px;
  background-color: var(--black-2);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  z-index: 999;
}
@media (max-width: 1200px) {
  .cursor-testi {
    display: none;
  }
}
.cursor-testi.hide {
  opacity: 0;
  visibility: hidden;
}
.cursor-testi.play {
  width: 100px;
  height: 100px;
  mix-blend-mode: unset;
  background-color: var(--black-2);
}
.cursor-testi.play::after {
  position: absolute;
  content: "Play";
  color: var(--white);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#featured_cursor {
  background: var(--white);
  color: var(--black-2);
}

@media (max-width: 1200px) {
  #client_cursor {
    display: none;
  }
}

/*----------------------------------------*/
/*  12. HEADER CSS START
/*----------------------------------------*/
.header__area {
  position: fixed;
  width: 100px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9991;
  border-right: 1px solid rgba(240, 240, 240, 0.07);
  mix-blend-mode: exclusion;
  transition: all 0.5s;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .header__area {
    width: 62px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__area {
    width: 62px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__area {
    padding: 0 15px;
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__area {
    padding: 0 15px;
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
  .header__area {
    padding: 0 15px;
    width: 100%;
    height: auto;
  }
}
.header__area button {
  display: block;
  margin-top: -58px;
  padding: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__area button {
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__area button {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header__area button {
    margin-top: 0;
  }
}
.header__area-2 {
  padding: 3px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  transition: 0.5s;
}
.header__area-3 {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}
.header__area-3.sticky-3 {
  background-color: var(--white);
  border-bottom: 1px solid var(--white-2);
}
.header__area-7 {
  padding: 3px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
.header__inner {
  padding: 50px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__inner {
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__inner {
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .header__inner {
    padding: 5px 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}
.header__inner-2, .header__inner-3 {
  display: grid;
  grid-template-columns: 150px auto 0;
  padding: 0 30px;
  height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__inner-2, .header__inner-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__inner-2, .header__inner-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .header__inner-2, .header__inner-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
  }
}
.header__inner-3 {
  padding: 10px 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__inner-3 {
    padding: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__inner-3 {
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .header__inner-3 {
    padding: 20px 15px;
  }
}
.header__nav-2 {
  text-align: right;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__nav-2 {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__nav-2 {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .header__nav-2 {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__nav-icon {
    grid-column: -1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__nav-icon {
    grid-column: -1;
  }
}
@media only screen and (max-width: 767px) {
  .header__nav-icon {
    grid-column: -1;
  }
}
.header__nav-icon-2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.header__nav-icon-2 .menu-icon-2::after {
  position: absolute;
  content: "";
  width: 180px;
  height: 180px;
  right: -60px;
  top: -60px;
  border: 1px solid var(--black-4);
  border-radius: 100%;
  transition: all 0.3s;
}
.header__nav-icon-3 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.header__nav-icon-3 .search-icon {
  padding-top: 3px;
  padding-right: 50px;
  position: relative;
  font-size: 18px;
}
.header__nav-icon-3 .search-icon::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 20px;
  right: 25px;
  top: 5px;
  background-color: var(--white-2);
}
.header__nav-icon-3 #search_close {
  display: none;
  padding-right: 55px;
}
.header__nav-icon-3 img {
  margin-top: -5px;
}
.header__nav-icon-7 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.header__nav-icon-7 .menu-icon-2::after {
  position: absolute;
  content: "";
  width: 180px;
  height: 180px;
  right: -60px;
  top: -60px;
  border: 1px solid var(--gray);
  border-radius: 100%;
  transition: all 0.3s;
}
.header__search {
  position: fixed;
  width: 300px;
  right: 60px;
  top: 90px;
  padding: 15px;
  background-color: var(--white-4);
  border-radius: 3px;
  z-index: 99991;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
@media only screen and (max-width: 767px) {
  .header__search {
    right: 10px;
    top: 100px;
  }
}
.header__search.open-search {
  opacity: 1;
  visibility: visible;
  top: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__search.open-search {
    top: 85px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__search.open-search {
    top: 85px;
  }
}
@media only screen and (max-width: 767px) {
  .header__search.open-search {
    top: 85px;
  }
}
.header__search input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--white-2);
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__support {
    grid-column: 11/11;
    grid-row: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__support {
    grid-column: 11/11;
    grid-row: 1;
  }
}
@media only screen and (max-width: 767px) {
  .header__support {
    display: none;
  }
}
.header__support p {
  width: 215px;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.3;
  color: var(--gray-2);
  text-align: right;
  transform: rotate(-90deg);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__support p {
    transform: rotate(0deg);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__support p {
    transform: rotate(0deg);
  }
}
@media only screen and (max-width: 767px) {
  .header__support p {
    transform: rotate(0deg);
  }
}
.header__support p a {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: var(--white);
}
.header__logo-2 {
  display: flex;
  align-items: center;
  transition: all 0.5s;
}

.sticky-2 .header__nav-icon-2 .menu-icon-2::after {
  width: 100px;
  height: 100px;
  right: -20px;
  top: -30px;
}
.sticky-2 .header__logo-5 img {
  filter: invert(1);
}
.sticky-2 .header__nav-icon-5 {
  filter: invert(1);
}

.sticky-7 .header__nav-icon-7 .menu-icon-2::after {
  width: 100px;
  height: 100px;
  right: -20px;
  top: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-primary {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo-primary {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .logo-primary {
    display: none;
  }
}
.logo-secondary {
  display: none;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-secondary {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo-secondary {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .logo-secondary {
    display: block;
  }
}

.dark .menu-icon img {
  filter: invert(1);
}

/*----------------------------------------*/
/*  13. OFFCANVES CSS START
/*----------------------------------------*/
.offcanvas__area {
  background-color: var(--black-2);
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.offcanvas__body {
  display: grid;
  grid-template-columns: 0.9fr 3fr 1.3fr;
  height: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__body {
    grid-template-columns: 1fr 3fr 2fr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__body {
    grid-template-columns: 1fr 2fr 1.5fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__body {
    grid-template-columns: 1fr 2.3fr 1.8fr;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__body {
    grid-template-columns: 1fr;
  }
}
.offcanvas__left {
  display: grid;
  grid-template-rows: 0fr 1fr 0.2fr;
  border-right: 1px solid var(--black-4);
}
@media only screen and (max-width: 767px) {
  .offcanvas__left {
    display: none;
  }
}
.offcanvas__mid {
  overflow: hidden;
  overflow-y: auto;
}
@media only screen and (max-width: 767px) {
  .offcanvas__mid {
    margin-top: 75px;
    display: grid;
    grid-template-rows: 0fr 1fr 0.2fr;
    border-right: 1px solid var(--black-4);
  }
}
.offcanvas__mid::-webkit-scrollbar {
  width: 5px;
}
.offcanvas__mid::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--black);
  border-radius: 10px;
}
.offcanvas__mid::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}
.offcanvas__right {
  background-color: var(--black-4);
  padding: 0 50px;
  position: relative;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__right {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__right {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__right {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__right {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__right {
    display: none;
  }
}
.offcanvas__right .shape-1 {
  position: absolute;
  bottom: 0;
  left: 125px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__right .shape-1 {
    left: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__right .shape-1 {
    left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__right .shape-1 {
    left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__right .shape-1 {
    left: 0;
  }
}
.offcanvas__right .shape-2 {
  position: absolute;
  bottom: 90px;
  right: 110px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__right .shape-2 {
    right: 30px;
  }
}
.offcanvas__logo {
  padding: 55px 0 55px 50px;
  border-bottom: 1px solid var(--black-4);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__logo {
    padding: 30px 0 30px 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__logo {
    padding: 30px 0 30px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__logo {
    padding: 30px 0 30px 15px;
  }
}
.offcanvas__social {
  padding-left: 30px;
  padding-top: 220px;
  border-bottom: 1px solid var(--black-4);
}
.offcanvas__social.social-mid {
  display: none;
}
@media only screen and (max-width: 767px) {
  .offcanvas__social.social-mid {
    display: block;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__social {
    padding-left: 50px;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__social {
    padding-left: 30px;
    padding-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__social {
    padding-left: 15px;
    padding-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .offcanvas__social {
    padding-left: 15px;
    padding-top: 50px;
  }
}
.offcanvas__social .social-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__social .social-title {
    font-size: 20px;
  }
}
.offcanvas__social ul li {
  padding-bottom: 2px;
  display: inline-block;
  padding-right: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__social ul li {
    padding-bottom: 5px;
  }
}
.offcanvas__social ul li a {
  font-size: 15px;
  line-height: 1.5;
  color: var(--white);
}
.offcanvas__social ul li a:hover {
  color: var(--gray-2);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__social ul li a {
    font-size: 16px;
  }
}
.offcanvas__links {
  padding-left: 50px;
  padding-top: 110px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__links {
    padding-left: 30px;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__links {
    padding-left: 30px;
    padding-top: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__links {
    padding-left: 15px;
    padding-top: 40px;
  }
}
.offcanvas__links ul li {
  padding-bottom: 2px;
}
.offcanvas__links ul li a {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--white);
}
.offcanvas__links ul li a:hover {
  color: var(--gray-2);
}
.offcanvas__close {
  position: absolute;
  right: 40px;
  top: 30px;
}
@media only screen and (max-width: 767px) {
  .offcanvas__close {
    right: 15px;
    top: 15px;
  }
}
.offcanvas__close button {
  font-size: 30px;
  width: 60px;
  height: 60px;
  color: var(--white);
  background: #232323;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.offcanvas__close button:hover {
  color: var(--gray-2);
}
@media only screen and (max-width: 767px) {
  .offcanvas__close button {
    font-size: 24px;
    width: 48px;
    height: 48px;
  }
}
.offcanvas__search {
  padding-top: 190px;
  padding-bottom: 140px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__search {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__search {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__search {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}
.offcanvas__search form {
  position: relative;
}
.offcanvas__search input {
  color: #585858;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 20px 45px 20px 20px;
  border-radius: 30px;
  border: 1px solid #3C3C3C;
  background-color: transparent;
  outline: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__search input {
    padding: 15px 40px 15px 15px;
  }
}
.offcanvas__search input::placeholder {
  opacity: 1;
  color: #585858;
}
.offcanvas__search button {
  color: var(--white);
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  border-radius: 0 100% 100% 0;
}
.offcanvas__contact h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__contact h3 {
    font-size: 20px;
  }
}
.offcanvas__contact li {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 7px;
  max-width: 260px;
}
.offcanvas__contact li span {
  font-size: 10px;
  display: block;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__contact li {
    font-size: 16px;
  }
}
.offcanvas__contact li a {
  color: var(--white);
}
.offcanvas__contact li a:hover {
  color: var(--gray-2);
}

/*----------------------------------------*/
/*  14. HERO CSS START
/*----------------------------------------*/
.hero__area {
  background-color: var(--black);
  position: relative;
  overflow: hidden;
  height: 100%;
}
.hero__area br {
  display: block;
}
.hero__area .hero1_bg {
  position: absolute;
  bottom: 0;
  top: 0;
  z-index: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.hero__area .hero_1234 {
  position: relative;
  bottom: 0;
  top: 0;
  z-index: 0;
  left: 0;
  width: auto;
  height: 58px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area .hero_1234 {
    height: 58px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area .hero_1234 {
    height: 56px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area .hero_1234 {
    height: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area .hero_1234 {
    height: 52px;
    top: -4px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area .hero_1234 {
    height: 42px;
    top: -3px;
  }
}
@media (max-width: 575px) {
  .hero__area .hero_1234 {
    height: 38px;
  }
}
@media (max-width: 359px) {
  .hero__area .hero_1234 {
    height: 28px;
  }
}
.hero__area-2 {
  background-image: url("/imgs/bg/lines.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--black-2);
  overflow: hidden;
  position: relative;
}
.hero__area-2 .hero2-shape {
  position: absolute;
  left: 30%;
  top: 41%;
  max-width: 41%;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-2 .hero2-shape {
    top: 37%;
    left: 33%;
    max-width: 36%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-2 .hero2-shape {
    top: 32%;
    left: 31%;
    max-width: 40%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-2 .hero2-shape {
    left: 30%;
    top: 34%;
    max-width: 40%;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-2 .hero2-shape {
    left: 5%;
    top: 30%;
    max-width: 90%;
  }
}
.hero__area-2 .hero2-shape img {
  max-width: 100%;
}
.hero__area-2 .sec-title-3 {
  color: var(--white);
  padding-right: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-2 .sec-title-3 {
    padding-top: 7px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-2 .sec-title-3 {
    padding-bottom: 40px;
    padding-right: 75px;
  }
}
.hero__area-2 .sec-title-3:last-child {
  text-align: right;
  padding-top: 110px;
  padding-right: 0;
  margin-top: 30px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 90px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 0;
  }
}
.hero__area-2 .sec-title-wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .hero__area-2 .sec-title-wrapper {
    flex-direction: column;
  }
}
.hero__area-2 .sec-title-wrapper .bg-shape {
  background-image: url("/imgs/shape/10.png");
  background-repeat: no-repeat;
  background-position: right top;
}
.hero__area-3 {
  padding-top: 190px;
  padding-bottom: 125px;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-3 {
    padding-top: 160px;
    padding-bottom: 110px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-3 {
    padding-top: 140px;
    padding-bottom: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-3 {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 {
    padding-top: 140px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-3 {
    padding-top: 130px;
    padding-bottom: 0;
  }
}
.hero__area-3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("/imgs/shape/5.png");
  background-repeat: no-repeat;
  background-position: left;
  z-index: -1;
}
.hero__area-3::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  right: 50px;
  top: 140px;
  background-image: url("/imgs/shape/6.png");
  background-repeat: no-repeat;
  background-position: right top;
}
.hero__area-3 .hero3-img {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-3 .hero3-img {
    max-width: 1000px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-3 .hero3-img {
    max-width: 1000px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-3 .hero3-img {
    max-width: 800px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 .hero3-img {
    max-width: 760px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-3 .hero3-img {
    position: unset;
  }
}
.hero__area-3 .hero3-img-anim {
  overflow: hidden;
  position: relative;
  width: 350px;
  height: 100%;
}
.hero__area-3 .hero3-img-anim img {
  position: absolute;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 150%;
  margin-left: auto;
  margin-right: auto;
}
.hero__area-3 .sec-title-wrapper {
  display: inline-block;
  position: unset;
}
.hero__area-3 .sec-sub-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--black-2);
  position: relative;
  display: inline-block;
  padding-right: 30px;
  padding-bottom: 0px;
  text-transform: uppercase;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .hero__area-3 .sec-sub-title {
    padding-bottom: 15px;
  }
}
.hero__area-3 .sec-sub-title::after {
  position: absolute;
  content: "";
  width: 90px;
  height: 1px;
  left: 100%;
  top: 12px;
  background-color: var(--black-2);
}
.hero__area-3 .sec-title {
  z-index: 2;
  font-weight: 600;
  font-size: 306px;
  line-height: 0.82;
  text-transform: uppercase;
  color: var(--black-2);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-3 .sec-title {
    font-size: 260px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-3 .sec-title {
    font-size: 260px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-3 .sec-title {
    font-size: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 .sec-title {
    font-size: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-3 .sec-title {
    font-size: 90px;
  }
}
.hero__area-3 .title-right {
  position: absolute;
  right: 0;
}
.hero__area-3 .wrapper {
  --clip-path: circle(65px at left);
  --clip-path-hover: circle(70px at left);
  --clip-path-clicked: circle(100vw at left);
  --duration: .4s;
  --timing-function: ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 .wrapper {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-3 .wrapper {
    display: none;
  }
}
.hero__area-3 .video-info {
  width: 300px;
  position: relative;
  left: 500px;
  top: -115px;
  height: 200px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-3 .video-info {
    left: 200px;
    top: -50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 .video-info {
    left: 280px;
    top: -235px;
  }
}
.hero__area-3 .video {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: var(--clip-path);
  transition: clip-path var(--duration) var(--timing-function);
  position: relative;
  top: 55px;
  left: 100px;
}
.hero__area-3 .video::before {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  background: url("/imgs/icon/play-icon.png");
  content: "";
  z-index: 999999999999;
  background-size: 100%;
}
.hero__area-3 .video video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: auto;
  width: auto;
  height: auto;
}
.hero__area-3 .intro-title {
  position: relative;
  left: 175px;
  top: 70px;
}
.hero__area-3 .intro-title .video-title {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
}
.hero__area-3 .intro-title .video-title span {
  display: block;
  font-size: 18px;
}
.hero__area-3 .intro-title .video-title.close-video-title {
  display: none;
}
.hero__area-3 #video_check {
  width: 200px;
  height: 200px;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-radius: 40px;
  outline: none;
  z-index: 2;
  appearance: none;
  cursor: pointer;
  z-index: 9999999;
}
.hero__area-3 #video_check:focus {
  outline: 0;
}
.hero__area-3 #video_check:hover ~ .video {
  clip-path: var(--clip-path-hover);
}
.hero__area-3 #video_check:hover ~ .text::before {
  --r: 25px;
  --opacity: 1;
}
.hero__area-3 #video_check:hover ~ .text::after {
  clip-path: var(--clip-path-hover);
}
.hero__area-3 #video_check:hover ~ .text > span::after {
  clip-path: var(--clip-path-hover);
}
.hero__area-3 #video_check:checked {
  width: 100%;
  height: 100%;
  border-radius: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.hero__area-3 #video_check:checked ~ .video {
  clip-path: var(--clip-path-clicked);
}
.hero__area-3 #video_check:checked ~ .text {
  --opacity: 0;
  transition: opacity 0.3s var(--timing-function);
}
.hero__area-3 #video_check:checked ~ .text::after {
  clip-path: var(--clip-path-clicked);
}
.hero__area-3 #video_check:checked ~ .text > span::after {
  clip-path: var(--clip-path-clicked);
}
.hero__area-3.start-video .hero3-img {
  opacity: 0;
}
.hero__area-3.start-video .sec-title,
.hero__area-3.start-video .sec-sub-title,
.hero__area-3.start-video .hero__text-3 p,
.hero__area-3.start-video .intro-title .video-title {
  color: var(--white-4);
}
.hero__area-3.start-video .sec-sub-title::after {
  background-color: var(--white);
}
.hero__area-4 {
  padding-top: 110px;
  height: 100%;
}
@media only screen and (min-width: 1920px) {
  .hero__area-4 {
    height: 100vh;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-4 {
    padding-top: 60px;
  }
}
.hero__area-10 {
  height: 100vh;
  padding: 90px 30px 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-10 {
    padding: 120px 30px 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-10 {
    padding: 100px 30px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-10 {
    padding: 100px 20px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-10 {
    padding: 100px 20px 0;
  }
}
@media only screen and (max-width: 767px) {
  .hero__area-10 {
    padding: 100px 15px 0;
  }
}
.hero__content {
  display: grid;
  justify-content: center;
  align-content: center;
  min-height: calc(100vh - 200px);
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  height: 100%;
}
.hero__content a {
  display: inline-block;
  max-width: 300px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white);
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
}
.hero__content a:hover i {
  transform: translate(20px, -20px) rotate(-45deg);
}
.hero__content a:hover span::after {
  opacity: 1;
  visibility: visible;
  transform: translate(20px, -20px) rotate(-45deg);
}
.hero__content a span {
  position: absolute;
  top: 0;
  right: 0;
}
.hero__content a span i {
  transform: translate(0, 0) rotate(-45deg);
  transition: all 0.3s;
}
.hero__content a span::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.hero__title {
  font-weight: 500;
  font-size: 84px;
  line-height: 1.5;
  letter-spacing: 4px;
  color: var(--white);
  position: relative;
  font-family: "Poppins-regular";
}
.hero__title .title_1 {
  padding-left: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title .title_1 {
    padding-left: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title .title_1 {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title .title_1 {
    padding-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__title .title_1 {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title .title_2 {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__title .title_2 {
    padding-right: 0px;
  }
}
.hero__title .title_3 {
  padding-left: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title .title_3 {
    padding-left: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title .title_3 {
    padding-left: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title .title_3 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__title .title_3 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title .title_4 {
    padding-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__title .title_4 {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title .title_5 {
    padding-left: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title .title_5 {
    padding-left: 20px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__title {
    font-size: 82px;
    line-height: 1.7;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title {
    font-size: 78px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title {
    font-size: 64px;
    line-height: 1.6;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title {
    font-size: 78px;
    line-height: 1.3;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .hero__title {
    font-size: 60px;
    line-height: 1.3;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .hero__title {
    font-size: 56px;
    line-height: 1.3;
  }
}
@media (max-width: 359px) {
  .hero__title {
    font-size: 42px;
    line-height: 1.3;
  }
}
.hero__title-wrapper {
  position: relative;
}
.hero__title-wrapper .lg {
  display: block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title-wrapper .lg {
    display: block;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title-wrapper .lg {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title-wrapper .lg {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .hero__title-wrapper .lg {
    display: none;
  }
}
.hero__title-wrapper .md {
  display: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title-wrapper .md {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title-wrapper .md {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title-wrapper .md {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .hero__title-wrapper .md {
    display: block;
  }
}
.hero__sub-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: var(--gray-3);
  max-width: 410px;
  position: absolute;
  right: 60px;
  bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__sub-title {
    bottom: -10px;
    right: -170px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__sub-title {
    bottom: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__sub-title {
    font-size: 16px;
    bottom: -10px;
    right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__sub-title {
    position: unset;
    padding-top: 15px;
  }
}
.hero__sub-title span {
  color: var(--white);
}
.hero__about {
  background-image: url("/imgs/about/about-bg.png");
  background-repeat: no-repeat;
  background-position: top right;
  overflow: hidden;
}
.hero__about-content {
  padding-top: 170px;
}
@media only screen and (max-width: 767px) {
  .hero__about-content {
    padding-top: 250px;
  }
}
.hero__about-content .hero-title {
  padding-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .hero__about-content .hero-title {
    padding-bottom: 30px;
  }
}
.hero__about-info {
  display: grid;
  grid-template-columns: 1fr 2.5fr 2.5fr;
  padding-bottom: 80px;
  grid-gap: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-info {
    grid-template-columns: 1fr 2.5fr 2fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__about-info {
    grid-template-columns: 1fr 2.5fr 1fr;
    grid-gap: 20px;
    padding-bottom: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__about-info {
    padding-bottom: 60px;
  }
}
.hero__about-text {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .hero__about-text {
    grid-column: 1/-1;
    grid-row: 1;
    margin-bottom: 20px;
  }
}
.hero__about-text p {
  font-size: 18px;
  line-height: 1.4;
  padding-left: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-text p {
    padding-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__about-text p {
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .hero__about-text p {
    padding-left: 0;
  }
}
.hero__about-award {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.hero__about-award::before {
  position: absolute;
  content: "";
  left: 75px;
  top: 50%;
  width: 250px;
  height: 1px;
  background-color: var(--white-4);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__about-award::before {
    left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-award::before {
    width: 150px;
    left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .hero__about-award {
    position: absolute;
    top: 150px;
  }
}
.hero__about-video {
  margin-bottom: -10px;
  height: 800px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__about-video {
    height: 700px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__about-video {
    height: 650px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-video {
    height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__about-video {
    height: 470px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__about-video {
    height: auto;
  }
}
.hero__about-video iframe,
.hero__about-video video {
  width: 100%;
  height: auto;
}
.hero__about-row {
  position: inherit;
  margin-right: -310px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__about-row {
    margin-right: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__about-row {
    margin-right: -150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-row {
    margin-right: -150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__about-row {
    margin-right: -120px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__about-row {
    margin-right: calc(-0.5 * var(--bs-gutter-x));
  }
}
.hero__link {
  display: inline-block;
  max-width: 300px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white);
  position: absolute;
  top: 190px;
  transition: all 0.3s;
  overflow: hidden;
}
.hero__link:hover i {
  transform: translate(20px, -20px) rotate(-45deg);
}
.hero__link:hover span::after {
  opacity: 1;
  visibility: visible;
  transform: translate(20px, -20px) rotate(-45deg);
}
.hero__link span {
  position: absolute;
  top: 0;
  right: 0;
}
.hero__link span i {
  transform: translate(0, 0) rotate(-45deg);
  transition: all 0.3s;
}
.hero__link span::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

#hero_texture {
  position: relative;
}
#hero_canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero__content-8 {
  padding-top: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__content-8 {
    padding-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-8 {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__content-8 {
    padding-top: 80px;
    text-align: center;
  }
}
.hero__content-8 .discription {
  padding-top: 40px;
  font-size: 18px;
  max-width: 330px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-8 .discription {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .hero__content-8 .discription {
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 100%;
  }
}
.hero__content-8 .scroll-wrapper {
  display: grid;
  grid-template-columns: 300px auto;
}
@media only screen and (max-width: 767px) {
  .hero__content-8 .scroll-wrapper {
    display: block;
  }
}
.hero__content-8 .scroll-down {
  position: relative;
  padding-left: 100px;
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .hero__content-8 .scroll-down {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-8 .scroll-down {
    padding-left: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__content-8 .scroll-down {
    padding-left: 120px;
  }
}
.hero__content-8 .scroll-down span {
  position: absolute;
  left: 105px;
  top: -45px;
  transform: rotate(-90deg);
  font-size: 14px;
  text-transform: uppercase;
}
.hero__content-8 .scroll-down img {
  animation: wcSlideBottom 3s infinite;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__content-8 .scroll-down img {
    max-height: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-8 .scroll-down img {
    max-height: 100px;
  }
}

.portfolio__big {
  text-align: center;
  padding-bottom: 395px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__big {
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__big {
    padding-bottom: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__big {
    padding-bottom: 120px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__big {
    padding-bottom: 60px;
  }
}
.portfolio__big img {
  margin-top: -90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__big img {
    margin-top: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__big img {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__big img {
    margin-top: 0;
  }
}
.portfolio__big-inner {
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 80px);
  width: 600px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: -100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__big-inner {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__big-inner {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__big-inner {
    margin-top: 10px;
  }
}
.portfolio__big-inner img {
  object-fit: cover;
  object-position: center;
}

.bodypadding {
  padding: 110px 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bodypadding {
    padding: 70px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .bodypadding {
    padding: 30px 15px;
  }
}

.bodypadding-2 {
  padding: 120px 50px;
}
@media only screen and (max-width: 767px) {
  .bodypadding-2 {
    padding: 30px 15px;
  }
}

.dark .hero__content-8 .scroll-down img {
  filter: invert(1);
}
.dark .hero__content-8 .scroll-down span {
  color: var(--white);
}

/*----------------------------------------*/
/*  15. SLIDER CSS START
/*----------------------------------------*/
.roll__area {
  background-color: var(--primary);
  padding: 31px 10px;
}
.roll__area-2 {
  padding: 31px 10px;
  background-color: var(--black-2);
}
.roll__area-2 .roll__slide h2 {
  color: var(--white);
}
.roll__area-3 {
  padding: 31px 10px;
  background-color: var(--pink-2);
}
.roll__area-3 .roll__slide h2 {
  color: var(--black-2);
}
.roll__wrapper {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}
.roll__slide {
  text-align: center;
}
.roll__slide h2 {
  font-weight: 400;
  font-size: 26px;
  line-height: 1.1;
  text-transform: uppercase;
  color: var(--black);
}

.contact_wrap {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_wrap {
    margin-top: 60px;
  }
}
.contact_wrap:hover .link {
  opacity: 1;
  visibility: visible;
}
.contact_wrap .link {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 18px;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  background: var(--black-2);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  text-transform: capitalize;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
@media (max-width: 1365px) {
  .contact_wrap .link {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 1365px) {
  .contact_wrap .link {
    opacity: 1;
    visibility: visible;
  }
}

.rollslide_wrap {
  text-align: center;
  overflow: hidden;
  margin-bottom: -10px;
}
.rollslide_title {
  color: var(--black-2);
  font-size: 250px;
  line-height: 1;
  transition: all 0.3s;
  text-transform: uppercase;
}
.rollslide_title-1 {
  color: var(--black-2);
  font-size: 245px;
  line-height: 0.75;
  text-transform: uppercase;
}
.rollslide_title-1 span {
  font-family: "Poppins";
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .rollslide_title-1 {
    font-size: 160px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rollslide_title-1 {
    font-size: 120px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .rollslide_title-1 {
    font-size: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rollslide_title-1 {
    font-size: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .rollslide_title-1 {
    font-size: 48px;
  }
}
.rollslide_title:hover {
  color: var(--black-3);
}
.rollslide_title span {
  font-weight: 400;
  font-family: "newYork";
}
@media only screen and (max-width: 767px) {
  .rollslide_title {
    font-size: 48px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rollslide_title {
    font-size: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rollslide_title {
    font-size: 120px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .rollslide_title {
    font-size: 180px;
  }
}

@media only screen and (max-width: 767px) {
  footer .contact_wrap {
    margin-top: 40px;
  }
}
.dark .rollslide_title-1 {
  color: var(--white);
}
.dark .contact_wrap:hover .link {
  color: var(--black-2);
  background-color: var(--white);
}
@media (max-width: 1365px) {
  .dark .contact_wrap .link {
    color: var(--black-2);
    background-color: var(--white);
  }
}

/*----------------------------------------*/
/*  16. ABOUT CSS START
/*----------------------------------------*/
.about__area {
  overflow: hidden;
  background-color: var(--white);
}
.about__area .sec-title {
  max-width: 700px;
  position: relative;
  z-index: 9;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .about__area .sec-title {
    max-width: 770px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__area .sec-title {
    max-width: 700px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area .sec-title {
    max-width: 700px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area .sec-title {
    max-width: 390px;
  }
}
@media only screen and (max-width: 767px) {
  .about__area .sec-title {
    max-width: 700px;
  }
}
.about__area-2 .sec-title {
  color: var(--black-2);
}
.about__area-2 .sec-title-wrapper {
  max-width: 770px;
  padding-bottom: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-2 .sec-title-wrapper {
    max-width: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .about__area-2 .sec-title-wrapper {
    padding-bottom: 50px;
  }
}
.about__area-2 .sec-text {
  max-width: 440px;
}
@media only screen and (max-width: 767px) {
  .about__area-2 .sec-text {
    padding-bottom: 40px;
  }
}
.about__area-2 .sec-text p {
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-3);
}
.about__area-2 .sec-text-wrapper {
  display: flex;
  justify-content: right;
  gap: 215px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-2 .sec-text-wrapper {
    gap: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-2 .sec-text-wrapper {
    gap: 50px;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 767px) {
  .about__area-2 .sec-text-wrapper {
    flex-direction: column;
    gap: 50px;
  }
}
.about__area-2 #btn_wrapper,
.about__area-2 .btn_wrapper {
  margin-left: 0;
  margin-right: -40px;
  margin-top: -50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-2 #btn_wrapper,
  .about__area-2 .btn_wrapper {
    margin-left: auto;
    margin-right: 0;
    margin-top: -20px;
  }
}
.about__area-3 {
  background-image: url("/imgs/shape/7.png");
  background-repeat: no-repeat;
  background-position: 100% 30%;
  background-color: var(--black-2);
  position: relative;
}
.about__area-3::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 60%;
  bottom: 0px;
  background-color: var(--black-5);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-3::after {
    left: 55%;
  }
}
@media only screen and (max-width: 767px) {
  .about__area-3::after {
    width: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-3 .pt-140 {
    padding-top: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-3 .pt-140 {
    padding-top: 90px;
  }
}
.about__area-3 .sec-title {
  color: var(--white);
  text-transform: uppercase;
  padding-bottom: 40px;
  background-color: var(--black-2);
}
.about__area-3 .sec-sub-title {
  color: var(--white);
  text-transform: uppercase;
  padding-top: 45px;
  background-color: var(--black-2);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-3 .sec-sub-title {
    padding-top: 0;
  }
}
.about__area-3 .sec-text p {
  max-width: 410px;
  font-size: 18px;
  line-height: 1.4;
  color: var(--gray-2);
  padding-bottom: 22px;
}
@media only screen and (max-width: 767px) {
  .about__area-3 .sec-text p {
    padding-bottom: 50px;
  }
}
.about__area-3 .sec-text-wrapper {
  padding-left: 210px;
  padding-top: 45px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 160px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 160px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 65px;
    padding-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 0;
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 0;
    padding-top: 15px;
  }
}
.about__area-8 .about-sub-right {
  color: var(--black-7);
  padding-bottom: 10px;
}
.about__title-wrapper {
  padding-left: 190px;
  padding-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__title-wrapper {
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .about__title-wrapper {
    padding-left: 0;
  }
}
.about__content {
  padding-top: 65px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__content {
    padding-top: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .about__content {
    padding-top: 50px;
  }
}
.about__content p {
  max-width: 440px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-3);
  margin-bottom: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__content p {
    max-width: 550px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__content p {
    max-width: 90%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__content p {
    margin-bottom: 30px;
    max-width: 440px;
  }
}
@media only screen and (max-width: 767px) {
  .about__content p {
    margin-bottom: 30px;
    max-width: 510px;
  }
}
.about__content-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__content-wrapper {
    grid-gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__content-wrapper {
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .about__content-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
.about__img img {
  width: 100%;
  overflow: hidden;
}
.about__img .img-anim {
  height: 565px;
  overflow: hidden;
}
.about__img-right {
  position: absolute;
  margin-right: 0;
  top: 220px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-right {
    position: absolute;
    top: 190px;
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .about__img-right {
    display: none;
    position: relative;
    top: 30px;
    right: unset;
    text-align: end;
  }
}
.about__img-right img {
  width: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-right img {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-right img {
    display: block;
  }
}
.about__img-bottom {
  display: none;
  position: absolute;
  margin-right: 0;
  top: 220px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-bottom {
    display: none;
    position: absolute;
    top: 190px;
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .about__img-bottom {
    display: block;
    position: relative;
    top: 0px;
    right: unset;
    text-align: end;
  }
}
.about__img-bottom img {
  width: auto;
  max-width: 60%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-bottom img {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-bottom img {
    display: block;
  }
}
.about__img-2 {
  max-width: 1720px;
  margin: 0 auto;
  overflow: hidden;
  max-height: 800px;
  margin-bottom: 140px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-2 {
    margin-bottom: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-2 {
    margin-bottom: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .about__img-2 {
    margin-bottom: 0;
  }
}
.about__img-2 img {
  width: 100%;
}
.about__img-3 {
  overflow: hidden;
  position: relative;
  width: calc(100% - 85px);
  height: 770px;
}
.about__img-3 img {
  position: absolute;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 110%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__img-3 {
    overflow: hidden;
    position: relative;
    width: calc(100% - 40px);
    height: 660px;
  }
  .about__img-3 img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 120%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-3 {
    width: 100%;
    height: unset;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-3 {
    width: 100%;
    height: unset;
  }
}
@media only screen and (max-width: 767px) {
  .about__img-3 {
    width: 100%;
    height: unset;
  }
}
.about__img-3 img {
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-3 img {
    position: unset;
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-3 img {
    position: unset;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
  .about__img-3 img {
    position: unset;
    height: auto;
  }
}

.portfolio__item {
  transform: perspective(4000px) rotateX(90deg);
}

.section_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  position: relative;
  margin-top: -25px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .section_wrapper {
    margin-bottom: 30px;
  }
}
.section_wrapper::before {
  content: "";
  position: absolute;
  bottom: 48%;
  left: 60px;
  height: 1px;
  width: calc(100% - 60px);
  background-color: var(--gray-11);
}
@media only screen and (max-width: 767px) {
  .section_wrapper::before {
    left: 48px;
    width: calc(100% - 48px);
  }
}

.react_border {
  position: relative;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  display: inline-block;
  background-color: transparent;
  border: 1px solid var(--gray-11);
  transform: rotate(45deg);
}
@media only screen and (max-width: 767px) {
  .react_border {
    height: 40px;
    width: 40px;
    line-height: 36px;
  }
}
.react_border span {
  font-weight: 400;
  font-size: 16px;
  color: var(--black-2);
  display: inline-block;
  transform: rotate(-45deg);
}

.about8__title {
  text-indent: 105px;
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--black-2);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .about8__title {
    font-size: 72px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about8__title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about8__title {
    font-size: 48px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about8__title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .about8__title {
    font-size: 36px;
    text-indent: 50px;
  }
}
.about8__title a {
  color: var(--black-2);
  font-family: "newYork";
  position: relative;
}
.about8__title a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 10px;
  background-color: var(--black-2);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about8__title a::after {
    bottom: 3px;
  }
}
@media only screen and (max-width: 767px) {
  .about8__title a::after {
    bottom: 3px;
  }
}
.about8__content {
  float: right;
}
.about8__content p {
  line-height: 1.3;
  max-width: 515px;
  text-indent: 65px;
  font-size: 24px;
  margin-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about8__content p {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about8__content p {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .about8__content p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 1.5;
  }
}

.workitems {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .workitems {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workitems {
    justify-content: center;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workitems {
    justify-content: center;
  }
}

.work8:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}
@media only screen and (max-width: 767px) {
  .work8:nth-child(2) {
    margin-top: 0;
  }
}
.work8:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.work8:nth-child(4) {
  margin-left: 50px;
  margin-bottom: 100px;
}
@media only screen and (max-width: 767px) {
  .work8:nth-child(4) {
    margin: 0;
  }
}
.work8:nth-child(5) {
  margin-top: 100px;
}
@media only screen and (max-width: 767px) {
  .work8:nth-child(5) {
    margin: 0;
  }
}
.work8:nth-child(6) {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work8 {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work8 {
    margin-bottom: 50px;
  }
}
.work8 img {
  max-width: 100%;
}
.work8__title {
  width: 320px;
  margin-bottom: 120px;
  padding-top: 90px;
  margin-left: 440px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work8__title {
    margin-bottom: 90px;
    padding-top: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work8__title {
    margin-left: 165px;
    margin-bottom: 90px;
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work8__title {
    margin-left: 40px;
    margin-bottom: 90px;
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .work8__title {
    margin-bottom: 40px;
    margin-left: 20px;
    padding-top: 40px;
  }
}
.work8__title p {
  font-size: 18px;
  text-indent: 65px;
  font-weight: 400;
  color: var(--black-2);
}
.work8__content {
  padding-top: 30px;
}
@media only screen and (max-width: 767px) {
  .work8__content {
    padding-top: 20px;
  }
}
.work8__content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--black-3);
}
.work8__content__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 5px;
}
.work8__content__title span {
  font-family: "newYork";
}
.work8__btn {
  text-align: center;
  margin-top: 115px;
  margin-bottom: 160px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work8__btn {
    margin-top: 60px;
    margin-bottom: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work8__btn {
    margin-top: 60px;
    margin-bottom: 120px;
  }
}
@media only screen and (max-width: 767px) {
  .work8__btn {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .work__area-8 {
    margin-bottom: 50px;
  }
}

.about__area-8 {
  padding-bottom: 170px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-8 {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-8 {
    padding-bottom: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .about__area-8 {
    padding-bottom: 80px;
  }
}

.dark .react_border span {
  color: var(--white);
}
.dark .about8__title,
.dark .about8__title a,
.dark .work8__title p,
.dark .work8__content__title,
.dark .about__area-8 .about-sub-right {
  color: var(--white);
}
.dark .work8__content p {
  color: var(--gray-2);
}

/*----------------------------------------*/
/*  17. SERVICE CSS START
/*----------------------------------------*/
.service__area {
  background-color: var(--black-2);
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area.pb-150 {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area.pb-150 {
    padding-bottom: 90px;
  }
}
.service__area a:first-child .service__item {
  border-top: 1px solid var(--secondary);
}
.service__area .sec-title,
.service__area .sec-sub-title {
  color: var(--white);
}
.service__area .sec-title-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.service__area-2 {
  background-color: var(--pink);
}
@media only screen and (max-width: 767px) {
  .service__area-2 {
    padding-bottom: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .service__area-2 .sec-text {
    padding-bottom: 30px;
  }
}
.service__area-2 .sec-text p {
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-2);
  max-width: 410px;
}
.service__area-3 {
  overflow: hidden;
}
.service__area-3 .sec-title {
  padding-bottom: 40px;
  text-transform: uppercase;
  background-color: var(--white);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-title {
    padding-bottom: 0;
  }
}
.service__area-3 .sec-title-wrapper {
  position: relative;
  padding-left: 330px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-3 .sec-title-wrapper {
    padding-left: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-title-wrapper {
    padding-left: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .service__area-3 .sec-title-wrapper {
    padding-left: 0;
  }
}
.service__area-3 .sec-title-wrapper::after {
  position: absolute;
  content: "";
  left: 40%;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--white-2);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-3 .sec-title-wrapper::after {
    left: 30%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-title-wrapper::after {
    left: 30%;
  }
}
@media only screen and (max-width: 767px) {
  .service__area-3 .sec-title-wrapper::after {
    width: 0;
  }
}
.service__area-3 .sec-title-wrapper p {
  padding-top: 70px;
  padding-bottom: 60px;
  max-width: 410px;
  font-size: 18px;
  line-height: 1.4;
  margin-left: 220px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-3 .sec-title-wrapper p {
    margin-left: 120px;
    padding-top: 30px;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-title-wrapper p {
    margin-left: 120px;
  }
}
@media only screen and (max-width: 767px) {
  .service__area-3 .sec-title-wrapper p {
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.service__area-3 .sec-sub-title {
  padding-top: 50px;
  text-transform: uppercase;
  background-color: var(--white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-3 .sec-sub-title {
    padding-top: 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-sub-title {
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .service__area-3 .sec-sub-title {
    padding-top: 0;
  }
}
.service__area-6 {
  background-color: var(--black-2);
  padding-bottom: 0;
}
.service__area-6.light {
  background-color: var(--white);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__area-6 {
    padding-left: 70px;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 {
    padding-top: 100px;
    padding-left: 88px;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 {
    padding-top: 90px;
    padding-left: 0;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .service__area-6 {
    padding-bottom: 0;
    padding-left: 0;
  }
}
.service__area-6 .inherit-row {
  margin-left: -180px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}
@media only screen and (max-width: 767px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}
.service__area-6 .content-wrapper {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .service__area-6 .content-wrapper {
    flex-direction: column;
  }
}
.service__area-6 .left-content {
  width: 21%;
  margin: auto 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 .left-content {
    width: 35%;
    padding-left: 50px;
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 .left-content {
    width: 30%;
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .service__area-6 .left-content {
    display: none;
  }
}
.service__area-6 .right-content {
  width: 39%;
  padding-left: 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__area-6 .right-content {
    padding-left: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__area-6 .right-content {
    padding-left: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 .right-content {
    width: 100%;
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 .right-content {
    width: 100%;
    padding-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .service__area-6 .right-content {
    width: 100%;
    padding-left: 0;
  }
}
.service__area-6 .mid-content {
  width: 35%;
  height: 100vh;
  position: relative;
  margin-left: 20px;
}
.service__area-6 .mid-content .service__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.service__area-6 .mid-content .service__image img {
  width: auto;
  height: 100vh;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 .mid-content {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 .mid-content {
    display: none;
  }
}
.service__area-6 .mid-content #img1 {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 767px) {
  .service__area-6 .mid-content {
    display: none;
  }
  .service__area-6 .right-content {
    padding-left: 0;
  }
}
.service__top-text {
  display: flex;
  align-items: center;
  height: 100%;
}
.service__top-text p {
  max-width: 505px;
  color: var(--gray-2);
  font-weight: 300;
  font-size: 20px;
  line-height: 1.4;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__top-text p {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__top-text p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .service__top-text p {
    font-size: 18px;
    margin: 30px 0;
  }
}
.service__top-text p span {
  color: var(--white);
}
.service__top-btn #btn_wrapper,
.service__top-btn .btn_wrapper {
  margin-left: auto;
  margin-right: -40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__top-btn #btn_wrapper,
  .service__top-btn .btn_wrapper {
    margin-right: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__top-btn #btn_wrapper,
  .service__top-btn .btn_wrapper {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .service__top-btn #btn_wrapper,
  .service__top-btn .btn_wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}
.service__list-wrapper {
  margin-top: 40px;
}
.service__img {
  transition: all 0.5s;
  opacity: 0;
  position: absolute;
  transform: rotate(0deg) translateX(-100px);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__img {
    max-width: 260px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__img {
    max-width: 200px;
  }
}
.service__img.active {
  opacity: 1;
  transform: rotate(15deg) translateX(60px) translateY(30px);
}
.service__img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__img-wrapper {
    display: none;
  }
}
.service__img-wrapper span {
  position: absolute;
  width: 175px;
  height: 70px;
  background-color: var(--primary);
  bottom: 0;
  left: 0;
  transform: rotate(-26deg) translate(-100%, -100%);
  transition: all 0.3s;
  transition-delay: 0.2s;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__img-wrapper span {
    bottom: 260px;
    height: 40px;
    width: 120px;
  }
}
.service__img-wrapper span.current {
  transform: rotate(-26deg) translate(120px, -100%);
}
.service__img-3 {
  position: relative;
}
.service__img-3 img {
  top: -95px;
  position: absolute;
  transform: translateY(-30%);
  opacity: 0;
  transition: all 0.5s;
}
.service__img-6 img {
  max-width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__items-6 {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .service__items-6 {
    padding-top: 120px;
  }
}
.service__item {
  display: grid;
  grid-template-columns: 0.7fr 2fr 2.5fr 1fr;
  align-items: center;
  padding: 35px 0;
  border-bottom: 1px solid var(--secondary);
  transition: all 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item {
    gap: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .service__item {
    grid-template-columns: 1fr;
    padding: 25px 0 10px;
  }
}
.service__item:hover .service__number span,
.service__item:hover .service a {
  color: var(--white);
}
.service__item:hover .service__title {
  color: var(--white);
}
.service__item:hover .service__link p {
  color: var(--white);
}
.service__item:hover .service__link p i {
  transform: translate(60px, -60px) rotate(-45deg);
}
.service__item:hover .service__link p::after {
  opacity: 1;
  visibility: visible;
  transform: translate(20px, -20px) rotate(-45deg);
}
.service__item-2 {
  position: relative;
}
.service__item-2::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: -90px;
  top: 0;
  background-color: var(--black-2);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-2::before {
    left: -40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-2::before {
    left: -20px;
  }
}
.service__item-2:nth-child(3n+1)::before {
  position: unset;
}
.service__item-2 img {
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .service__item-2 img {
    margin-bottom: 20px;
  }
}
.service__item-2 p {
  color: var(--black-2);
  padding-bottom: 25px;
}
.service__item-2 ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black-2);
}
.service__item-3 {
  display: grid;
  grid-template-columns: 30% 45% 25%;
  border-bottom: 1px solid var(--white-2);
  padding: 55px 0 50px;
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-3 {
    grid-template-columns: 30% 50% 20%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-3 {
    grid-template-columns: 30% 50% 20%;
    padding: 30px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-3 {
    grid-template-columns: 30% 40% 24%;
    gap: 2%;
    padding: 50px 0;
  }
}
@media only screen and (max-width: 767px) {
  .service__item-3 {
    grid-template-columns: 1fr;
    padding: 40px 0;
  }
}
.service__item-3:first-child {
  border-top: 1px solid var(--white-2);
}
.service__item-3:hover .service__hover-3 {
  opacity: 1;
}
.service__item-6 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.service__item-6 .image-tab {
  display: none;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-6 {
    height: auto;
    flex-direction: unset;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 30px;
    margin-bottom: 60px;
  }
  .service__item-6:last-child {
    margin-bottom: 0;
  }
  .service__item-6 .service-p {
    padding-top: 100px;
  }
  .service__item-6 .image-tab {
    display: block;
  }
  .service__item-6 .image-tab img {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-6 {
    height: auto;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .service__item-6 {
    height: auto;
    padding-bottom: 80px;
  }
}
.service__item-6 p {
  max-width: 550px;
  font-size: 14px;
  line-height: 1.6;
  color: var(--gray-2);
  padding-bottom: 30px;
}
.service__item-6 p.light {
  color: var(--black-2);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-6 p {
    padding-bottom: 20px;
  }
}
.service__item-6 ul {
  display: grid;
  grid-template-columns: 240px auto;
  display: block;
  padding-bottom: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-6 ul {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 767px) {
  .service__item-6 ul {
    grid-template-columns: 1fr;
    padding-bottom: 20px;
  }
}
.service__item-6 ul li a {
  display: inline-block;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-6 ul li {
    font-size: 16px;
    padding-bottom: 5px;
  }
}
.service__item-6 ul li {
  display: block;
  padding-bottom: 10px;
}
.service__item-6 ul li a.light {
  color: var(--black-2);
}
.service__item-6 ul li:hover a {
  color: var(--primary);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__item-6 ul {
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-6 ul {
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-6 ul {
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-6 ul {
    padding-bottom: 50px;
  }
}
.service__hover-3 {
  width: 300px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%;
  opacity: 0;
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  margin: -200px 0 0 -150px;
  overflow: hidden;
  pointer-events: none;
}
@media (max-width: 1200px) {
  .service__hover-3 {
    width: 0;
    display: none;
  }
}
.service__number span {
  font-weight: 400;
  font-size: 28px;
  line-height: 1.2;
  color: var(--gray-2);
  transition: all 0.3s;
}
.service__title {
  display: block;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.3;
  color: var(--gray-2);
}
.service__title:hover {
  color: var(--white);
}
@media only screen and (max-width: 767px) {
  .service__title {
    padding: 10px 0 20px;
  }
  .service__title br {
    display: none;
  }
}
.service__title-2, .service__title-3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__title-2, .service__title-3 {
    font-size: 24px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .service__title-2, .service__title-3 {
    padding-bottom: 15px;
  }
}
.service__title-3 {
  text-transform: uppercase;
}
.service__title-3:hover {
  color: var(--black-2);
}
@media only screen and (max-width: 767px) {
  .service__title-3 {
    display: block;
    font-size: 30px;
    padding-bottom: 20px;
  }
}
.service__title-6 {
  font-weight: 500;
  font-size: 40px;
  line-height: 1.1;
  color: var(--white);
  padding-bottom: 20px;
}
.service__title-6.light {
  color: var(--black-2);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__title-6 {
    font-size: 32px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__title-6 {
    font-size: 32px;
    padding-top: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__title-6 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .service__title-6 {
    font-size: 30px;
  }
}
.service__text {
  max-width: 320px;
}
.service__text p {
  color: var(--gray-2);
}
.service__link {
  text-align: right;
  overflow: hidden;
}
.service__link p {
  display: inline-block;
  font-size: 48px;
  color: var(--gray-2);
}
.service__link p i {
  transform: translate(0, 0) rotate(-45deg);
  transition: all 0.3s;
}
.service__link p::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.service__btn-2 a {
  color: var(--black-2);
  border-color: var(--black-2);
}
.service__btn-2#btn_wrapper, .service__btn-2.btn_wrapper {
  margin: 0 auto;
  margin-top: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__btn-2#btn_wrapper, .service__btn-2.btn_wrapper {
    margin-top: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__btn-2#btn_wrapper, .service__btn-2.btn_wrapper {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .service__btn-2#btn_wrapper, .service__btn-2.btn_wrapper {
    margin: 0;
    margin-top: 60px;
  }
}
.service__btn-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .service__btn-3 {
    align-items: flex-start;
    margin-top: 30px;
  }
}
.service__btn-3 #btn_wrapper,
.service__btn-3 .btn_wrapper {
  width: 200px;
  height: 200px;
  margin-right: -15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__btn-3 #btn_wrapper,
  .service__btn-3 .btn_wrapper {
    margin-left: auto;
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .service__btn-3 #btn_wrapper,
  .service__btn-3 .btn_wrapper {
    margin-left: -15px;
  }
}
.service__list .active .service__number span,
.service__list .active .service__title,
.service__list .active .service__link p {
  color: var(--white);
}
.service__list-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__list-2 {
    gap: 100px 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__list-2 {
    gap: 90px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .service__list-2 {
    grid-template-columns: repeat(1, 1fr);
    gap: 60px;
  }
}
.service__list-3 {
  padding-top: 70px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .service__list-3 {
    padding-top: 40px;
  }
}
.service__list-6 {
  padding-top: 160px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__list-6 {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__list-6 {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__list-6 {
    padding-top: 60px;
    position: sticky;
    top: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__list-6 {
    padding-top: 60px;
    position: sticky;
    top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .service__list-6 {
    padding-top: 60px;
  }
}
.service__list-6 li {
  padding-bottom: 40px;
  inline-size: 150px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__list-6 li {
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__list-6 li {
    padding-bottom: 20px;
  }
}
.service__list-6 li.active a {
  color: var(--white);
}
.service__list-6 li.active a.light {
  color: var(--black);
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .service__list-6 li {
    padding-bottom: 20px;
  }
}
.service__list-6 li a {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5;
  color: var(--gray-2);
}
.service__list-6 li a.light {
  color: var(--black-3);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__list-6 li a {
    line-height: 1.4;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__list-6 li a {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .service__list-6 li a {
    font-size: 20px;
  }
}
.service__content-3 {
  max-width: 340px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__content-3 {
    padding-left: 30px;
  }
}
.service__content-3 p {
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-2);
}
.service__content-3 ul {
  padding-top: 20px;
  position: relative;
  z-index: 1;
}
.service__content-3 ul li {
  font-size: 18px;
  color: var(--black-2);
}
.service3__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.service3__img-wrap {
  width: 20vw;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  padding-bottom: 25vw;
}
@media only screen and (max-width: 767px) {
  .service3__img-wrap {
    display: none;
    position: unset;
  }
}
.service__detail {
  overflow: hidden;
}
.service__detail .sec-title {
  max-width: 630px;
  font-size: 40px;
  line-height: 1.3;
  padding-bottom: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__detail .sec-title {
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .service__detail .sec-title {
    font-size: 32px;
    padding-bottom: 30px;
  }
}
.service__detail-circle {
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .service__detail-circle {
    display: none;
  }
}
.service__detail-circle span {
  position: relative;
  display: block;
  width: 136px;
  height: 136px;
}
.service__detail-circle span::before {
  position: absolute;
  content: "";
  width: 170px;
  height: 2px;
  left: 50%;
  top: calc(50% - 1px);
  background-color: var(--black-2);
  border-radius: 100%;
}
.service__detail-circle span::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  left: calc(50% - 3px);
  top: calc(50% - 3px);
  background-color: var(--black-2);
  border-radius: 100%;
}
.service__detail-img {
  padding-right: 1px;
  position: relative;
  margin-bottom: 60px;
  z-index: 9;
}
@media only screen and (max-width: 767px) {
  .service__detail-img {
    margin-top: 30px;
  }
}
.service__detail-img img {
  width: 100%;
}
.service__detail-img img.sd-shape {
  width: auto;
  position: absolute;
  bottom: -15px;
  right: 20px;
}
@media only screen and (max-width: 767px) {
  .service__detail-img img.sd-shape {
    right: 5px;
    bottom: -30px;
  }
}
.service__detail-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__detail-content {
    grid-gap: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__detail-content {
    grid-gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__detail-content {
    grid-gap: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .service__detail-content {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
.service__detail-content p {
  font-size: 18px;
  line-height: 1.4;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-v2.pt-110 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-v2.pt-110 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .service-v2.pt-110 {
    padding-top: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-v3.pt-130 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-v3.pt-130 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .service-v3.pt-130 {
    padding-top: 120px;
  }
}
.service-v4.pb-150 {
  padding-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-v4 .pt-130 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-v4 .pt-130 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .service-v4 .pt-130 {
    padding-top: 120px;
  }
}
.service-v5 .portfolio__service-item, .service-v5.portfolio__service .sec-title, .service-v5.portfolio__service .sec-text, .service-v5.portfolio__service .sec-text p {
  opacity: 1;
}
.service-v5 .portfolio__service-item {
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-v5.pt-140 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-v5.pt-140 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .service-v5.pt-140 {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}
.service-v5.portfolio__service .sec-title {
  text-indent: 0;
}

.service__list-6 li a.active {
  color: red;
  transition: all 0.2s;
}

.service__area-6 .mid-content .service__image:nth-child(1) {
  z-index: 9;
}

.service__image:nth-child(2) {
  z-index: 8;
}

.service__image:nth-child(3) {
  z-index: 7;
}

.service__image:nth-child(4) {
  z-index: 6;
}

.service__image:nth-child(5) {
  z-index: 5;
}

.service__image:nth-child(6) {
  z-index: 4;
}

.service__image:nth-child(7) {
  z-index: 3;
}

.service__image:nth-child(8) {
  z-index: 2;
}

.service__image:nth-child(9) {
  z-index: 1;
}

.service__hero-2 {
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url("/imgs/home-7/sh-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-2 {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-2 {
    padding-bottom: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-2 {
    padding-bottom: 60px;
  }
}
.service__hero-2 .shape-1 {
  position: absolute;
  width: 132px;
  height: auto;
  right: 165px;
  top: 275px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__hero-2 .shape-1 {
    right: 100px;
    top: 180px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-2 .shape-1 {
    right: 30px;
    top: 200px;
    width: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-2 .shape-1 {
    max-width: 80px;
    right: 10px;
    top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-2 .shape-1 {
    width: 75px;
    right: 6px;
    top: 130px;
  }
}
.service__hero-inner-2 {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
@media only screen and (max-width: 767px) {
  .service__hero-inner-2 {
    grid-template-columns: 1fr;
  }
}
.service__hero-left-2 {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .service__hero-left-2 {
    grid-row: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-top: 30px;
  }
}
.service__hero-left-2 img {
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-left-2 img {
    max-width: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-left-2 img {
    max-width: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-left-2 img {
    max-width: 160px;
    margin: 0;
  }
}
.service__hero-left-2 .image-1, .service__hero-left-2 .image-3 {
  transform: translateX(-100px);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__hero-left-2 .image-1, .service__hero-left-2 .image-3 {
    transform: translateX(-40px);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-left-2 .image-1, .service__hero-left-2 .image-3 {
    transform: translateX(-30px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-left-2 .image-1, .service__hero-left-2 .image-3 {
    transform: translateX(-25px);
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-left-2 .image-1, .service__hero-left-2 .image-3 {
    transform: translateX(0);
  }
}
.service__hero-left-2 .image-2 {
  transform: translateX(62px);
}
@media only screen and (max-width: 767px) {
  .service__hero-left-2 .image-2 {
    transform: translateX(0);
  }
}
.service__hero-left-2 .image-4 {
  position: absolute;
  left: 318px;
  bottom: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-left-2 .image-4 {
    left: 278px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-left-2 .image-4 {
    left: 225px;
    bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-left-2 .image-4 {
    position: unset;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-right-2 {
    grid-row: 1;
  }
}
.service__hero-right-2 .title {
  font-weight: 500;
  font-size: 130px;
  line-height: 1;
  color: var(--black);
  text-transform: capitalize;
  padding-bottom: 40px;
  padding-top: 80px;
  padding-left: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-right-2 .title {
    font-size: 110px;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-right-2 .title {
    font-size: 90px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-right-2 .title {
    font-size: 60px;
    padding-bottom: 20px;
    padding-top: 0;
    padding-left: 0;
  }
}
.service__hero-right-2 .title span {
  display: block;
  padding-left: 170px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-right-2 .title span {
    padding-left: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-right-2 .title span {
    padding-left: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-right-2 .title span {
    padding-left: 60px;
  }
}
.service__hero-right-2 p {
  font-size: 18px;
  max-width: 340px;
  margin-left: 350px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-right-2 p {
    margin-left: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-right-2 p {
    margin-left: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-right-2 p {
    font-size: 16px;
    margin-left: 65px;
  }
}
.service__hero-right-2 .scroll {
  margin-left: 350px;
  margin-top: 95px;
  animation: wcSlideBottom 5s infinite linear;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-right-2 .scroll {
    margin-left: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-right-2 .scroll {
    margin-left: 220px;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .service__hero-right-2 .scroll {
    margin-left: 65px;
    margin-top: 40px;
  }
}

/*----------------------------------------*/
/*  18. COUNTER CSS START
/*----------------------------------------*/
.counter__area {
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__area .pt-150 {
    padding-top: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__area-3 {
    background-color: var(--pink-4);
    padding: 100px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__area-3 {
    background-color: var(--pink-4);
    padding: 90px 0;
  }
}
@media only screen and (max-width: 767px) {
  .counter__area-3 {
    background-color: var(--pink-4);
    padding: 60px 0;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__area-3 .sec-title-wrapper {
    padding-top: 120px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__area-3 .sec-title-wrapper {
    padding-top: 120px;
  }
}
.counter__area-3 .sec-sub-title {
  font-weight: 500;
  color: var(--black);
  text-transform: uppercase;
  padding-bottom: 150px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__area-3 .sec-sub-title {
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__area-3 .sec-sub-title {
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__area-3 .sec-sub-title {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__area-3 .sec-sub-title {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .counter__area-3 .sec-sub-title {
    display: none;
  }
}
.counter__area-3 .counter__number {
  color: var(--black-2);
  font-weight: 400;
  padding-bottom: 10px;
}
.counter__wrapper {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .counter__wrapper {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px 10px;
  }
}
.counter__wrapper-2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media only screen and (max-width: 767px) {
  .counter__wrapper-2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }
}
.counter__wrapper-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .counter__wrapper-3 {
    align-items: flex-start;
    gap: 50px 40px;
  }
}
.counter__item {
  width: 380px;
  height: 380px;
  border: 1px solid var(--white-2);
  border-radius: 100%;
  margin-left: -67px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__item {
    margin-left: -60px;
    width: 330px;
    height: 330px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__item {
    margin-left: -25px;
    width: 259px;
    height: 259px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__item {
    margin-left: -27px;
    width: 200px;
    height: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .counter__item {
    margin-left: 0;
    width: 160px;
    height: 160px;
  }
}
.counter__item:first-child {
  margin-left: 0;
}
.counter__item p {
  font-size: 20px;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__item p {
    font-size: 18px;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 767px) {
  .counter__item p {
    font-size: 16px;
    line-height: 1.3;
    padding: 0 7px;
  }
}
.counter__item-2 {
  text-align: center;
}
.counter__item-2 p {
  font-size: 20px;
  line-height: 1.3;
}
.counter__item-3 {
  padding-bottom: 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__item-3 {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__item-3 {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__item-3 {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .counter__item-3 {
    padding-bottom: 0;
    text-align: center;
  }
}
.counter__item-3 p {
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .counter__item-3 p {
    line-height: 1.2;
  }
}
.counter__number {
  font-family: "Poppins-regular";
  font-size: 80px;
  line-height: 1.1;
  color: var(--secondary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__number {
    font-size: 72px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__number {
    font-size: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .counter__number {
    font-size: 40px;
  }
}
.counter__img-3 {
  margin-top: 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__img-3 {
    margin-top: 0;
    text-align: right;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__img-3 {
    margin-top: 0;
    text-align: right;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__img-3 {
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__img-3 {
    margin-top: 0;
  }
}
.counter__img-3 img {
  width: 100%;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__img-3 img {
    width: 80%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__img-3 img {
    width: 75%;
  }
}

/*----------------------------------------*/
/*  19. WORKFLOW CSS START
/*----------------------------------------*/
.workflow__slider {
  padding-bottom: 120px;
}
@media only screen and (min-width: 1920px) {
  .workflow__slider {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .workflow__slider {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .workflow__slider {
    padding-bottom: 0;
  }
}
.workflow__slider .swipper-btn {
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  transition: all 0.3s;
}
@media only screen and (min-width: 1920px) {
  .workflow__slider .swipper-btn {
    display: none;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .workflow__slider .swipper-btn {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .workflow__slider .swipper-btn {
    display: none;
  }
}
.workflow__slider .swipper-btn:hover {
  background-color: var(--black-2);
}
.workflow__slider .swipper-btn:hover i {
  color: var(--white);
}
.workflow__slider .swipper-btn i {
  font-size: 18px;
  color: var(--black-3);
}
.workflow__slider .next-button {
  right: calc(50% + 10px);
}
.workflow__slider .prev-button {
  left: calc(50% + 10px);
}
.workflow__area {
  overflow: hidden;
}
.workflow__area .sec-title-wrapper {
  text-align: center;
  padding-bottom: 60px;
}
.workflow__area-3 {
  overflow: hidden;
  background-color: var(--pink-4);
  padding-top: 60px;
  direction: ltr;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__area-3 {
    padding-top: 90px;
    background-color: var(--pink-3);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__area-3 {
    padding-top: 80px;
    background-color: var(--pink-3);
  }
}
@media only screen and (max-width: 767px) {
  .workflow__area-3 {
    padding-top: 60px;
    background-color: var(--pink-3);
  }
}
.workflow__area-3 .wf_panel {
  width: 100%;
  height: 100%;
  will-change: transform;
  opacity: 1 !important;
}
.workflow__area-6 {
  overflow: hidden;
  padding-top: 5px;
}
@media only screen and (max-width: 767px) {
  .workflow__area-6 .pb-130 {
    padding-bottom: 20px;
  }
}
.workflow__wrapper-3 {
  width: 400%;
  height: 100%;
  display: flex;
  flex-wrap: no-wrap;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__wrapper-3 {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__wrapper-3 {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  .workflow__wrapper-3 {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
}
.workflow__wrapper-6 {
  border-top: 1px solid var(--white-4);
  padding-top: 50px;
}
@media only screen and (max-width: 767px) {
  .workflow__wrapper-6 {
    border-top: none;
    padding-top: 0;
  }
}
.workflow__slide {
  text-align: center;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__slide {
    padding: 0 10px;
  }
}
.workflow__slide::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  top: 58px;
  background-color: var(--black-2);
}
.workflow__slide::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  left: calc(50% - 9px);
  top: 50px;
  background-color: var(--primary);
  border: 3px solid var(--white);
  border-radius: 100%;
  animation: wcBubble 1.5s 1s infinite;
  transition: all 0.3s;
}
.workflow__slide p {
  max-width: 230px;
  margin: 0 auto;
}
.workflow__slide-6 {
  position: relative;
  text-align: center;
  padding: 0 25px;
}
@media only screen and (max-width: 767px) {
  .workflow__slide-6 {
    max-width: 100%;
    padding-bottom: 70px;
  }
}
.workflow__slide-6::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: -54px;
  background-color: var(--black);
  border: 1px solid var(--white);
  border-radius: 100%;
  animation: wcBubble 1.5s 1s infinite;
  transition: all 0.3s;
}
@media only screen and (max-width: 767px) {
  .workflow__slide-6::before {
    width: 0;
    height: 0;
  }
}
.workflow__step {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: var(--black-2);
  text-transform: capitalize;
  padding-bottom: 50px;
}
.workflow__number {
  font-size: 85px;
  font-weight: 500;
  color: var(--gray-4);
}
.workflow__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
  color: var(--black-2);
  padding-bottom: 15px;
}
.workflow__title-6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 1.25;
  color: var(--black);
  padding-bottom: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__title-6 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .workflow__title-6 {
    padding-bottom: 10px;
  }
}

.choose-title {
  font-weight: 500;
  font-size: 200px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--black);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .choose-title {
    font-size: 160px;
    margin-top: -100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-title {
    font-size: 48px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .choose-title {
    font-size: 40px;
  }
}
.choose-title-wrapper {
  text-align: center;
}
.choose-wrapper {
  display: flex;
  align-items: center;
}

.research__area {
  background-color: var(--pink-3);
  background-image: url("/imgs/shape/8.png");
  background-repeat: no-repeat;
  background-position: 200px calc(100% - 150px);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .research__area {
    background-position: 20px calc(100% - 230px);
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__area {
    background-position: 20px calc(100% - 220px);
  }
  .research__area.pt-150 {
    padding-top: 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area {
    background-position: 20px calc(100% - 100px);
    padding-bottom: 50px;
  }
  .research__area.pt-150 {
    padding-top: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area {
    padding-bottom: 40px;
    background-position: 0 210px;
    background-size: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .research__area {
    background-size: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area.pt-150 {
    padding-top: 60px;
  }
}
.research__area .sec-title {
  text-transform: uppercase;
  padding-bottom: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__area .sec-title {
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area .sec-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area .sec-title {
    padding-bottom: 30px;
  }
}
.research__area .sec-title-wrapper {
  padding-right: 80px;
  padding-bottom: 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__area .sec-title-wrapper {
    padding-right: 40px;
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area .sec-title-wrapper {
    padding-bottom: 40px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area .sec-title-wrapper {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .research__area .sec-title-wrapper {
    padding-right: 0;
    padding-bottom: 40px;
    text-align: center;
  }
}
.research__area .sec-title-wrapper p {
  max-width: 360px;
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-2);
  margin-left: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area .sec-title-wrapper p {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area .sec-title-wrapper p {
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .research__area .sec-title-wrapper p {
    margin: 0 auto;
  }
}
.research__area .sec-sub-title {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--black);
  padding-bottom: 140px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .research__area .sec-sub-title {
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__area .sec-sub-title {
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area .sec-sub-title {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area .sec-sub-title {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .research__area .sec-sub-title {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .research__list {
    padding-bottom: 35px;
  }
}
.research__tools {
  padding-left: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__tools {
    padding-left: 0;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__tools {
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .research__tools {
    padding-left: 0;
    padding-bottom: 40px;
    text-align: center;
  }
}
.research__tools li {
  display: inline-block;
}
.research__tools li a {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 30px;
  padding: 2px 15px;
}
.research__tools li a:hover {
  color: var(--white);
  background-color: var(--black-2);
}
.research__item {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 50px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .research__item {
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__item {
    padding-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .research__item {
    justify-content: center;
  }
}
.research__item:nth-child(odd) {
  padding-left: 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__item:nth-child(odd) {
    padding-left: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__item:nth-child(odd) {
    padding-left: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .research__item:nth-child(odd) {
    padding-left: 0;
  }
}
.research__item p {
  max-width: 255px;
  color: var(--black-2);
}
.research__number span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 48px;
  line-height: 1.1;
  text-transform: uppercase;
  color: var(--black-2);
  width: 190px;
  height: 190px;
  border: 3px solid;
  border-radius: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__number span {
    width: 160px;
    height: 160px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__number span {
    width: 160px;
    height: 160px;
  }
}
@media only screen and (max-width: 767px) {
  .research__number span {
    width: 130px;
    height: 130px;
    font-size: 36px;
  }
}
.research__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__title {
    padding-bottom: 5px;
  }
}

/*----------------------------------------*/
/*  20. BRAND CSS START
/*----------------------------------------*/
.brand__area {
  overflow: hidden;
}
.brand__area .pb-100 {
  padding-bottom: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__area .pb-100 {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .brand__area .pb-100 {
    padding-bottom: 0;
  }
}
.brand__area .sec-title-wrapper {
  padding-bottom: 60px;
}
.brand__list, .brand__list-3, .brand__list-2 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: var(--white);
  position: relative;
  z-index: 1;
  margin-left: 1px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand__list, .brand__list-3, .brand__list-2 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__list, .brand__list-3, .brand__list-2 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .brand__list, .brand__list-3, .brand__list-2 {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
  }
}
.brand__list-2 {
  gap: 40px;
  background-color: transparent;
  border-bottom: none;
  margin-left: 0;
}
.brand__list-3 {
  background-color: transparent;
  border-bottom: none;
  margin-left: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand__list-3 {
    gap: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__list-3 {
    gap: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .brand__list-3 {
    gap: 50px;
  }
}
.brand__item {
  padding: 40px;
  text-align: center;
  border-right: 1px solid var(--white-3);
  margin-left: -3px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .brand__item {
    padding: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__item {
    padding: 30px 25px;
  }
}
@media only screen and (max-width: 767px) {
  .brand__item {
    padding: 30px 0;
    border-left: 1px solid var(--white-3);
  }
  .brand__item:nth-child(even) {
    border-left: none;
  }
}
.brand__item-2 {
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__item-2 {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .brand__item-2 {
    padding-bottom: 20px;
  }
}
.brand__title-2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 50px;
  text-align: center;
}
.brand__title-3 {
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-2);
  text-align: center;
  padding-bottom: 65px;
  font-weight: 400;
  text-transform: uppercase;
}

.brand-gap {
  gap: 50px 0;
  border: none;
  background-color: transparent;
}

/*----------------------------------------*/
/*  22. PORTFOLIO CSS START
/*----------------------------------------*/
.portfolio__text {
  text-align: center;
  font-size: 150px;
  font-weight: 500;
  color: var(--white);
  text-transform: uppercase;
  padding: 120px 0 50px;
  opacity: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__text {
    font-size: 110px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__text {
    font-size: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__text {
    font-size: 45px;
    padding: 50px 0;
  }
}
.portfolio__area {
  position: relative;
  background-color: var(--black-2);
  overflow: hidden;
}
.portfolio__area.light {
  background-color: var(--white);
}
.portfolio__area-2 {
  overflow: hidden;
  position: relative;
}
.portfolio__area-2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 84.6%;
  top: 0;
  background: var(--white-4);
}
@media only screen and (max-width: 767px) {
  .portfolio__area-2 {
    padding-bottom: 15px;
  }
}
.portfolio__area-2::after {
  z-index: 9;
}
@media only screen and (max-width: 767px) {
  .portfolio__area-2::after {
    z-index: unset;
  }
}
.portfolio__area-2 .swiper-slide-active .sec-title {
  animation: wcfadeUp 1s 0.5s forwards;
}
.portfolio__area-2 .swiper-slide-active p {
  animation: wcfadeUp 1s 0.7s forwards;
}
.portfolio__area-2 .swiper-slide-active .btn-common-wrap {
  animation: wcfadeUp 1s 0.9s forwards;
}
.portfolio__area-2 .sec-title-wrapper {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .portfolio__area-2 .sec-title-wrapper {
    text-align: left;
  }
}
.portfolio__area-2 .sec-title-wrapper .sec-text {
  font-size: 24px;
  max-width: 410px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .portfolio__area-2 .sec-title-wrapper .sec-text {
    font-size: 20px;
  }
}
.portfolio__area-2 .sec-title-3 {
  font-weight: 400;
  background-image: url("/imgs/shape/1.png");
  background-repeat: no-repeat;
  background-position: 70% 25px;
  display: inline-block;
  padding-bottom: 120px;
  padding-right: 320px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-2 .sec-title-3 {
    background-size: 260px;
    background-position: 70% 20px;
    padding-right: 200px;
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-2 .sec-title-3 {
    background-size: 200px;
    background-position: 95% 0;
    padding-right: 100px;
    padding-bottom: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-2 .sec-title-3 {
    background-size: 150px;
    background-position: 60% 0;
    padding-left: 0;
    padding-bottom: 45px;
    padding-right: 0px;
  }
}
.portfolio__area-2 .sec-title-3 span {
  display: block;
  padding-left: 450px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-2 .sec-title-3 span {
    padding-right: 0;
    padding-left: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-2 .sec-title-3 span {
    padding-right: 0;
    padding-left: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-2 .sec-title-3 span {
    padding-right: 0;
    padding-left: 85px;
  }
}
.portfolio__area-3 {
  background-color: var(--black-2);
}
.portfolio__area-3 .sec-sub-title {
  text-transform: uppercase;
  color: var(--white);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-3 .sec-sub-title {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-3 .sec-sub-title {
    padding-bottom: 45px;
  }
}
.portfolio__area-3 .swiper-btn {
  display: flex;
  gap: 30px;
}
.portfolio__area-3 .swiper-btn div {
  font-size: 20px;
  color: var(--white);
  border: 1px solid var(--white);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  z-index: 9;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1365px) {
  .portfolio__area-3 .swiper-btn div {
    opacity: 1;
    visibility: visible;
    top: calc(50% - 100px);
  }
}
@media (max-width: 1023px) {
  .portfolio__area-3 .swiper-btn div {
    opacity: 1;
    visibility: visible;
    top: calc(50% - 25px);
  }
}
.portfolio__area-3 .swiper-btn div:hover {
  color: var(--black-2);
  background-color: var(--white);
}
.portfolio__area-3 .swiper-btn div.pp-prev {
  left: 26px;
}
.portfolio__area-3 .swiper-btn div.pp-next {
  right: 0;
}
@media (max-width: 1365px) {
  .portfolio__area-3 .swiper-btn div.pp-next {
    right: -24px;
  }
}
.portfolio__area-3 .swiper-pagination {
  width: 120px;
  left: 370px;
  top: 0;
  bottom: unset;
  font-weight: 400;
  font-size: 60px;
  line-height: 1;
  color: var(--white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__area-3 .swiper-pagination {
    left: 300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-3 .swiper-pagination {
    left: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-3 .swiper-pagination {
    left: -22px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-3 .swiper-pagination {
    left: 0;
  }
}
.portfolio__area-3 .swiper-pagination-total {
  font-size: 22px;
  display: flex;
  justify-content: flex-end;
  margin-top: -50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-6 .line {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-6 .line {
    padding-top: 60px;
  }
}
.portfolio__area-6 .zi-9 {
  position: relative;
  z-index: 9;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-6 .pin-spacer {
    top: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-6 .pin-spacer {
    top: 0 !important;
  }
}
@media (max-width: 575px) {
  .portfolio__area-6 .pin-spacer {
    top: 0 !important;
  }
}
.portfolio__area-6 .sec-title {
  font-size: 100px;
  padding-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__area-6 .sec-title {
    font-size: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-6 .sec-title {
    font-size: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-6 .sec-title {
    font-size: 56px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-6 .sec-title {
    font-size: 52px;
  }
}
.portfolio__area-6 .sec-title-wrapper {
  position: sticky;
  top: 130px;
}
.portfolio__area-6 .sec-title-wrapper p {
  font-size: 18px;
  line-height: 1.4;
  padding-left: 110px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-6 .sec-title-wrapper p {
    padding-left: 60px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-6 .sec-title-wrapper p {
    padding-left: 60px;
  }
}
.portfolio__area-6 .sec-title-wrapper p::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 1px;
  background-color: var(--white);
  left: 0;
  top: 8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-6 .sec-title-wrapper p::before {
    width: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__area-6 .sec-title-wrapper p::before {
    width: 50px;
  }
}
.portfolio__area-6 .sec-sub-title {
  font-size: 24px;
  padding-bottom: 0;
}
.portfolio__slider-2 {
  position: relative;
  height: 100vh;
}
.portfolio__slider-2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 50%;
  top: 0;
  background: var(--white-4);
}
.portfolio__slider-2::after {
  z-index: 9;
}
@media only screen and (max-width: 767px) {
  .portfolio__slider-2::after {
    z-index: unset;
  }
}
@media (max-width: 1400px) {
  .portfolio__slider-2 {
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__slider-2-pagination {
    display: none;
  }
}
.portfolio__slider-2-pagination .swiper-pagination-bullets {
  left: calc(100% - 135px);
  top: 50%;
  transform: translate3d(0px, -50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullets {
    left: calc(100% - 60px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullets {
    left: calc(100% - 45px);
  }
}
.portfolio__slider-2-pagination .swiper-pagination-bullet {
  display: block;
  margin: 15px 10px !important;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 1px solid #D6D6D6;
  opacity: 1;
  position: relative;
  transition: all 0.3s;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullet {
    margin: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullet {
    margin: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullet {
    width: 30px;
    height: 30px;
    margin: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullet {
    width: 25px;
    height: 25px;
    margin: 5px !important;
  }
}
.portfolio__slider-2-pagination .swiper-pagination-bullet::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  left: 50%;
  top: 50%;
  border-radius: 50px;
  background-color: #D6D6D6;
  transform: translate(-50%, -50%);
}
.portfolio__slider-2-pagination .swiper-pagination-bullet-active, .portfolio__slider-2-pagination .swiper-pagination-bullet:hover {
  border-color: var(--black-2);
}
.portfolio__slider-2-pagination .swiper-pagination-bullet-active::after, .portfolio__slider-2-pagination .swiper-pagination-bullet:hover::after {
  background-color: var(--black-2);
}
.portfolio__slider-3 {
  position: relative;
}
.portfolio__slider-3:hover .swiper-btn div {
  opacity: 1;
  visibility: visible;
}
.portfolio__slide-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: var(--white);
}
@media only screen and (max-width: 767px) {
  .portfolio__slide-2 {
    grid-template-columns: 1fr;
  }
}
.portfolio__slide-2 .sec-title {
  line-height: 1.1;
  padding-bottom: 30px;
  padding-left: 10px;
  opacity: 0;
}
.portfolio__slide-2 .sec-title a {
  color: var(--black-2);
  text-transform: capitalize;
}
.portfolio__slide-2 .sec-title span {
  display: block;
  padding-left: 60px;
  position: relative;
}
.portfolio__slide-2 .sec-title span::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 1px;
  left: -10px;
  top: 50%;
  background-color: var(--black-2);
}
.portfolio__slide-2 .slide-img {
  padding-right: 15px;
}
@media only screen and (max-width: 767px) {
  .portfolio__slide-2 .slide-img {
    padding-right: 0;
  }
}
.portfolio__slide-2 .slide-img img {
  width: 100%;
}
.portfolio__slide-2 .slide-content {
  padding-left: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slide-2 .slide-content {
    padding-left: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slide-2 .slide-content {
    padding-left: 0;
    padding-right: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__slide-2 .slide-content {
    padding: 30px 15px 50px;
  }
}
.portfolio__slide-2 p {
  max-width: 330px;
  font-size: 18px;
  line-height: 1.4;
  padding-bottom: 50px;
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .portfolio__slide-2 p {
    padding-bottom: 30px;
  }
}
.portfolio__slide-2 .btn-common {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black-2);
}
.portfolio__slide-2 .btn-common:hover {
  color: var(--black-3);
}
.portfolio__slide-2 .btn-common i {
  padding-left: 5px;
}
.portfolio__slide-2 .btn-common-wrap {
  opacity: 0;
}
.portfolio__slide-3 {
  text-align: right;
  padding-right: 110px;
}
.portfolio__slide-3 a {
  display: block;
  color: var(--white);
}
.portfolio__slide-3 a:hover span {
  color: var(--white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slide-3 {
    padding-right: 0;
  }
  .portfolio__slide-3 img {
    width: 60%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slide-3 {
    padding-right: 0;
  }
  .portfolio__slide-3 img {
    width: 60%;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__slide-3 {
    padding-right: 0;
  }
  .portfolio__slide-3 img {
    width: 100%;
  }
}
.portfolio__wrapper-6 {
  padding-left: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__wrapper-6 {
    padding-left: 0;
    padding-top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__wrapper-6 {
    padding-left: 0;
    padding-top: 100px;
  }
}
.portfolio__item {
  padding-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  scale: 0.5;
  opacity: 0;
}
.portfolio__item:nth-child(even) {
  top: 50%;
}
@media only screen and (max-width: 767px) {
  .portfolio__item:nth-child(even) {
    top: 0%;
    bottom: 0%;
  }
}
.portfolio__item:hover .portfolio__info {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.portfolio__item .img-box {
  height: 100%;
}
.portfolio__item img {
  width: 100%;
}
.portfolio__item-6 {
  position: relative;
  padding-bottom: 80px;
  overflow: hidden;
}
.portfolio__item-6 img {
  max-width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__item-6 {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__item-6 {
    padding-bottom: 50px;
  }
}
.portfolio__item-6:hover .portfolio__content-6 {
  right: 0%;
  opacity: 1;
  visibility: visible;
}
.portfolio__info {
  width: 175px;
  padding: 15px;
  background-color: var(--black-2);
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: 1;
  transform: translateX(60px);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__info {
    opacity: 1;
    visibility: visible;
    right: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__info {
    opacity: 1;
    visibility: visible;
    right: 45px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__info {
    opacity: 1;
    visibility: visible;
    right: 60px;
  }
}
.portfolio__info p {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.3;
  color: var(--white);
}
.portfolio__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: var(--white);
  padding-bottom: 3px;
}
.portfolio__title-3 {
  font-weight: 500;
  font-size: 180px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--white);
  text-align: left;
  padding-top: 80px;
  position: absolute;
  z-index: 5;
  opacity: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__title-3 {
    font-size: 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__title-3 {
    font-size: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__title-3 {
    font-size: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__title-3 {
    font-size: 60px;
    padding-top: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
}
.portfolio__title-3 span {
  display: block;
  padding-left: 200px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__title-3 span {
    padding-left: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__title-3 span {
    padding-left: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__title-3 span {
    padding-left: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__title-3 span {
    padding-left: 60px;
  }
}
.portfolio__title-6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: var(--white);
  padding-bottom: 5px;
  max-width: 150px;
}
.portfolio__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--white);
  max-width: 150px;
}
.portfolio__list-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 30px;
  position: relative;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .portfolio__list-1 {
    grid-template-columns: 1fr;
    gap: 0px;
  }
}
.portfolio__list-6 {
  overflow-x: hidden;
  overflow: hidden;
}
.portfolio__pagination-6 {
  padding-top: 430px;
  padding-left: 110px;
  font-weight: 300;
  font-size: 19px;
  line-height: 1.1;
  color: var(--black-3);
  display: flex;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__pagination-6 {
    padding-top: 330px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__pagination-6 {
    padding-top: 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__pagination-6 {
    padding-top: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__pagination-6 {
    padding-top: 180px;
    padding-left: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__pagination-6 {
    padding-top: 80px;
    padding-left: 0;
  }
}
.portfolio__current {
  font-weight: 300;
  font-size: 60px;
  line-height: 1.1;
  color: var(--black);
}
.portfolio__btn {
  margin-top: 40px;
}
.portfolio__btn#btn_wrapper, .portfolio__btn.btn_wrapper {
  margin: 0 auto;
}
.portfolio__content-6 {
  position: absolute;
  top: 0%;
  right: 5%;
  background-color: var(--black-2);
  z-index: 9;
  padding: 20px 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  margin-top: 40%;
}
@media (max-width: 1200px) {
  .portfolio__content-6 {
    opacity: 1;
    visibility: visible;
    right: 0;
  }
}
.portfolio__detail {
  overflow: hidden;
}
.portfolio__detail img {
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-top .pt-110 {
    padding-top: 140px;
  }
}
.portfolio__detail-top .sec-title {
  font-size: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-top .sec-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-top .sec-title {
    font-size: 62px;
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__detail-top .sec-title {
    font-size: 50px;
    padding-bottom: 50px;
    padding-top: 90px;
  }
}
.portfolio__detail-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.portfolio__detail-info ul {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: 25px 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-info ul {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 50px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__detail-info ul {
    grid-gap: 20px 50px;
  }
}
.portfolio__detail-info ul li {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--gray-8);
  text-transform: capitalize;
}
.portfolio__detail-info ul li span,
.portfolio__detail-info ul li a {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black-3);
  text-transform: capitalize;
}
.portfolio__detail-thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 70vh;
}
.portfolio__detail-thumb img {
  position: absolute;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 130%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1920px) {
  .portfolio__detail-thumb {
    padding-left: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
  }
  .portfolio__detail-thumb img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .portfolio__detail-thumb img {
    position: unset;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
  }
  .portfolio__detail-thumb img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .portfolio__detail-thumb img {
    position: unset;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__detail-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
  }
  .portfolio__detail-thumb img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .portfolio__detail-thumb img {
    position: unset;
  }
}
.portfolio__detail-title {
  max-width: 350px;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.1;
  color: var(--black-2);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__detail-title {
    max-width: 500px;
    padding-bottom: 30px;
  }
}
.portfolio__detail-text {
  padding-bottom: 60px;
}
.portfolio__detail-text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-3);
  padding-bottom: 15px;
}
.portfolio__detail-text li {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 5px;
}
.portfolio__detail-text .fonts {
  display: grid;
  grid-template-columns: 2fr 3.5fr;
  gap: 70px;
  padding-top: 60px;
  padding-bottom: 85px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-text .fonts {
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-text .fonts {
    grid-template-columns: 1fr;
    gap: 30px;
    padding-top: 40px;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__detail-text .fonts {
    grid-template-columns: 1fr;
    gap: 30px;
    padding-top: 40px;
    padding-bottom: 0;
  }
}
.portfolio__detail-text .fonts img {
  width: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-text .fonts img {
    width: 100%;
  }
}
.portfolio__detail-text .fonts ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.portfolio__detail-text .fonts ul li {
  font-weight: 500;
  text-transform: capitalize;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.portfolio__detail-text .fonts ul .regular {
  font-weight: 400;
  color: var(--gray-9);
}
.portfolio__detail-text .fonts ul .medium {
  color: var(--gray-10);
}
.portfolio__detail-text .fonts ul .semibold {
  color: var(--black-3);
}
.portfolio__detail .block-thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 65vh;
  z-index: 9;
  margin-bottom: 20px;
}
.portfolio__detail .block-thumb img {
  position: absolute;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 120%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail .block-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }
  .portfolio__detail .block-thumb img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .portfolio__detail .block-thumb img {
    position: unset;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__detail .block-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }
  .portfolio__detail .block-thumb img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .portfolio__detail .block-thumb img {
    position: unset;
  }
}
.portfolio__detail .block-gallery {
  overflow: hidden;
}
.portfolio__detail .block-gallery img {
  width: 50%;
  float: left;
  padding-bottom: 20px;
}
.portfolio__detail .block-gallery img:nth-child(odd) {
  padding-right: 10px;
}
.portfolio__detail .block-gallery img:nth-child(even) {
  padding-left: 10px;
}
.portfolio__detail .block-img-text {
  display: grid;
  grid-template-columns: 1fr 1fr 1.3fr;
  grid-gap: 20px;
}
@media only screen and (max-width: 767px) {
  .portfolio__detail .block-img-text {
    grid-template-columns: repeat(2, 1fr);
  }
}
.portfolio__detail .block-img-text p {
  font-size: 18px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  padding-left: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail .block-img-text p {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail .block-img-text p {
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio__detail .block-img-text p {
    grid-column: 1/-1;
    padding-left: 0;
  }
}
.portfolio__detail .block-content {
  position: relative;
  z-index: 1;
}
.portfolio__detail-btns {
  display: flex;
  justify-content: center;
}
.portfolio__detail-btns a {
  margin: 0 -10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v2.pt-150 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v2.pt-150 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio-v2.pt-150 {
    padding-top: 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v3 .pt-100 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v3 .pt-100 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio-v3 .pt-100 {
    padding-top: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v4 .pt-100 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v4 .pt-100 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio-v4 .pt-100 {
    padding-top: 130px;
  }
}
.portfolio-v4 .sec-title-2 {
  color: var(--white);
}
.portfolio-v4 .blog__text p {
  color: var(--gray-2);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v5 .pt-100 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v5 .pt-100 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio-v5 .pt-100 {
    padding-top: 130px;
  }
}
.portfolio-v5 .pb-100 {
  padding-bottom: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio-v5 .pb-100 {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v5 .pb-100 {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v5 .pb-100 {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio-v5 .pb-100 {
    padding-bottom: 0;
  }
}
.portfolio-v5.portfolio__project {
  padding: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v5.portfolio__project .line {
    padding-top: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v5.portfolio__project .line {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio-v5.portfolio__project .line {
    padding-top: 60px;
  }
}
.portfolio-v5.portfolio__project .pp-slider-wrapper,
.portfolio-v5.portfolio__project .pp-slide-thumb,
.portfolio-v5.portfolio__project .pp-next,
.portfolio-v5.portfolio__project .pp-prev {
  opacity: 1;
}

.swiper-slide-active .portfolio__title-3 {
  animation: wcfadeUp 1s 0.7s forwards;
}

.bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.bg_image::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  content: "";
}
.bg_image img {
  width: 120%;
}

.row_bottom {
  margin-top: 80vh;
}
@media only screen and (max-width: 767px) {
  .row_bottom {
    margin-top: 30px;
  }
}

/*----------------------------------------*/
/*  24. BLOG CSS START
/*----------------------------------------*/
.blog__area {
  overflow: hidden;
}
.blog__area .row {
  --bs-gutter-x: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__area .row {
    --bs-gutter-x: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area .row {
    --bs-gutter-x: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area .row {
    --bs-gutter-x: 20px;
  }
}
.blog__area .sec-title-wrapper {
  text-align: center;
  padding-bottom: 60px;
}
.blog__area-2 {
  overflow: hidden;
  background-color: var(--black-2);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-2 .pt-150 {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-2 .pb-110 {
    padding-bottom: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 .pb-110 {
    padding-bottom: 55px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__area-2 .pb-110 {
    padding-bottom: 0;
  }
}
.blog__area-2 .row {
  --bs-gutter-x: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__area-2 .row {
    --bs-gutter-x: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-2 .row {
    --bs-gutter-x: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 .row {
    --bs-gutter-x: 20px;
  }
}
.blog__area-2 #btn_wrapper,
.blog__area-2 .btn_wrapper {
  margin-left: auto;
  margin-right: -40px;
  margin-top: -60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-2 #btn_wrapper,
  .blog__area-2 .btn_wrapper {
    margin-right: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 #btn_wrapper,
  .blog__area-2 .btn_wrapper {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__area-2 #btn_wrapper,
  .blog__area-2 .btn_wrapper {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
.blog__area-2 .sec-title {
  color: var(--white);
  padding-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 .sec-title {
    padding-top: 45px;
  }
}
.blog__area-2 .sec-text {
  padding-top: 30px;
}
.blog__area-2 .sec-text p {
  max-width: 340px;
  font-size: 18px;
  line-height: 1.4;
  color: var(--gray-2);
}
.blog__area-2 .sec-btn {
  margin-bottom: 30px;
}
.blog__area-2 .sec-btn a {
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .blog__area-2 .sec-btn a {
    margin-left: 0;
  }
}
.blog__area-3 {
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-3.pb-140 {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__area-3.pb-140 {
    padding-bottom: 20px;
  }
}
.blog__area-3 .sec-title {
  padding-bottom: 40px;
  text-transform: uppercase;
  background-color: var(--white);
}
.blog__area-3 .sec-title-wrapper {
  position: relative;
  padding-left: 330px;
  margin-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-3 .sec-title-wrapper {
    padding-left: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__area-3 .sec-title-wrapper {
    padding-left: 0;
  }
}
.blog__area-3 .sec-title-wrapper::after {
  position: absolute;
  content: "";
  left: 39%;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--white-2);
}
.blog__area-3 .sec-title-wrapper p {
  padding-top: 70px;
  padding-bottom: 60px;
  max-width: 330px;
  font-size: 18px;
  line-height: 1.4;
  margin-left: 220px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-3 .sec-title-wrapper p {
    margin-left: 100px;
    padding-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-3 .sec-title-wrapper p {
    margin-left: 100px;
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .blog__area-3 .sec-title-wrapper p {
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.blog__area-3 .sec-sub-title {
  padding-top: 50px;
  text-transform: uppercase;
  background-color: var(--white);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-3 .sec-sub-title {
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .blog__area-3 .sec-sub-title {
    padding-top: 0;
  }
}
.blog__area-6 {
  overflow: hidden;
}
.blog__area-6 .pb-110 {
  padding-bottom: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-6 .pb-110 {
    padding-bottom: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__area-6 .pb-110 {
    padding-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-6 .sec-title-wrapper {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__area-6 .sec-title-wrapper {
    padding-top: 60px;
  }
}
.blog__area-6 .blog__item, .blog__area-6 .blog__item-2 {
  padding-bottom: 55px;
  position: relative;
  z-index: 1;
}
.blog__item, .blog__item-2 {
  position: relative;
  z-index: 5;
}
@media only screen and (max-width: 767px) {
  .blog__item, .blog__item-2 {
    padding-bottom: 50px;
  }
}
.blog__item:hover .blog__img, .blog__item-2:hover .blog__img {
  transform: scale(1.3);
}
.blog__item-2 {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .blog__item-2 {
    padding-bottom: 30px;
  }
}
.blog__item-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__item-3 {
    grid-template-columns: 2.5fr 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__item-3 {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__item-3 {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
}
.blog__info-3 {
  position: absolute;
  width: 63%;
  bottom: -1px;
  right: 0;
  margin-left: -50px;
  background: var(--white);
  z-index: 1;
  padding: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__info-3 {
    width: 70%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__info-3 {
    width: 80%;
  }
}
@media only screen and (max-width: 767px) {
  .blog__info-3 {
    width: 90%;
    margin-left: 0;
    padding: 30px 20px;
    border-top-left-radius: 5px;
  }
}
.blog__img {
  width: 100%;
  transform: scale(1.2);
  transition: all 0.3s;
}
.blog__img-3 {
  width: 100%;
}
.blog__img-wrapper {
  overflow: hidden;
  margin-bottom: 25px;
  position: relative;
  z-index: 9;
}
.blog__meta, .blog__meta-2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.3;
  color: var(--black-3);
  margin-bottom: 10px;
}
.blog__meta a, .blog__meta-2 a {
  color: var(--black-3);
  transition: all 0.3s;
}
.blog__meta a:hover, .blog__meta-2 a:hover {
  color: var(--secondary);
}
.blog__meta-2 {
  color: var(--gray-2);
}
.blog__meta-2 a {
  color: var(--gray-2);
}
.blog__meta-2 a:hover {
  color: var(--white);
}
.blog__title, .blog__title-3, .blog__title-2 {
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
  color: var(--black-2);
  border-bottom: 1px solid var(--gray-5);
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.blog__title:hover, .blog__title-3:hover, .blog__title-2:hover {
  color: var(--black-3);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__title, .blog__title-3, .blog__title-2 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__title, .blog__title-3, .blog__title-2 {
    font-size: 20px;
  }
}
.blog__title-2 {
  color: var(--white);
  border-bottom: 0;
}
.blog__title-2:hover {
  color: var(--gray-2);
}
.blog__title-3 {
  padding-bottom: 50px;
}
.blog__btn {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: var(--black-3);
  text-transform: capitalize;
}
.blog__btn span {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: -28px;
}
.blog__btn span i {
  transform: translate(0, 0) rotate(-45deg);
  transition: all 0.5s;
}
.blog__btn span::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.blog__btn:hover {
  color: var(--secondary);
}
.blog__btn:hover i {
  transform: translate(20px, -20px) rotate(-45deg);
}
.blog__btn:hover span::after {
  opacity: 1;
  visibility: visible;
  transform: translate(20px, -20px) rotate(-45deg);
}
.blog__text {
  display: flex;
  align-items: center;
  height: 100%;
}
.blog__text p {
  font-size: 18px;
  line-height: 1.4;
}
@media only screen and (max-width: 767px) {
  .blog__text p {
    padding-top: 30px;
  }
}
.blog__detail {
  overflow: hidden;
}
.blog__detail-top {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-top {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__detail-top {
    padding-top: 60px;
  }
}
.blog__detail-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 15px;
}
.blog__detail-date span {
  color: var(--black-3);
  position: relative;
  padding-left: 25px;
}
.blog__detail-date span::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  top: 11px;
  left: 8px;
  background-color: var(--gray-5);
  border-radius: 50px;
}
.blog__detail-title {
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 35px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .blog__detail-title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__detail-title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__detail-title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-title {
    font-size: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__detail-title {
    font-size: 36px;
  }
}
.blog__detail-metalist {
  display: flex;
  gap: 50px;
  padding-bottom: 50px;
}
.blog__detail-meta {
  display: flex;
  align-items: center;
  gap: 15px;
}
.blog__detail-meta p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--gray-8);
}
.blog__detail-meta p span {
  display: block;
  color: var(--black-3);
  font-size: 16px;
  font-weight: 500;
}
.blog__detail-thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 70vh;
}
.blog__detail-thumb img {
  position: absolute;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  .blog__detail-thumb {
    padding-bottom: 40px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 30vh;
  }
  .blog__detail-thumb img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.blog__detail-thumb img {
  width: 100%;
}
.blog__detail-content {
  padding-top: 55px;
  padding-bottom: 30px;
}
.blog__detail-content p {
  font-size: 18px;
  line-height: 1.4;
  padding-bottom: 25px;
}
.blog__detail-content p a {
  text-decoration: underline;
}
.blog__detail-content img {
  margin-bottom: 30px;
  max-width: 100%;
}
.blog__detail-content h1,
.blog__detail-content h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 15px;
  padding-top: 10px;
}
.blog__detail-content h3 {
  font-weight: 500;
  font-size: 30px;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 15px;
  padding-top: 10px;
}
.blog__detail-content h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 15px;
  padding-top: 10px;
}
.blog__detail-content h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 10px;
  padding-top: 10px;
}
.blog__detail-content h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 10px;
  padding-top: 10px;
}
.blog__detail-content ul,
.blog__detail-content ol {
  padding-bottom: 25px;
}
.blog__detail-content ul li,
.blog__detail-content ol li {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 5px;
  padding-left: 25px;
  position: relative;
}
.blog__detail-content ul li::before,
.blog__detail-content ol li::before {
  position: absolute;
  content: "+";
  left: 0;
  font-size: 20px;
}
.blog__detail-tags p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-2);
  text-transform: capitalize;
}
.blog__detail-tags p a {
  color: var(--black-3);
  text-transform: lowercase;
  text-decoration-line: underline;
}
.blog__related {
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .blog__related .pb-140 {
    padding-bottom: 0;
  }
}
.blog__related .sec-title-wrapper {
  padding-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__related .pb-130 {
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__related .pb-130 {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .blog__related .pb-130 {
    padding-bottom: 0;
  }
}
.blog-v2 {
  padding-bottom: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v2 {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-v2 {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v2 .pt-150 {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-v2 .pt-150 {
    padding-top: 130px;
  }
}
.blog-v2 .sec-text p {
  padding-bottom: 70px;
  margin-left: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v2 .sec-text p {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-v2 .sec-text p {
    padding-bottom: 40px;
  }
}
.blog-v2 .blog__item-2 {
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v2 .blog__item-2 {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-v2 .blog__item-2 {
    margin-bottom: 0;
  }
}
.blog-v3.blog__area-3 .sec-title-wrapper::after {
  position: unset;
}
.blog-v3 .line {
  padding-bottom: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-v3 .line {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v3 .line {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-v3 .line {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
.blog-v3 .blog__item-3 {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v3 .blog__item-3 {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-v3 .blog__item-3 {
    margin-bottom: 50px;
  }
}

.img-box {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .img-box {
    height: 425px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-box {
    height: 340px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-box {
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
  .img-box {
    height: auto;
  }
}
.img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  transition: 0.5s;
  background-size: cover;
}
@media (max-width: 1023px) {
  .img-box img {
    position: unset;
    width: 100%;
    height: auto;
  }
}
.img-box img:nth-child(1) {
  transform: translatex(50%) scalex(2);
  opacity: 0;
  filter: blur(10px);
}
@media (max-width: 1023px) {
  .img-box img:nth-child(1) {
    display: none;
  }
}
.img-box:hover img:nth-child(2) {
  transform: translatex(-50%) scalex(2);
  opacity: 0;
  filter: blur(10px);
}
@media (max-width: 1023px) {
  .img-box:hover img:nth-child(2) {
    opacity: 1;
  }
}
@media (max-width: 991px) {
  .img-box:hover img:nth-child(2) {
    transform: translatex(0%) scalex(1.1);
    opacity: 1;
    filter: blur(0px);
  }
}
.img-box:hover img:nth-child(1) {
  transform: translatex(0) scalex(1);
  opacity: 1;
  filter: blur(0);
}

.row.reset-grid {
  --bs-gutter-x: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .row.reset-grid {
    --bs-gutter-x: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row.reset-grid {
    --bs-gutter-x: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row.reset-grid {
    --bs-gutter-x: 20px;
  }
}

/*----------------------------------------*/
/*  25. CTA CSS START
/*----------------------------------------*/
.cta__area {
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__area-2 .pb-120 {
    padding-bottom: 126px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__area-2 .pb-120 {
    padding-bottom: 115px;
  }
}
@media only screen and (max-width: 767px) {
  .cta__area-2 .pb-120 {
    padding-bottom: 70px;
  }
}
.cta__area-2 .wc-btn-pink {
  color: var(--black-3);
}
.cta__area-2 .wc-btn-pink:hover {
  color: var(--black-2);
}
.cta__area-3 {
  background-color: var(--pink-5);
}
@media only screen and (min-width: 1920px) {
  .cta__area-3 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .cta__area-3 {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__area-3 .pt-150 {
    padding-top: 125px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__area-3 {
    background-color: var(--white);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__area-3 {
    background-color: var(--white);
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .cta__area-3 {
    background-color: var(--white);
    padding-bottom: 10px;
  }
}
.cta__content {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__content {
    width: 80%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .cta__content {
    width: 100%;
  }
}
.cta__content #btn_wrapper,
.cta__content .btn_wrapper {
  margin: 0 auto;
}
.cta__content-2, .cta__content-3 {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content-2, .cta__content-3 {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .cta__content-2, .cta__content-3 {
    width: 100%;
  }
}
.cta__content-2 #btn_wrapper, .cta__content-3 #btn_wrapper,
.cta__content-2 .btn_wrapper,
.cta__content-3 .btn_wrapper {
  position: relative;
  z-index: 1;
  width: 350px;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__content-2 #btn_wrapper, .cta__content-3 #btn_wrapper,
  .cta__content-2 .btn_wrapper,
  .cta__content-3 .btn_wrapper {
    width: 260px;
    height: 260px;
  }
}
@media (max-width: 1200px) {
  .cta__content-2 #btn_wrapper, .cta__content-3 #btn_wrapper,
  .cta__content-2 .btn_wrapper,
  .cta__content-3 .btn_wrapper {
    width: 200px;
    height: 200px;
  }
}
.cta__content-2 #btn_wrapper .btn-item, .cta__content-3 #btn_wrapper .btn-item,
.cta__content-2 .btn_wrapper .btn-item,
.cta__content-3 .btn_wrapper .btn-item {
  top: 25px;
}
@media only screen and (max-width: 767px) {
  .cta__content-2 #btn_wrapper .btn-item, .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-2 .btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 0;
  }
}
.cta__content-2 a, .cta__content-3 a {
  width: 300px;
  height: 300px;
  font-size: 24px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__content-2 a, .cta__content-3 a {
    width: 220px;
    height: 220px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__content-2 a, .cta__content-3 a {
    width: 200px;
    height: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content-2 a, .cta__content-3 a {
    width: 200px;
    height: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .cta__content-2 a, .cta__content-3 a {
    width: 200px;
    height: 200px;
    font-size: 20px;
  }
}
.cta__content-2 a:hover span, .cta__content-3 a:hover span {
  width: 800px;
  height: 800px;
}
.cta__content-2 a i, .cta__content-3 a i {
  font-size: 30px;
  transform: rotate(0);
  padding-left: 20px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .cta__content-3 a {
    width: 220px;
    height: 220px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 0;
  }
}
.cta__content-3 a {
  border-color: var(--black-2);
}
.cta__sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  color: var(--black-3);
  border: 1px solid var(--gray);
  display: inline-block;
  padding: 5px 17px;
  border-radius: 30px;
  margin-bottom: 20px;
}
.cta__sub-title-2 {
  font-weight: 300;
  font-size: 28px;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 25px;
}
.cta__title {
  font-weight: 400;
  font-size: 50px;
  line-height: 1.1;
  color: var(--black-2);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__title {
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__title {
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__title {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .cta__title {
    font-size: 36px;
    padding-bottom: 40px;
  }
}
.cta__title-2 {
  font-weight: 300;
  font-size: 100px;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__title-2 {
    font-size: 75px;
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__title-2 {
    font-size: 70px;
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__title-2 {
    font-size: 60px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .cta__title-2 {
    font-size: 48px;
  }
}

/*----------------------------------------*/
/*  29. DEVELOPMENT CSS START
/*----------------------------------------*/
.development__area {
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .development__area {
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .development__area .pt-130 {
    padding-top: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .development__area .pt-130 {
    padding-top: 120px;
  }
}
@media only screen and (max-width: 767px) {
  .development__area .pb-150 {
    padding-bottom: 40px;
  }
}
.development__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  padding-bottom: 35px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--black-3);
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .development__content {
    grid-gap: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .development__content {
    grid-gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .development__content {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .development__content {
    padding-top: 30px;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
.development__content p {
  font-size: 18px;
  line-height: 1.4;
}
.development__wrapper {
  padding-bottom: 55px;
}
@media only screen and (max-width: 767px) {
  .development__wrapper {
    padding-bottom: 50px;
  }
}
.development__wrapper ul {
  display: grid;
  grid-template-columns: 240px auto;
  grid-template-columns: 310px auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .development__wrapper ul {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 767px) {
  .development__wrapper ul {
    grid-template-columns: 1fr;
    padding-bottom: 20px;
  }
}
.development__wrapper ul li a {
  display: inline-block;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black-2);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .development__wrapper ul li {
    font-size: 16px;
    padding-bottom: 5px;
  }
}
.development__wrapper p strong {
  font-family: "Poppins-medium";
  font-weight: 600;
}
.development__img {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 400px;
  z-index: 9;
}
.development__img img {
  position: absolute;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 150%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .development__img {
    padding-bottom: 15px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }
  .development__img img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .development__img img {
    position: unset;
  }
}
@media only screen and (max-width: 767px) {
  .development__img {
    padding-bottom: 15px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }
  .development__img img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .development__img img {
    position: unset;
  }
}
.development__img img {
  width: 100%;
}

/*----------------------------------------*/
/*  31. FAQ CSS START
/*----------------------------------------*/
.faq__area {
  overflow: hidden;
}
.faq__area-6 {
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__area-6 .line {
    padding-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__area-6 .line {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 767px) {
  .faq__area-6 .line {
    padding-top: 120px;
  }
}
.faq__area-6 .sec-title-wrapper {
  padding-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .faq__area-6 .sec-title-wrapper {
    padding-bottom: 40px;
  }
}
.faq__area-6 .sec-title-wrapper p {
  padding-left: 60px;
  font-size: 18px;
  padding-top: 20px;
  line-height: 1.4;
}
@media only screen and (max-width: 767px) {
  .faq__area-6 .sec-title-wrapper p {
    padding-left: 30px;
  }
}
.faq__img {
  padding-right: 85px;
  overflow: hidden;
  position: relative;
  width: calc(100% - 85px);
  height: 630px;
  z-index: 1;
}
.faq__img img {
  position: absolute;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 120%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq__img {
    padding-right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__img {
    padding-right: 0;
    overflow: hidden;
    position: relative;
    width: calc(100% - 30px);
    height: auto;
  }
  .faq__img img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .faq__img img {
    position: unset;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__img {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    padding-right: 0;
  }
  .faq__img img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .faq__img img {
    position: unset;
  }
}
@media only screen and (max-width: 767px) {
  .faq__img {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    padding-right: 0;
  }
  .faq__img img {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .faq__img img {
    position: unset;
  }
}
.faq__img img {
  width: 100%;
}
.faq__content {
  padding-left: 85px;
  padding-top: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq__content {
    padding-left: 30px;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__content {
    padding-left: 0;
    padding-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__content {
    padding-left: 0;
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .faq__content {
    padding-left: 0;
  }
}
.faq__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.1;
  color: var(--black-2);
  max-width: 315px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .faq__title {
    font-size: 30px;
  }
}
.faq__list, .faq__list-6, .faq__list-3 {
  padding-right: 1px;
}
.faq__list .accordion-body, .faq__list-6 .accordion-body, .faq__list-3 .accordion-body {
  padding-left: 0;
  min-height: 160px;
}
.faq__list .accordion-item, .faq__list-6 .accordion-item, .faq__list-3 .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.faq__list .accordion-button, .faq__list-6 .accordion-button, .faq__list-3 .accordion-button {
  background-color: transparent;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black-2);
  padding-left: 0;
  padding-right: 10px;
  border-radius: 0;
}
.faq__list .accordion-button.collapsed::after, .faq__list-6 .accordion-button.collapsed::after, .faq__list-3 .accordion-button.collapsed::after {
  background-image: none;
  content: "+";
  font-weight: 300;
  font-size: 30px;
  top: 6px;
  position: absolute;
  right: 0;
  transform: rotate(0);
}
.faq__list .accordion-button:not(.collapsed), .faq__list-6 .accordion-button:not(.collapsed), .faq__list-3 .accordion-button:not(.collapsed) {
  box-shadow: none;
  background: transparent;
}
.faq__list .accordion-button:not(.collapsed)::after, .faq__list-6 .accordion-button:not(.collapsed)::after, .faq__list-3 .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "-";
  font-weight: 300;
  font-size: 30px;
  top: 6px;
  position: absolute;
  right: 0;
  transform: rotate(0);
}
.faq__list .accordion-button:focus, .faq__list-6 .accordion-button:focus, .faq__list-3 .accordion-button:focus {
  box-shadow: none;
}
.faq__list-3 {
  padding-right: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__list-3 {
    padding-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__list-3 {
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .faq__list-3 {
    padding-right: 0;
  }
}
.faq__list-3 .accordion-item {
  background-color: transparent;
  border-bottom: 1px solid var(--black-2);
}
.faq__list-3 .accordion-item:first-child {
  border-top: 1px solid var(--black-2);
}
.faq__list-6 {
  padding-left: 1px;
}
.faq__list-6 .accordion-button {
  font-size: 20px;
}
.faq__list-6 .accordion-button.collapsed::after, .faq__list-6 .accordion-button:not(.collapsed)::after {
  font-size: 50px;
  top: -10px;
}
.faq__list-6 .accordion-body p {
  font-size: 18px;
  line-height: 1.4;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__list-6 .accordion-header {
    padding-right: 10px;
  }
}
.faq__btm {
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__btm .pb-130 {
    padding-bottom: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__btm .pb-130 {
    padding-bottom: 120px;
  }
}
.faq__btm p {
  padding-top: 15px;
  font-size: 18px;
  line-height: 1.4;
}
.faq__btm a {
  margin: 0 auto;
  margin-top: 60px;
}
@media only screen and (max-width: 767px) {
  .faq__btm a {
    margin-top: 0;
  }
}
.faq__btm #btn_wrapper,
.faq__btm .btn_wrapper {
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .faq__btm #btn_wrapper,
  .faq__btm .btn_wrapper {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .video7_layer .video-container video {
    height: 300px;
  }
}

.video_text {
  display: flex;
  justify-content: flex-start;
  max-width: 540px;
  align-items: center;
  position: absolute;
  z-index: 3;
  right: 100px;
  z-index: -2;
  bottom: 60px;
}
.video_text::before {
  content: "";
  position: absolute;
  bottom: -23%;
  left: 0;
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
}
.video_text__left {
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .video_text {
    top: 0;
    right: 0;
  }
}
.video_text .video_title_7 {
  font-size: 20px;
  color: var(--white);
  font-family: "newYork";
}
@media only screen and (max-width: 767px) {
  .video_text .video_title_7 {
    font-size: 40px;
  }
}
.video_text .video_dis_7 {
  font-size: 18px;
  color: var(--white);
  text-indent: 30px;
}

.nominated_title_7 {
  font-family: "newYork";
  font-weight: 400;
  font-size: 100px;
  color: var(--white);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nominated_title_7 {
    font-size: 50px;
  }
}

.contactrow {
  padding-left: 450px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contactrow {
    padding-left: 0;
  }
}

.nominateparagraph {
  max-width: 440px;
  margin: auto;
}
.nominateparagraph p {
  font-size: 18px;
  text-indent: 60px;
}

.overlay_text {
  display: flex;
  position: absolute;
  top: 75px;
  left: 80px;
  z-index: 9;
  color: var(--white);
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid var(--white);
  padding-bottom: 30px;
}
.overlay_text h4 {
  margin-right: 30px;
}

.tech_title {
  font-weight: 400;
  font-size: 80px;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 45px;
  text-transform: uppercase;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tech_title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tech_title {
    font-size: 48px;
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tech_title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .tech_title {
    font-size: 30px;
    line-height: 1.3;
    padding-bottom: 30px;
  }
}
.tech_title a {
  color: var(--black-2);
  font-family: "newYork";
  position: relative;
}
.tech_title a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 10px;
  background-color: var(--black-2);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tech_title a::after {
    bottom: 7px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tech_title a::after {
    bottom: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .tech_title a::after {
    bottom: 3px;
  }
}
.tech_title span {
  font-family: "newYork";
}

.single_dis {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
  color: var(--black-3);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_dis {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_dis {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .single_dis {
    font-size: 18px;
  }
}

.single__thumb {
  position: relative;
}
.single__thumb img {
  max-width: 100%;
}
.single__thumb video {
  width: 100%;
}
.single__thumb .video-frame {
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 100%;
  filter: brightness(25);
}
.single__thumb .shape-2 {
  position: absolute;
  bottom: -30px;
  left: 60%;
}
@media only screen and (max-width: 767px) {
  .single__thumb .shape-2 {
    bottom: -20px;
    max-width: 40px;
  }
}
.single__thumb .svgstar {
  position: absolute;
  left: 25%;
  top: 18%;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .single__thumb .svgstar {
    left: 24%;
    top: 15%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .single__thumb .svgstar {
    left: 23%;
    top: 15%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single__thumb .svgstar {
    max-width: 90px;
    left: 23%;
    top: 15%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single__thumb .svgstar {
    max-width: 70px;
    left: 24%;
    top: 16%;
  }
}
@media only screen and (max-width: 767px) {
  .single__thumb .svgstar {
    max-width: 40px;
    left: 23%;
    top: 15%;
  }
}

.dark .tech_title,
.dark .tech_title a {
  color: var(--white);
}
.dark .tech_title a::after {
  background-color: var(--white);
}
.dark .single__thumb .shape-2,
.dark .single__thumb .svgstar {
  filter: invert(1);
}
.dark .single__thumb .video-frame {
  filter: unset;
}

.client__area-8 {
  background-color: var(--black-2);
  background-image: url("/imgs/essential-img/bg-axtra.png");
  padding-top: 150px;
  padding-bottom: 140px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .client__area-8 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client__area-8 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .client__area-8 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.client__area-8 .section_wrapper::before {
  background-color: #313131;
}
.client__area-8 .about-sub-right {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 10px;
}
.client__area-8 .react_border {
  border-color: #313131;
}
.client__area-8 .react_border span {
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 16px;
  color: var(--white);
  display: inline-block;
  transform: rotate(-45deg);
}
.client__section {
  display: flex;
  gap: 30px;
  margin-bottom: 110px;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .client__section {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
  }
}
.client__section__title {
  color: var(--white);
  font-size: 80px;
  line-height: 1;
  text-transform: uppercase;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .client__section__title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .client__section__title {
    font-size: 48px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client__section__title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .client__section__title {
    font-size: 40px;
  }
}
.client__section__title span {
  font-family: "newYork";
}
.client__section__dis {
  max-width: 240px;
  color: var(--gray-2);
  font-size: 18px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .client__section__dis {
    max-width: 100%;
  }
}
.client__logo {
  display: grid;
  grid-gap: 120px 60px;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .client__logo {
    grid-gap: 80px 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client__logo {
    grid-gap: 60px 40px;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .client__logo {
    grid-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
  }
}
.client__logo img {
  max-width: 100%;
}

/*----------------------------------------*/
/*  33. FEATURE CSS START
/*----------------------------------------*/
.feature__area-2 {
  background-color: var(--pink);
}
.feature__top {
  padding-bottom: 65px;
  border-bottom: 1px solid var(--black);
}
@media only screen and (max-width: 767px) {
  .feature__top {
    border-bottom: none;
  }
}
@media only screen and (max-width: 767px) {
  .feature__btm {
    padding-bottom: 15px;
  }
}
.feature__text {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 85px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__text {
    padding-left: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__text {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__text {
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .feature__text {
    padding-left: 0;
    padding-top: 30px;
  }
}
.feature__text p {
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-2);
}
.feature__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__list {
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
}
@media only screen and (max-width: 767px) {
  .feature__list {
    grid-template-columns: 1fr;
  }
}
.feature__item {
  border-right: 1px solid var(--black-2);
  padding: 145px 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__item {
    padding: 90px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__item {
    padding: 90px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__item {
    padding: 50px 30px;
    border-right: none;
  }
}
@media only screen and (max-width: 767px) {
  .feature__item {
    border-right: none;
    padding: 0 0 50px 0;
  }
}
.feature__item:first-child {
  padding-left: 0;
}
.feature__item:last-child {
  border-right: 0;
  padding-right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__item:nth-child(odd) {
    border-right: 1px solid var(--black-2);
  }
}
.feature__item img {
  margin-bottom: 35px;
}
.feature__item p {
  color: var(--black-2);
}
.feature__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.4;
  color: var(--black-2);
  padding-bottom: 12px;
}

/*----------------------------------------*/
/*  34. CONTACT CSS START
/*----------------------------------------*/
.contact__area-6 {
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__area-6 .line {
    padding-top: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .contact__area-6 .line {
    padding-top: 130px;
  }
}
.contact__text {
  max-width: 330px;
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .contact__text {
    padding-top: 30px;
  }
}
.contact__text p {
  font-size: 18px;
  line-height: 1.4;
}
.contact__btm {
  padding-top: 65px;
}
@media only screen and (max-width: 767px) {
  .contact__btm {
    padding-top: 50px;
  }
}
.contact__info {
  padding-top: 15px;
}
@media only screen and (max-width: 767px) {
  .contact__info {
    padding-bottom: 50px;
  }
}
.contact__info h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
  color: var(--black-2);
  padding-bottom: 30px;
}
.contact__info ul li a {
  text-decoration-line: underline;
}
.contact__info ul li a,
.contact__info ul li span {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black-2);
  display: block;
  margin-bottom: 10px;
}
.contact__form {
  position: relative;
  z-index: 9;
}
.contact__form input,
.contact__form textarea {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black-2);
  border: none;
  border-bottom: 1px solid var(--gray);
  padding: 15px 0;
  transition: all 0.3s;
  outline: 0;
  position: relative;
  z-index: 1;
  background: transparent;
  margin-bottom: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__form input,
  .contact__form textarea {
    padding: 10px 0;
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__form input,
  .contact__form textarea {
    padding: 10px 0;
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .contact__form input,
  .contact__form textarea {
    margin-bottom: 20px;
  }
}
.contact__form input:focus,
.contact__form textarea:focus {
  border-bottom: 1px solid var(--black-2);
}
.contact__form input::placeholder,
.contact__form textarea::placeholder {
  color: var(--black-2);
  opacity: 1;
}
.contact__form textarea {
  height: 55px;
}
.contact__form #btn_wrapper,
.contact__form .btn_wrapper {
  margin-top: -20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__form #btn_wrapper,
  .contact__form .btn_wrapper {
    margin-top: -10px;
  }
}
@media (max-width: 1200px) {
  .contact__form #btn_wrapper,
  .contact__form .btn_wrapper {
    margin-top: 10px;
  }
}

/*----------------------------------------*/
/*  36. 404 CSS START
/*----------------------------------------*/
.error__content {
  padding-top: 80px;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.error__content h1 {
  font-size: 200px;
  font-weight: 900;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error__content {
    padding-top: 100px;
  }
}
.error__content::before {
  position: absolute;
  content: "";
  width: 38px;
  height: 38px;
  top: 70%;
  left: 10%;
  background-repeat: no-repeat;
  animation: wcZoom 2s infinite;
}
.error__content::after {
  position: absolute;
  content: "";
  width: 38px;
  height: 38px;
  top: 10%;
  right: 10%;
  background-repeat: no-repeat;
  animation: wcZoom 2s infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error__content::after {
    top: 20%;
  }
}
@media only screen and (max-width: 767px) {
  .error__content::after {
    top: 15%;
  }
}
.error__content h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.5;
  color: var(--black-2);
  padding-top: 25px;
  padding-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .error__content h2 {
    font-size: 30px;
    line-height: 1.3;
  }
}
.error__content p {
  max-width: 340px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.4;
  padding-bottom: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error__content p {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .error__content p {
    padding-bottom: 30px;
  }
}
.error__content a {
  margin: 0 auto;
}
.error__content img {
  max-width: 100%;
}
.error__content #btn_wrapper,
.error__content .btn_wrapper {
  margin-left: 0;
  margin-top: -40px;
}
@media (max-width: 1400px) {
  .error__content #btn_wrapper,
  .error__content .btn_wrapper {
    margin-top: 0;
  }
}

/*----------------------------------------*/
/*  37. HOME 5 CSS START
/*----------------------------------------*/
:root {
  --white-6: #d7d7d7;
  --white-7: #B7B7C5;
  --black-14: #323438;
  --lime: #745EFF;
  --lime-2: #5547F5;
  --gray-16: #6B6B6B;
  --gray-17: #F7F7FC;
  --gray-18: #999999;
  --gray-20: #D7D7D7;
  --gray-21: #F2F2F8;
  --gray-22: #FCFCFE;
  --gray-23: #ECECF3;
  --gray-24: #ECECF4;
  --gray-25: #D3D3D3;
}

.btn-together {
  font-weight: 400;
  line-height: 1.3;
  color: var(--white);
  position: relative;
  transition: all 0.5s;
  font-size: 18px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .btn-together {
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-together {
    font-size: 15px;
  }
}
.btn-together i {
  padding-left: 10px;
}
.btn-together::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--white);
  content: "";
  transition: all 0.5s;
}
.btn-together:hover::after {
  left: 0;
  width: 1%;
}
.btn-together:hover {
  color: var(--gray);
}

.header__inner-5 {
  padding: 25px 50px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
}
@media only screen and (max-width: 767px) {
  .header__inner-5 {
    padding: 20px 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__inner-5 {
    padding: 25px 15px 22px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__inner-5 {
    padding: 25px 15px 22px;
  }
}
.header__area-5 {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}
.header__area-5.sticky-5 {
  background-color: var(--white);
  border-bottom: 1px solid var(--white-2);
}
.header__area-5.sticky-5 .header__inner-5 {
  padding: 20px 50px;
}
@media (max-width: 1200px) {
  .header__area-5.sticky-5 .header__inner-5 {
    padding: 20px 15px;
  }
}

.menu-text-5 {
  color: var(--black-2);
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  padding-right: 10px;
}
.menu-text-pp {
  color: var(--black-2);
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  padding-right: 10px;
}

.hero__area-5 {
  padding: 190px 35px 113px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .hero__area-5 {
    padding: 135px 0px 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-5 {
    padding: 145px 0px 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-5 {
    padding: 155px 0px 70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-5 {
    padding: 166px 0px 80px;
    background-size: 90%;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-5 {
    padding: 185px 0px 80px;
    background-size: 90%;
  }
}
.hero__area-5 img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  filter: invert(1);
  z-index: 0;
}
.hero__content-5 p {
  font-size: 18px;
  color: var(--black-2);
  font-weight: 400;
  line-height: 1.4;
  max-width: 330px;
}
@media only screen and (max-width: 767px) {
  .hero__content-5 p {
    font-size: 16px;
  }
}
.hero__text-5 {
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
}
.hero__title-5 {
  font-size: 80px;
  font-weight: 500;
  line-height: 1;
  color: var(--black-2);
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .hero__title-5 {
    font-size: 30px;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title-5 {
    font-size: 36px;
    line-height: 1.2;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title-5 {
    font-size: 40px;
    line-height: 1.4;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title-5 {
    font-size: 45px;
    line-height: 1.4;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__title-5 {
    font-size: 50px;
    line-height: 1.4;
  }
}

.portfolio__area-5 {
  border-top: 1px solid var(--gray-25);
  border-bottom: 1px solid var(--gray-25);
  padding: 56px 45px;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .portfolio__area-5 {
    padding: 56px 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-5 {
    padding: 56px 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-5 {
    padding: 56px 0px;
  }
}
.portfolio__area-5::before {
  position: absolute;
  left: 33.3%;
  top: 0;
  width: 1px;
  height: 100%;
  content: "";
  background-color: var(--secondary);
  filter: invert(1);
}
.portfolio__area-5::after {
  position: absolute;
  left: 66.6%;
  top: 0;
  width: 1px;
  height: 100%;
  content: "";
  background-color: var(--secondary);
  filter: invert(1);
}
.portfolio__area-5 .sec-line {
  position: absolute;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-color: var(--secondary);
  filter: invert(1);
}
.portfolio__inner-5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 110px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .portfolio__inner-5 {
    gap: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__inner-5 {
    gap: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__inner-5 {
    gap: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__inner-5 {
    gap: 70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__inner-5 {
    gap: 80px;
  }
}
.portfolio__item-5 {
  width: 29%;
  position: relative;
  transition: all 0.5s;
}
@media only screen and (max-width: 767px) {
  .portfolio__item-5 {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__item-5 {
    width: 48.5%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__item-5 {
    width: 48%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__item-5 {
    width: 28%;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__item-5 {
    width: 28%;
  }
}
.portfolio__item-5:hover .portfolio__content-5 {
  opacity: 1;
  right: -50px;
}
.portfolio__item-5 img {
  width: 100%;
  max-width: 100%;
}
.portfolio__content-5 {
  background-color: var(--black-2);
  padding: 20px 30px 22px 30px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: -70px;
  z-index: 9;
  opacity: 0;
  transition: all 0.5s;
}
@media only screen and (max-width: 767px) {
  .portfolio__content-5 {
    right: 0;
    opacity: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__content-5 {
    right: 0;
    opacity: 1;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__content-5 {
    right: -70px;
  }
}
.portfolio__name-5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  padding-bottom: 2px;
  color: var(--white);
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .portfolio__name-5 {
    font-size: 12px;
  }
}
.portfolio__title-5 {
  font-size: 24px;
  font-weight: 500;
  color: var(--white);
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .portfolio__title-5 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__title-5 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__title-5 {
    font-size: 20px;
  }
}

.cta__area-5 {
  position: relative;
  border-bottom: 1px solid var(--secondary);
  filter: invert(1);
}
.cta__area-5::before {
  position: absolute;
  top: 0;
  left: 57%;
  width: 1px;
  height: 100%;
  content: "";
  background-color: var(--secondary);
}
.cta__inner-5 {
  max-width: 1410px;
  margin: 0 auto;
}
.cta__title-5 {
  font-size: 60px;
  color: var(--white);
  font-weight: 500;
  line-height: 1.2;
  padding-bottom: 28px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .cta__title-5 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__title-5 {
    font-size: 36px;
  }
  .cta__title-5 br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__title-5 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__title-5 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .cta__title-5 {
    font-size: 50px;
  }
}
.cta__list-wrapper {
  display: grid;
  grid-template-columns: 230px auto;
  gap: 30px;
}
@media only screen and (max-width: 767px) {
  .cta__list-wrapper {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__list-wrapper {
    grid-template-columns: 180px auto;
    gap: 20px;
  }
}
.cta__list-left {
  padding-left: 15px;
}
.cta__list-left li {
  position: relative;
}
.cta__list-left li::before {
  position: absolute;
  top: 0;
  left: -15px;
  width: auto;
  height: auto;
  content: "+";
}
.cta__item-5 h3 {
  font-size: 18px;
  font-weight: 400;
  color: var(--white);
  padding-bottom: 10px;
  text-transform: uppercase;
}
.cta__item-5 li {
  font-size: 18px;
  color: var(--gray-2);
  line-height: 1.5;
  font-weight: 400;
}
@media only screen and (max-width: 767px) {
  .cta__item-5 li {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__item-5 li {
    font-size: 16px;
  }
}
.cta__btn-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .cta__btn-5 {
    justify-content: flex-start;
  }
}

.footer__area-5 {
  padding: 79px 0px 72px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__area-5 {
    padding: 60px 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__area-5 {
    padding: 50px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__area-5 {
    padding: 50px 0px;
  }
}
.footer__menu-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .footer__menu-5 {
    gap: 25px;
  }
}
.footer__menu-5 li a {
  color: var(--gray-2);
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.5s;
}
.footer__menu-5 li a .menu-text {
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-2);
}
.footer__menu-5 li a .menu-text span {
  display: block;
  backface-visibility: hidden;
  font-style: normal;
  transition: transform 0.4s ease;
  transform: translateY(var(--m)) translateZ(0);
}
.footer__menu-5 li a .menu-text span:nth-child(1) {
  transition-delay: 0.05s;
}
.footer__menu-5 li a .menu-text span:nth-child(2) {
  transition-delay: 0.1s;
}
.footer__menu-5 li a .menu-text span:nth-child(3) {
  transition-delay: 0.15s;
}
.footer__menu-5 li a .menu-text span:nth-child(4) {
  transition-delay: 0.2s;
}
.footer__menu-5 li a .menu-text span:nth-child(5) {
  transition-delay: 0.25s;
}
.footer__menu-5 li a .menu-text span:nth-child(6) {
  transition-delay: 0.3s;
}
.footer__menu-5 li a .menu-text span:nth-child(7) {
  transition-delay: 0.35s;
}
.footer__menu-5 li a .menu-text span:nth-child(8) {
  transition-delay: 0.4s;
}
.footer__menu-5 li a .menu-text span:nth-child(9) {
  transition-delay: 0.45s;
}
.footer__menu-5 li a .menu-text span:nth-child(10) {
  transition-delay: 0.5s;
}
.footer__menu-5 li a .menu-text span:nth-child(11) {
  transition-delay: 0.55s;
}
.footer__menu-5 li a .menu-text span:nth-child(12) {
  transition-delay: 0.6s;
}
.footer__menu-5 li a .menu-text span:nth-child(13) {
  transition-delay: 0.65s;
}
.footer__menu-5 li a .menu-text span:nth-child(14) {
  transition-delay: 0.7s;
}
.footer__menu-5 li a .menu-text span:nth-child(15) {
  transition-delay: 0.75s;
}
.footer__menu-5 li a .menu-text span:nth-child(16) {
  transition-delay: 0.8s;
}
.footer__menu-5 li a .menu-text span:nth-child(17) {
  transition-delay: 0.85s;
}
.footer__menu-5 li a .menu-text span:nth-child(18) {
  transition-delay: 0.9s;
}
.footer__menu-5 li a .menu-text span:nth-child(19) {
  transition-delay: 0.95s;
}
.footer__menu-5 li a .menu-text span:nth-child(20) {
  transition-delay: 1s;
}
.footer__menu-5 li a .menu-text span:nth-child(21) {
  transition-delay: 1.05s;
}
.footer__menu-5 li a .menu-text span:nth-child(22) {
  transition-delay: 1.1s;
}
.footer__menu-5 li a .menu-text span:nth-child(23) {
  transition-delay: 1.15s;
}
.footer__menu-5 li a .menu-text span:nth-child(24) {
  transition-delay: 1.2s;
}
.footer__menu-5 li a .menu-text span:nth-child(25) {
  transition-delay: 1.25s;
}
.footer__menu-5 li a .menu-text span:nth-child(26) {
  transition-delay: 1.3s;
}
.footer__menu-5 li a .menu-text span:nth-child(27) {
  transition-delay: 1.35s;
}
.footer__menu-5 li a .menu-text span:nth-child(28) {
  transition-delay: 1.4s;
}
.footer__menu-5 li a .menu-text span:nth-child(29) {
  transition-delay: 1.45s;
}
.footer__menu-5 li a .menu-text span:nth-child(30) {
  transition-delay: 1.5s;
}
.footer__menu-5 li a .menu-text span:nth-child(31) {
  transition-delay: 1.55s;
}
.footer__menu-5 li a .menu-text span:nth-child(32) {
  transition-delay: 1.6s;
}
.footer__menu-5 li a .menu-text span:nth-child(33) {
  transition-delay: 1.65s;
}
.footer__menu-5 li a .menu-text span:nth-child(34) {
  transition-delay: 1.7s;
}
.footer__menu-5 li a .menu-text span:nth-child(35) {
  transition-delay: 1.75s;
}
.footer__menu-5 li a .menu-text span:nth-child(36) {
  transition-delay: 1.8s;
}
.footer__menu-5 li a .menu-text span:nth-child(37) {
  transition-delay: 1.85s;
}
.footer__menu-5 li a .menu-text span:nth-child(38) {
  transition-delay: 1.9s;
}
.footer__menu-5 li a .menu-text span:nth-child(39) {
  transition-delay: 1.95s;
}
.footer__menu-5 li a .menu-text span:nth-child(40) {
  transition-delay: 2s;
}
.footer__menu-5 li a .menu-text span:nth-child(41) {
  transition-delay: 2.05s;
}
.footer__menu-5 li a .menu-text span:nth-child(42) {
  transition-delay: 2.1s;
}
.footer__menu-5 li a .menu-text span:nth-child(43) {
  transition-delay: 2.15s;
}
.footer__menu-5 li a .menu-text span:nth-child(44) {
  transition-delay: 2.2s;
}
.footer__menu-5 li a .menu-text span:nth-child(45) {
  transition-delay: 2.25s;
}
.footer__menu-5 li a .menu-text span:nth-child(46) {
  transition-delay: 2.3s;
}
.footer__menu-5 li a .menu-text span:nth-child(47) {
  transition-delay: 2.35s;
}
.footer__menu-5 li a .menu-text span:nth-child(48) {
  transition-delay: 2.4s;
}
.footer__menu-5 li a .menu-text span:nth-child(49) {
  transition-delay: 2.45s;
}
.footer__menu-5 li a:hover {
  --y: -4px;
}
.footer__menu-5 li a:hover span {
  --m: calc(16px * -1);
}
.footer__copyright-4 {
  color: var(--gray-2);
  font-size: 16px;
  font-weight: 400;
}
.footer__copyright-4 a {
  color: var(--black-2);
}
.footer__copyright-4 a:hover {
  color: var(--gray);
}

/*----------------------------------------*/
/*  38. FOOTER CSS START
/*----------------------------------------*/
.footer__area {
  overflow: hidden;
}
.footer__area-2 {
  background-image: url("/imgs/shape/4.png");
  background-repeat: no-repeat;
  background-position: top right;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__area-2 {
    background-size: 380px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__area-2 {
    background-size: 350px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__area-2 {
    background-size: 200px;
  }
}
.footer__area-3 {
  background-color: var(--black-2);
}
.footer__row {
  margin-left: -500px;
}
@media only screen and (max-width: 767px) {
  .footer__row {
    margin-left: 0;
    margin-right: 0;
  }
}
.footer__top {
  text-align: right;
}
.footer__top img {
  margin-bottom: -380px;
  height: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__top img {
    margin-bottom: -300px;
    width: 90%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__top img {
    margin-bottom: -250px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .footer__top img {
    margin-bottom: 0;
    width: 100%;
  }
}
.footer__top-2 {
  padding-bottom: 70px;
}
@media only screen and (max-width: 767px) {
  .footer__top-2 {
    padding-bottom: 50px;
  }
}
.footer__top-3 {
  border-bottom: 1px solid var(--black-6);
}
.footer__top-wrapper-3 {
  padding: 0 50px;
  display: grid;
  grid-template-columns: 30% 10% auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__top-wrapper-3 {
    grid-template-columns: 35% 15% auto;
    gap: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__top-wrapper-3 {
    grid-template-columns: 35% 20% auto;
    gap: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__top-wrapper-3 {
    grid-template-columns: 35% 25% auto;
  }
}
@media only screen and (max-width: 767px) {
  .footer__top-wrapper-3 {
    padding: 0 15px;
    grid-template-columns: 1fr;
  }
}
.footer__middle-2 {
  border-top: 1px solid var(--white-2);
  border-bottom: 1px solid var(--white-2);
  padding: 95px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__middle-2 {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer__middle-2 {
    padding: 50px 0;
  }
}
.footer__location-2 {
  display: flex;
  gap: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__location-2 {
    gap: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__location-2 {
    flex-direction: column;
    gap: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__location-2 {
    flex-direction: column;
    gap: 30px;
  }
}
.footer__location-2 .location h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__btm {
    padding-left: 55px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__btm {
    padding-left: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__btm {
    background-color: var(--black-2);
  }
  .footer__btm .container {
    padding: 0;
    background-color: var(--black-2);
  }
}
.footer__btm-2 {
  padding: 50px 0;
}
@media only screen and (max-width: 767px) {
  .footer__btm-2 {
    padding: 30px 0;
  }
  .footer__btm-2 .row {
    flex-direction: column-reverse;
  }
}
.footer__btm-3 {
  padding: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__btm-3 {
    padding: 40px 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__btm-3 {
    padding: 40px 15px;
  }
  .footer__btm-3 .row {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 767px) {
  .footer__btm-3 {
    padding: 30px 10px;
  }
  .footer__btm-3 .row {
    flex-direction: column-reverse;
  }
}
.footer__inner {
  background-color: var(--black-2);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-left: 350px;
  position: relative;
  z-index: 9;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__inner {
    padding-left: 512px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__inner {
    padding-left: 450px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__inner {
    padding-top: 100px;
    padding-left: 410px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__inner {
    padding-left: 470px;
    padding-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__inner {
    background-color: transparent;
    padding-left: 0;
    padding-top: 60px;
    grid-template-columns: 1fr;
  }
}
.footer__logo {
  margin-bottom: 30px;
}
.footer__logo-3 img {
  margin-bottom: 30px;
}
.footer__logo-3 p {
  max-width: 310px;
  color: var(--gray-2);
}
.footer__sub-title {
  max-width: 850px;
  font-weight: 300;
  font-size: 36px;
  line-height: 1.3;
  color: var(--black-3);
  padding-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__sub-title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__sub-title {
    font-size: 24px;
  }
}
.footer__widget {
  grid-column: span 4;
  padding: 140px 0 130px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget {
    grid-column: 1/5;
    padding: 0 0 50px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget {
    grid-column: 1/6;
    padding: 0 0 50px 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer__widget {
    padding: 0 0 50px 0;
    grid-column: span 4;
  }
}
.footer__widget p {
  max-width: 310px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--gray-2);
  margin-bottom: 30px;
}
.footer__widget-title {
  font-size: 20px;
  line-height: 1.3;
  color: var(--white);
  padding-bottom: 20px;
}
.footer__widget-2 {
  grid-column: span 2;
  padding: 140px 0 130px;
  margin-left: -30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget-2 {
    grid-column: 6/9;
    padding: 0 0 50px 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget-2 {
    grid-column: 7/-1;
    padding: 0 0 50px 0;
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer__widget-2 {
    padding: 0 0 50px 0;
    grid-column: span 4;
    margin-left: 0;
  }
}
.footer__widget-3 {
  grid-column: span 2;
  padding: 140px 0 130px;
  margin-right: -30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget-3 {
    grid-column: 9/-1;
    padding: 0 50px 50px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget-3 {
    grid-column: span 6;
    padding: 50px 60px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer__widget-3 {
    padding: 0 0 50px 0;
  }
}
.footer__widget-4 {
  grid-column: span 4;
  grid-row: span 2;
  background-color: var(--black-4);
  padding: 135px 70px 30px;
  text-align: center;
  margin-left: 60px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__widget-4 {
    padding: 130px 30px 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__widget-4 {
    padding: 130px 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget-4 {
    grid-column: 7/-1;
    padding: 50px;
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget-4 {
    grid-column: span 6;
    padding: 50px 30px;
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer__widget-4 {
    padding: 30px;
    margin-left: 0;
  }
}
.footer__widget-4 .project-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 1.3;
  color: var(--white);
  padding-bottom: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__widget-4 .project-title {
    font-size: 30px;
  }
}
.footer__widget-4 a {
  color: var(--gray-2);
  border-color: var(--secondary);
  margin: 0 auto;
  margin-bottom: 20px;
}
.footer__widget-4 .contact-time {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  color: var(--white);
  padding-bottom: 3px;
}
.footer__widget-4 .contact-day {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: var(--gray-2);
}
.footer__social li {
  display: inline-block;
  padding-right: 15px;
}
.footer__social li a {
  display: inline-block;
  color: var(--gray-2);
  font-size: 20px;
}
.footer__social li a:hover {
  color: var(--white);
}
.footer__social-3 {
  border-left: 1px solid var(--black-6);
  border-right: 1px solid var(--black-6);
}
@media only screen and (max-width: 767px) {
  .footer__social-3 {
    margin-top: 50px;
    border-top: 1px solid var(--black-6);
    border-bottom: 1px solid var(--black-6);
  }
}
.footer__social-3 li {
  display: block;
  border-bottom: 1px solid var(--black-6);
}
.footer__social-3 li:last-child {
  border-bottom: 0;
}
.footer__social-3 li a {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  padding: 38px 0;
  background-color: transparent;
  transition: all 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__social-3 li a {
    padding: 30px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__social-3 li a {
    padding: 30px 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer__social-3 li a {
    padding: 30px 0;
  }
}
.footer__social-3 li a:hover {
  color: var(--black-2);
  background-color: var(--white);
}
.footer__link li {
  display: block;
  padding-bottom: 15px;
}
.footer__link li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-2);
  line-height: 18px;
}
.footer__link li a:hover {
  color: var(--white);
}
.footer__contact {
  display: block;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-2);
}
.footer__contact li {
  padding-bottom: 15px;
}
.footer__contact li span {
  font-size: 10px;
  display: block;
}
.footer__contact li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-2);
}
.footer__contact li a.phone {
  color: var(--white);
  position: relative;
}
.footer__contact li a.phone::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0px;
  background-color: var(--white);
  transition: all 0.3s;
}
.footer__contact li a.phone:hover::after {
  width: 0%;
}
.footer__contact li a:hover {
  color: var(--white);
}
.footer__contact-3 {
  text-align: right;
  padding-top: 90px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__contact-3 {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__contact-3 {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__contact-3 {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 767px) {
  .footer__contact-3 {
    text-align: center;
    margin-bottom: 50px;
    padding-top: 60px;
  }
}
.footer__contact-3 a {
  font-weight: 400;
  font-size: 180px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__contact-3 a {
    font-size: 140px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__contact-3 a {
    font-size: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__contact-3 a {
    font-size: 72px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__contact-3 a {
    font-size: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__contact-3 a {
    font-size: 50px;
  }
}
.footer__copyright {
  grid-column: span 4;
  border-top: 1px solid var(--gray-6);
  margin-left: -66px;
  padding: 35px 0 35px 66px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__copyright {
    margin-left: -35px;
    padding: 35px 0 35px 35px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__copyright {
    margin-left: -54px;
    padding: 35px 0 35px 54px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__copyright {
    border-top: 0;
    margin-left: 0;
    grid-column: 1/5;
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright {
    grid-column: 1/-1;
    text-align: center;
    border-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer__copyright {
    grid-column: 1/-1;
    grid-row: 7;
    text-align: center;
    border-top: 0;
    padding-top: 0;
  }
}
.footer__copyright p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-2);
  font-size: 14px;
  max-width: 233px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright p {
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .footer__copyright p {
    max-width: 100%;
  }
}
.footer__copyright p a {
  color: var(--white);
}
.footer__copyright p a:hover {
  color: var(--gray);
}
.footer__copyright-2 p {
  max-width: 235px;
}
@media only screen and (max-width: 767px) {
  .footer__copyright-2 p {
    max-width: 100%;
    padding-top: 50px;
    text-align: center;
  }
}
.footer__copyright-2 p a {
  color: var(--black-2);
}
.footer__copyright-2 p a:hover {
  color: var(--gray-2);
}
.footer__copyright-3 p {
  color: var(--white);
  max-width: 235px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright-3 p {
    max-width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .footer__copyright-3 p {
    max-width: 100%;
    text-align: center;
  }
}
.footer__copyright-3 p a {
  color: var(--white);
}
.footer__copyright-3 p a:hover {
  color: var(--gray-2);
}
.footer__copyright-8 {
  padding: 20px 100px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__copyright-8 {
    padding: 20px 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__copyright-8 {
    padding: 20px 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__copyright-8 {
    padding: 20px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright-8 {
    padding: 20px 15px;
  }
}
.footer__copyright-8 p {
  max-width: 235px;
  line-height: 1.4;
}
@media only screen and (max-width: 767px) {
  .footer__copyright-8 p {
    max-width: 100%;
    text-align: center;
  }
}
.footer__copyright-8 p a {
  color: var(--black-2);
}
.footer__copyright-8 p a:hover {
  color: var(--gray-2);
}
.footer__nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 767px) {
  .footer__nav {
    justify-content: center;
  }
}
.footer__nav-2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__nav-2 {
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  .footer__nav-2 {
    justify-content: center;
  }
}
.footer__subscribe {
  grid-column: span 4;
  border-top: 1px solid var(--gray-6);
  padding: 35px 0;
  margin-right: -60px;
  padding-right: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__subscribe {
    grid-column: 1/7;
    grid-row: 2;
    padding: 0 40px 0 0;
    border-top: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe {
    grid-column: span 6;
    grid-row: 3;
    border-top: 0;
    padding: 0 20px 0 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer__subscribe {
    margin-right: 0;
    padding-right: 0;
    border-top: 0;
  }
}
.footer__subscribe form {
  position: relative;
  max-width: 450px;
  width: 100%;
  margin-left: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe form {
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .footer__subscribe form {
    max-width: 100%;
  }
}
.footer__subscribe input {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--gray-4);
  background-color: var(--black-4);
  width: 100%;
  border: none;
  border-radius: 0;
  outline: none;
  padding: 21px 50px 21px 15px;
}
.footer__subscribe input::placeholder {
  opacity: 1;
  color: var(--gray-2);
}
.footer__subscribe .subs-btn {
  position: absolute;
  right: 20px;
  top: 18px;
  background-color: transparent;
  border: none;
  color: var(--white);
}
.footer__subscribe-2 {
  position: relative;
  padding-left: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe-2 {
    padding-left: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .footer__subscribe-2 {
    padding-left: 0;
    margin-top: 50px;
  }
}
.footer__subscribe-2::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 120px;
  left: -20px;
  top: -15px;
  background-color: var(--white-2);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe-2::before {
    height: 100%;
    top: 0;
  }
}
.footer__subscribe-2 input {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--black-2);
  font-weight: 300;
  font-size: 24px;
  line-height: 1.5;
  color: var(--black-2);
  padding: 20px 80px 20px 0;
  outline: none;
}
@media only screen and (max-width: 767px) {
  .footer__subscribe-2 input {
    font-size: 18px;
  }
}
.footer__subscribe-2 input::placeholder {
  color: var(--black-2);
  opacity: 1;
}
.footer__subscribe-2 form {
  position: relative;
}
.footer__subscribe-2 .submit {
  position: absolute;
  right: 0;
  top: 10px;
  background: transparent;
  border: none;
}
@media only screen and (max-width: 767px) {
  .footer__subscribe-2 .submit {
    top: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__subscribe-2 .submit img {
    width: 45px;
  }
}

footer #btn_wrapper,
footer .btn_wrapper {
  margin: 0 auto;
}

.contact_title span {
  padding-left: 30px;
}

.footer_categories {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-11);
  padding-bottom: 58px;
  margin: 0 100px;
}
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer_categories {
    margin: 0 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer_categories {
    margin: 0 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer_categories {
    margin: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_categories {
    margin: 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .footer_categories {
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    flex-wrap: wrap;
  }
}
.footer_categories::before {
  content: "";
  position: absolute;
  top: 12%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--gray-11);
}
@media only screen and (max-width: 767px) {
  .footer_categories::before {
    top: 14%;
  }
}
.footer_categories h4 {
  font-size: 16px;
  color: var(--black-2);
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item {
    width: 100%;
  }
}
.categories_item .cat_title {
  margin-bottom: 74px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item .cat_title {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .categories_item .cat_title {
    margin-bottom: 50px;
    margin-top: 30px;
  }
}
.categories_item input {
  background-color: transparent;
  padding: 5px 80px 20px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item input {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .categories_item input {
    padding: 15px 80px 15px 0;
  }
}
.categories_item .footer__subscribe-2 {
  padding-left: 0;
}
@media only screen and (max-width: 767px) {
  .categories_item .footer__subscribe-2 {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item .footer__subscribe-2 input {
    width: inherit;
  }
}
.categories_item .footer__subscribe-2::before {
  display: none;
}

.cat_links li {
  padding-bottom: 12px;
}
.cat_links li a {
  display: inline-block;
  padding: 1px 0;
  font-size: 24px;
  line-height: 16px;
  color: var(--black-2);
  text-transform: uppercase;
}
.cat_links li a .menu-text {
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-3);
}
.cat_links li a .menu-text span {
  display: block;
  backface-visibility: hidden;
  font-style: normal;
  transition: transform 0.4s ease;
  transform: translateY(var(--m)) translateZ(0);
}
.cat_links li a .menu-text span:nth-child(1) {
  transition-delay: 0.05s;
}
.cat_links li a .menu-text span:nth-child(2) {
  transition-delay: 0.1s;
}
.cat_links li a .menu-text span:nth-child(3) {
  transition-delay: 0.15s;
}
.cat_links li a .menu-text span:nth-child(4) {
  transition-delay: 0.2s;
}
.cat_links li a .menu-text span:nth-child(5) {
  transition-delay: 0.25s;
}
.cat_links li a .menu-text span:nth-child(6) {
  transition-delay: 0.3s;
}
.cat_links li a .menu-text span:nth-child(7) {
  transition-delay: 0.35s;
}
.cat_links li a .menu-text span:nth-child(8) {
  transition-delay: 0.4s;
}
.cat_links li a .menu-text span:nth-child(9) {
  transition-delay: 0.45s;
}
.cat_links li a .menu-text span:nth-child(10) {
  transition-delay: 0.5s;
}
.cat_links li a .menu-text span:nth-child(11) {
  transition-delay: 0.55s;
}
.cat_links li a .menu-text span:nth-child(12) {
  transition-delay: 0.6s;
}
.cat_links li a .menu-text span:nth-child(13) {
  transition-delay: 0.65s;
}
.cat_links li a .menu-text span:nth-child(14) {
  transition-delay: 0.7s;
}
.cat_links li a .menu-text span:nth-child(15) {
  transition-delay: 0.75s;
}
.cat_links li a .menu-text span:nth-child(16) {
  transition-delay: 0.8s;
}
.cat_links li a .menu-text span:nth-child(17) {
  transition-delay: 0.85s;
}
.cat_links li a .menu-text span:nth-child(18) {
  transition-delay: 0.9s;
}
.cat_links li a .menu-text span:nth-child(19) {
  transition-delay: 0.95s;
}
.cat_links li a .menu-text span:nth-child(20) {
  transition-delay: 1s;
}
.cat_links li a .menu-text span:nth-child(21) {
  transition-delay: 1.05s;
}
.cat_links li a .menu-text span:nth-child(22) {
  transition-delay: 1.1s;
}
.cat_links li a .menu-text span:nth-child(23) {
  transition-delay: 1.15s;
}
.cat_links li a .menu-text span:nth-child(24) {
  transition-delay: 1.2s;
}
.cat_links li a .menu-text span:nth-child(25) {
  transition-delay: 1.25s;
}
.cat_links li a .menu-text span:nth-child(26) {
  transition-delay: 1.3s;
}
.cat_links li a .menu-text span:nth-child(27) {
  transition-delay: 1.35s;
}
.cat_links li a .menu-text span:nth-child(28) {
  transition-delay: 1.4s;
}
.cat_links li a .menu-text span:nth-child(29) {
  transition-delay: 1.45s;
}
.cat_links li a .menu-text span:nth-child(30) {
  transition-delay: 1.5s;
}
.cat_links li a .menu-text span:nth-child(31) {
  transition-delay: 1.55s;
}
.cat_links li a .menu-text span:nth-child(32) {
  transition-delay: 1.6s;
}
.cat_links li a .menu-text span:nth-child(33) {
  transition-delay: 1.65s;
}
.cat_links li a .menu-text span:nth-child(34) {
  transition-delay: 1.7s;
}
.cat_links li a .menu-text span:nth-child(35) {
  transition-delay: 1.75s;
}
.cat_links li a .menu-text span:nth-child(36) {
  transition-delay: 1.8s;
}
.cat_links li a .menu-text span:nth-child(37) {
  transition-delay: 1.85s;
}
.cat_links li a .menu-text span:nth-child(38) {
  transition-delay: 1.9s;
}
.cat_links li a .menu-text span:nth-child(39) {
  transition-delay: 1.95s;
}
.cat_links li a .menu-text span:nth-child(40) {
  transition-delay: 2s;
}
.cat_links li a .menu-text span:nth-child(41) {
  transition-delay: 2.05s;
}
.cat_links li a .menu-text span:nth-child(42) {
  transition-delay: 2.1s;
}
.cat_links li a .menu-text span:nth-child(43) {
  transition-delay: 2.15s;
}
.cat_links li a .menu-text span:nth-child(44) {
  transition-delay: 2.2s;
}
.cat_links li a .menu-text span:nth-child(45) {
  transition-delay: 2.25s;
}
.cat_links li a .menu-text span:nth-child(46) {
  transition-delay: 2.3s;
}
.cat_links li a .menu-text span:nth-child(47) {
  transition-delay: 2.35s;
}
.cat_links li a .menu-text span:nth-child(48) {
  transition-delay: 2.4s;
}
.cat_links li a .menu-text span:nth-child(49) {
  transition-delay: 2.45s;
}
.cat_links li a:hover {
  --y: -4px;
}
.cat_links li a:hover span {
  --m: calc(16px * -1);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cat_links li a {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .cat_links li a {
    font-size: 16px;
  }
}

.categories_item.l_item {
  flex-basis: 36%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item.l_item .cat_title {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .categories_item.l_item {
    flex-basis: 100%;
  }
  .categories_item.l_item .cat_title {
    margin-top: 50px;
    margin-bottom: 0;
  }
}

/*----------------------------------------*/
/*  00. PRELOADER CSS START
/*----------------------------------------*/
@keyframes wcLoading {
  0% {
    transform: scaleY(0.1);
    background: var(--white);
  }
  50% {
    transform: scaleY(1);
    background: var(--primary);
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}
.preloader {
  width: 100%;
  height: 100vh;
  background-color: var(--black-2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.loading .bar {
  width: 6px;
  height: 60px;
  background: var(--white);
  display: inline-block;
  transform-origin: bottom center;
  animation: wcLoading 1.5s ease-in-out infinite;
}
.loading .bar1 {
  animation-delay: 0.1s;
}
.loading .bar2 {
  animation-delay: 0.2s;
}
.loading .bar3 {
  animation-delay: 0.3s;
}
.loading .bar4 {
  animation-delay: 0.4s;
}
.loading .bar5 {
  animation-delay: 0.5s;
}
.loading .bar6 {
  animation-delay: 0.6s;
}
.loading .bar7 {
  animation-delay: 0.7s;
}
.loading .bar8 {
  animation-delay: 0.8s;
}

.vertical-centered-box {
  text-align: center;
}
.vertical-centered-box:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}
.vertical-centered-box .content {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 0;
}

.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  margin-left: -60px;
  margin-top: -60px;
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
  animation: rotate 1.2s infinite linear;
}
.loader-line-mask .loader-line {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}