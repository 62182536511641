/*----------------------------------------*/
/*  03. SPACE CSS START
/*----------------------------------------*/
.pb-10{
  padding-bottom: 10px;
}
.pt-10{
  padding-top: 10px;
}
.pb-60{
  padding-bottom: 60px;
}
.pb-40{
  padding-bottom: 40px;
}
.pt-80{
  padding-top: 80px;
}

.pb-100 {
  padding-bottom: 100px;

  @media #{$lg} {
    padding-bottom: 90px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 60px;
  }
}

.pb-110 {
  padding-bottom: 110px;

  @media #{$lg} {
    padding-bottom: 90px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 60px;
  }
}

.pb-120 {
  padding-bottom: 120px;

  @media #{$lg} {
    padding-bottom: 90px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 60px;
  }
}

.pb-130 {
  padding-bottom: 130px;

  @media #{$lg} {
    padding-bottom: 90px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 60px;
  }
}

.pb-140 {
  padding-bottom: 140px;

  @media #{$lg} {
    padding-bottom: 90px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 60px;
  }
}

.pb-150 {
  padding-bottom: 150px;

  @media #{$lg} {
    padding-bottom: 90px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 60px;
  }
}

.pb-200 {
  padding-bottom: 200px;

  @media #{$lg} {
    padding-bottom: 90px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 60px;
  }
}

.pt-90 {
  padding-top: 90px;
  
    @media #{$sm} {
    padding-top: 20px;
  }
    @media #{$md} {
    padding-top: 50px;
  }
    @media #{$lg} {
        padding-top: 70px;
      }
}

.pt-42 {
  padding-top: 40px;
}

.pt-100 {
  padding-top: 100px;

  @media #{$sm} {
    padding-top: 60px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$lg} {
    padding-top: 90px;
  }
}

.pt-110 {
  padding-top: 110px;

  @media #{$sm} {
    padding-top: 60px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$lg} {
    padding-top: 90px;
  }
}

.pt-120 {
  padding-top: 120px;

  @media #{$sm} {
    padding-top: 60px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$lg} {
    padding-top: 90px;
  }
}

.pt-130 {
  padding-top: 130px;

  @media #{$sm} {
    padding-top: 60px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$lg} {
    padding-top: 90px;
  }
}

.pt-140 {
  padding-top: 140px;

  @media #{$sm} {
    padding-top: 60px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$lg} {
    padding-top: 90px;
  }
}

.pt-150 {
  padding-top: 150px;

  @media #{$sm} {
    padding-top: 60px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$lg} {
    padding-top: 90px;
  }
}

.sp-x {
  padding-left: 100px;
  padding-right: 100px;

  @media #{$lg} {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media #{$md} {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media #{$sm} {
    padding-left: 10px;
    padding-right: 10px;
  }

}

.mt-60 {
  margin-top: 60px;
}
.ml-60 {
  margin-left: 60% !important;
}
.ml-45 {
  margin-left: 45% !important;
}

.ht-200 {
  padding-top: 200px;

  @media #{$lg} {
    padding-top: 180px;
  }

  @media #{$md} {
    padding-top: 160px;
  }

  @media #{$sm} {
    padding-top: 130px;
  }
}
.pr-20{
padding-right: 20px;
}
.pr-40{
padding-right: 40px;
}
.pb-sm-0 {
 
  @media #{$sm} {
    padding-bottom: 0px;
  }
    @media #{$xs} {
    padding-bottom: 0px;
  }
}
.pb-sm-20 {
 
  @media #{$sm} {
    padding-bottom: 20px;
  }
    @media #{$xs} {
    padding-bottom: 20px;
  }
}