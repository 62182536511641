/*-----------------------------------------------------------------------------------

  Theme Name: Axtra - Digital Agency HTML5 Template
  Author: Crowdyflow
  Support: habib.crowdyflow@gmail.com
  Description: BUILDY – is a  Constructor HTML5 template that comes with high-quality 3 Pre build Home Pages and many built-in awesome inner pages  which are perfect for any creative agencies, designers, digital agencies, freelancers, developers, designers, marketing, startup, blog and portfolio.
  Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

  01. VARIBALES CSS START
  02. MIXIN CSS START
  03. SPACE CSS START
  04. ANIMATION CSS START
  05. THEME CSS START
  06. THEME DARK CSS START
  07. BUTTON CSS START
  08. TITLE CSS START
  09. MENU CSS START
  10. MODAL CSS START
  11. CURSOR CSS START
  12. HEADER CSS START
  13. OFFCANVAS CSS START
  14. HERO CSS START
  15. SLIDER CSS START
  16. ABOUT CSS START
  17. SERVICE CSS START
  18. COUNTER CSS START
  19. WORKFLOW CSS START
  20. BRAND CSS START
  21. TESTIMONIAL CSS START
  22. PORTFOLIO CSS START
  23. PORTFOLIO 2 CSS START
  24. BLOG CSS START
  25. CTA CSS START
  26. STORY CSS START
  27. TEAM CSS START
  28. SOLUTION CSS START
  29. DEVELOPMENT CSS START
  30. AWARD CSS START
  31. FAQ CSS START
  32. CAREER CSS START
  33. FEATURE CSS START
  34. CONTACT CSS START
  35. PRICE CSS START
  36. ERROR CSS START
  37. HOME 5 CSS START
  38. FOOTER CSS START
**********************************************/


/*----------------------------------------*/
/*  01. VARIBALES CSS START
/*----------------------------------------*/


// Colors
:root {
  --primary: #f7cb3c;
  --secondary: #2c2c2c;

  --black: #000;
  --black-2: #121212;
  --black-3: #555;
  --black-4: #1d1d1d;
  --black-5: #343434;
  --black-6: #262626;
  --black-7: #555555;
  --black-13: #666;

  --white: #fff;
  --white-2: #efefef;
  --white-3: #e9e9e9;
  --white-4: #f0f0f0;
  --white-5: #fbfbfb;
  --white-6: #d7d7d7;

  --gray: #c2c2c2;
  --gray-2: #999;
  --gray-3: #a8a8a8;
  --gray-4: #f6f6f6;
  --gray-5: #bbb;
  --gray-6: #2b2b2b;
  --gray-7: #b9b9b9;
  --gray-8: #8E8E8E;
  --gray-9: #aaa;
  --gray-10: #7c7c7c;
  --gray-11: #D9D9D9;
  --gray-12: #383838;
  --gray-13: #ccc;
  --gray-14: #dfdfdf;
  --gray-15: #C0C0C0;

  --pink: #FAEDE9;
  --pink-2: #FF9776;
  --pink-3: #F3ECEC;
  --pink-4: #FFFAF0;
  --pink-5: #E0E3CC;

  --bg-line: #1e1e1e;


  --d-gray: #6A6A6A;
  --d-black: #1A1A1A;
  --d-black-2: #171717;
  --d-black-3: #1A1A1A;
}

// Font Family | newYork
@font-face {
  font-family: newYork;
  src: url('/fonts/newyork/FontsFree-Net-NewYork.ttf');
  font-weight: 400;
}
@font-face {
  font-family: Poppins;
  src: url('/fonts/poppins/Poppins-Light.ttf');
  font-display: swap;

}
@font-face {
  font-family: "Poppins-regular";
  src: url('/fonts/poppins/Poppins-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-medium";
  src: url('/fonts/poppins/Poppins-Medium.ttf');
  font-weight: 400;
  font-display: swap;
}

// Font Family
// $font_primary: 'Kanit', sans-serif;
$font_primary: 'Poppins';
$font_primary_title: 'Poppins-medium';
$font_awesome: 'Font Awesome 6 Free';
$font_newyork: 'newYork';


// Responsive Variable
$xxxl: 'only screen and (min-width: 1920px)';
$xxl: 'only screen and (min-width: 1400px) and (max-width: 1919px)';
$xxl-1: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm: 'only screen and (max-width: 767px)';
$xs: '(max-width: 575px)';
$xxs: '(max-width: 359px)';