/*----------------------------------------*/
/*  18. COUNTER CSS START
/*----------------------------------------*/


.counter {

  &__area {
    overflow: hidden;

    .pt-150 {
      @media #{$lg} {
        padding-top: 100px;
      }
    }

    &-3 {

      @media #{$lg} {
        background-color: var(--pink-4);
        padding: 100px 0;
      }

      @media #{$md} {
        background-color: var(--pink-4);
        padding: 90px 0;
      }

      @media #{$sm} {
        background-color: var(--pink-4);
        padding: 60px 0;
      }

      .sec-title-wrapper {
        @media #{$xxl} {
          padding-top: 120px;
        }

        @media #{$xl} {
          padding-top: 120px;
        }
      }

      .sec-sub-title {
        font-weight: 500;
        color: var(--black);
        text-transform: uppercase;
        padding-bottom: 150px;

        @media #{$xxl} {
          padding-bottom: 60px;
        }

        @media #{$xl} {
          padding-bottom: 20px;
        }

        @media #{$lg} {
          display: none;
        }

        @media #{$md} {
          display: none;
        }

        @media #{$sm} {
          display: none;
        }
      }

      .counter__number {
        color: var(--black-2);
        font-weight: 400;
        padding-bottom: 10px;
      }
    }
  }

  &__wrapper {
    display: flex;

    @media #{$sm} {
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 30px 10px;
    }

    &-2 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      @media #{$sm} {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
      }
    }

    &-3 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      height: 100%;

      @media #{$sm} {
        align-items: flex-start;
        gap: 50px 40px;
      }
    }
  }

  &__item {
    width: 380px;
    height: 380px;
    border: 1px solid var(--white-2);
    border-radius: 100%;
    margin-left: -67px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media #{$xl} {
      margin-left: -60px;
      width: 330px;
      height: 330px;
    }

    @media #{$lg} {
      margin-left: -25px;
      width: 259px;
      height: 259px;
    }

    @media #{$md} {
      margin-left: -27px;
      width: 200px;
      height: 200px;
    }

    @media #{$sm} {
      margin-left: 0;
      width: 160px;
      height: 160px;
    }

    &:first-child {
      margin-left: 0;
    }

    p {
      font-size: 20px;
      text-align: center;

      @media #{$md} {
        font-size: 18px;
        line-height: 1.2;
      }

      @media #{$sm} {
        font-size: 16px;
        line-height: 1.3;
        padding: 0 7px;
      }
    }

    &-2 {
      text-align: center;

      p {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    &-3 {
      padding-bottom: 80px;

      @media #{$xxl} {
        padding-bottom: 0;
      }
      @media #{$xl} {
        padding-bottom: 0;
      }

      @media #{$md} {
        padding-bottom: 0;
      }

      @media #{$sm} {
        padding-bottom: 0;
        text-align: center;
      }

      p {
        font-size: 20px;

        @media #{$sm} {
          line-height: 1.2;
        }
      }
    }
  }

  &__number {
    font-family: "Poppins-regular";
    font-size: 80px;
    line-height: 1.1;
    color: var(--secondary);

    @media #{$lg} {
      font-size: 72px;
    }

    @media #{$md} {
      font-size: 48px;
    }

    @media #{$sm} {
      font-size: 40px;
    }
  }

  &__img {
    &-3 {
      margin-top: 80px;

      @media #{$xxl} {
        margin-top: 0;
        text-align: right;
      }

      @media #{$xl} {
        margin-top: 0;
        text-align: right;
      }

      @media #{$lg} {
        margin-top: 0;
      }

      @media #{$md} {
        margin-top: 0;
      }

      img {
        width: 100%;

        @media #{$xxl} {
          width: 80%;
        }

        @media #{$xl} {
          width: 75%;
        }
      }
    }
  }
}