/*----------------------------------------*/
/*  24. BLOG CSS START
/*----------------------------------------*/



.blog {
  &__area {
    overflow: hidden;

    .row {
      --bs-gutter-x: 60px;

      @media #{$xl} {
        --bs-gutter-x: 40px;
      }

      @media #{$lg} {
        --bs-gutter-x: 30px;
      }

      @media #{$md} {
        --bs-gutter-x: 20px;
      }
    }

    .sec-title-wrapper {
      text-align: center;
      padding-bottom: 60px;
    }

    &-2 {
      overflow: hidden;
      background-color: var(--black-2);

      .pt-150 {
        @media #{$lg} {
          padding-top: 150px;
        }
      }

      .pb-110 {
        @media #{$lg} {
          padding-bottom: 65px;
        }

        @media #{$md} {
          padding-bottom: 55px;
        }

        @media #{$sm} {
          padding-bottom: 0;
        }
      }

      .row {
        --bs-gutter-x: 60px;

        @media #{$xl} {
          --bs-gutter-x: 40px;
        }

        @media #{$lg} {
          --bs-gutter-x: 30px;
        }

        @media #{$md} {
          --bs-gutter-x: 20px;
        }
      }

      #btn_wrapper,
      .btn_wrapper {
        margin-left: auto;
        margin-right: -40px;
        margin-top: -60px;

        @media #{$lg} {
          margin-right: -10px;
        }

        @media #{$md} {
          margin-right: 0;
          margin-top: 0;
          margin-bottom: 60px;
        }

        @media #{$sm} {
          margin: 0;
          margin-top: 30px;
          margin-bottom: 50px;
        }
      }

      .sec {
        &-title {
          color: var(--white);
          padding-top: 10px;

          @media #{$md} {
            padding-top: 45px;
          }
        }

        &-text {
          padding-top: 30px;

          p {
            max-width: 340px;
            font-size: 18px;
            line-height: 1.4;
            color: var(--gray-2);
          }
        }

        &-btn {
          margin-bottom: 30px;

          a {
            margin-left: auto;

            @media #{$sm} {
              margin-left: 0;
            }
          }
        }
      }
    }

    &-3 {
      overflow: hidden;

      &.pb-140 {
        @media #{$md} {
          padding-bottom: 40px;
        }

        @media #{$sm} {
          padding-bottom: 20px;
        }
      }

      .sec {
        &-title {
          padding-bottom: 40px;
          text-transform: uppercase;
          background-color: var(--white);

          &-wrapper {
            position: relative;
            padding-left: 330px;
            margin-bottom: 60px;

            @media #{$md} {
              padding-left: 200px;
            }

            @media #{$sm} {
              padding-left: 0;
            }

            &::after {
              position: absolute;
              content: "";
              left: 39%;
              top: 0;
              width: 1px;
              height: 100%;
              background-color: var(--white-2);
            }

            p {
              padding-top: 70px;
              padding-bottom: 60px;
              max-width: 330px;
              font-size: 18px;
              line-height: 1.4;
              margin-left: 220px;

              @media #{$lg} {
                margin-left: 100px;
                padding-top: 0;
              }

              @media #{$md} {
                margin-left: 100px;
                padding-top: 0;
              }

              @media #{$sm} {
                margin-left: 0;
                padding-top: 0;
                padding-bottom: 0;
              }
            }
          }
        }

        &-sub-title {
          padding-top: 50px;
          text-transform: uppercase;
          background-color: var(--white);

          @media #{$md} {
            padding-top: 0;
          }

          @media #{$sm} {
            padding-top: 0;
          }
        }
      }
    }

    &-6 {
      overflow: hidden;

      .pb-110 {
        padding-bottom: 90px;

        @media #{$md} {
          padding-bottom: 25px;
        }

        @media #{$sm} {
          padding-bottom: 15px;
        }
      }

      .sec-title-wrapper {

        @media #{$md} {
          padding-top: 90px;
        }

        @media #{$sm} {
          padding-top: 60px;
        }
      }

      .blog__item {
        padding-bottom: 55px;
        position: relative;
        z-index: 1;
      }
    }
  }

  &__item {
    @include zi(5);

    @media #{$sm} {
      padding-bottom: 50px;
    }

    &:hover {
      .blog__img {
        transform: scale(1.3);
      }
    }

    &-2 {
      @extend .blog__item;
      text-align: center;

      @media #{$sm} {
        padding-bottom: 30px;
      }
    }

    &-3 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;

      @media #{$lg} {
        grid-template-columns: 2.5fr 1fr;
      }

      @media #{$md} {
        margin-bottom: 50px;
      }

      @media #{$sm} {
        grid-template-columns: 1fr;
        margin-bottom: 50px;
      }
    }
  }

  &__info {
    &-3 {
      position: absolute;
      width: 63%;
      bottom: -1px;
      right: 0;
      margin-left: -50px;
      background: var(--white);
      z-index: 1;
      padding: 40px;

      @media #{$xl} {
        width: 70%;
      }

      @media #{$lg} {
        width: 80%;
      }

      @media #{$sm} {
        width: 90%;
        margin-left: 0;
        padding: 30px 20px;
        border-top-left-radius: 5px;
      }
    }
  }

  &__img {
    width: 100%;
    transform: scale(1.2);
    transition: all 0.3s;

    &-3 {
      width: 100%;
    }

    &-wrapper {
      overflow: hidden;
      margin-bottom: 25px;
      position: relative;
      z-index: 9;
    }
  }

  &__meta {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    color: var(--black-3);
    margin-bottom: 10px;

    a {
      color: var(--black-3);
      transition: all 0.3s;

      &:hover {
        color: var(--secondary);
      }
    }

    &-2 {
      @extend .blog__meta;
      color: var(--gray-2);

      a {
        color: var(--gray-2);

        &:hover {
          color: var(--white);
        }
      }
    }
  }

  &__title {
    display: block;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.2;
    color: var(--black-2);
    border-bottom: 1px solid var(--gray-5);
    margin-bottom: 10px;
    padding-bottom: 20px;

    &:hover {
      color: var(--black-3);
    }

    @media #{$lg} {
      font-size: 22px;
    }

    @media #{$md} {
      font-size: 20px;
    }

    &-2 {
      @extend .blog__title;
      color: var(--white);
      border-bottom: 0;

      &:hover {
        color: var(--gray-2);
      }
    }

    &-3 {
      @extend .blog__title;
      padding-bottom: 50px;
    }
  }

  &__btn {
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    color: var(--black-3);
    text-transform: capitalize;

    span {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: -28px;

      i {
        transform: translate(0, 0) rotate(-45deg);
        transition: all 0.5s;
      }

      &::after {
        position: absolute;
        content: "\f061";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        left: -20px;
        bottom: -20px;
        transform: translate(0, 0) rotate(-45deg);
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
      }
    }

    &:hover {
      color: var(--secondary);

      i {
        transform: translate(20px, -20px) rotate(-45deg);
      }

      span::after {
        opacity: 1;
        visibility: visible;
        transform: translate(20px, -20px) rotate(-45deg);
      }
    }
  }


  &__text {
    display: flex;
    align-items: center;
    height: 100%;

    p {
      font-size: 18px;
      line-height: 1.4;

      @media #{$sm} {
        padding-top: 30px;
      }
    }
  }


  // Blog Detail
  &__detail {
    overflow: hidden;

    &-top {
      @include zi(1);

      @media #{$md} {
        padding-top: 90px;
      }

      @media #{$sm} {
        padding-top: 60px;
      }
    }

    &-date {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: var(--black-2);
      padding-bottom: 15px;

      span {
        color: var(--black-3);
        position: relative;
        padding-left: 25px;

        &::before {
          position: absolute;
          content: "";
          width: 4px;
          height: 4px;
          top: 11px;
          left: 8px;
          background-color: var(--gray-5);
          border-radius: 50px;
        }
      }
    }

    &-title {
      font-weight: 500;
      font-size: 80px;
      line-height: 1;
      color: var(--black-2);
      padding-bottom: 35px;

      @media #{$xxl} {
        font-size: 60px;
      }

      @media #{$xl} {
        font-size: 60px;
      }

      @media #{$lg} {
        font-size: 60px;
      }

      @media #{$md} {
        font-size: 48px;
      }

      @media #{$sm} {
        font-size: 36px;
      }
    }

    &-metalist {
      display: flex;
      gap: 50px;
      padding-bottom: 50px;
    }

    &-meta {
      display: flex;
      align-items: center;
      gap: 15px;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        color: var(--gray-8);

        span {
          display: block;
          color: var(--black-3);
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    &-thumb {
      @include img_anim(100%, 70vh, 100%);

      @media #{$sm} {
        padding-bottom: 40px;
              @include img_anim(100%, 30vh, 100%);
      }

      img {
        width: 100%;
      }
    }

    &-content {
      padding-top: 55px;
      padding-bottom: 30px;

      p {
        font-size: 18px;
        line-height: 1.4;
        padding-bottom: 25px;
        a{
          
          text-decoration: underline;
        }
      }

      img {
        margin-bottom: 30px;
        max-width: 100%;
      }

      h1,
      h2 {
        font-weight: 500;
        font-size: 36px;
        line-height: 1.2;
        color: var(--black);
        padding-bottom: 15px;
        padding-top: 10px;
      }

      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 1.2;
        color: var(--black);
        padding-bottom: 15px;
        padding-top: 10px;
      }

      h4 {
        font-weight: 500;
        font-size: 24px;
        line-height: 1.2;
        color: var(--black);
        padding-bottom: 15px;
        padding-top: 10px;
      }

      h5 {
        font-weight: 500;
        font-size: 20px;
        line-height: 1.2;
        color: var(--black);
        padding-bottom: 10px;
        padding-top: 10px;
      }

      h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        color: var(--black);
        padding-bottom: 10px;
        padding-top: 10px;
      }

      ul,
      ol {
        padding-bottom: 25px;

        li {
          font-weight: 400;
          font-size: 18px;
          line-height: 1.5;
          color: var(--black-2);
          padding-bottom: 5px;
          padding-left: 25px;
          position: relative;

          &::before {
            position: absolute;
            content: "+";
            left: 0;
            font-size: 20px;
          }
        }
      }
    }

    &-tags {
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;
        color: var(--black-2);
        text-transform: capitalize;

        a {
          color: var(--black-3);
          text-transform: lowercase;
          text-decoration-line: underline;
        }
      }
    }
  }

  &__related {
    overflow: hidden;

    .pb-140 {
      @media #{$sm} {
        padding-bottom: 0;
      }
    }

    .sec-title-wrapper {
      padding-bottom: 60px;
    }

    @media #{$lg} {
      .pb-130 {
        padding-bottom: 40px;
      }
    }

    @media #{$md} {
      .pb-130 {
        padding-bottom: 40px;
      }
    }

    @media #{$sm} {
      .pb-130 {
        padding-bottom: 0;
      }
    }
  }

  // Blog V2
  &-v2 {
    padding-bottom: 80px;

    @media #{$md} {
      padding-bottom: 30px;
    }

    @media #{$sm} {
      padding-bottom: 0;
    }

    .pt-150 {
      @media #{$md} {
        padding-top: 130px;
      }

      @media #{$sm} {
        padding-top: 130px;
      }
    }

    .sec-text p {
      padding-bottom: 70px;
      margin-left: auto;

      @media #{$md} {
        padding-bottom: 50px;
      }

      @media #{$sm} {
        padding-bottom: 40px;
      }
    }

    .blog__item-2 {
      margin-bottom: 50px;

      @media #{$md} {
        margin-bottom: 20px;
      }

      @media #{$sm} {
        margin-bottom: 0;
      }
    }
  }


  // 
  &-v3 {
    &.blog__area-3 .sec-title-wrapper::after {
      position: unset;
    }

    .line {
      padding-bottom: 70px;

      @media #{$lg} {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      @media #{$md} {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      @media #{$sm} {
        padding-top: 50px;
        padding-bottom: 30px;
      }
    }

    .blog__item-3 {
      margin-bottom: 80px;
      position: relative;
      z-index: 1;

      @media #{$md} {
        margin-bottom: 50px;
      }

      @media #{$sm} {
        margin-bottom: 50px;
      }
    }
  }
}



// Image Animation
.img-box {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;

  @media #{$xl} {
    height: 425px;
  }

  @media #{$lg} {
    height: 340px;
  }

  @media #{$md} {
    height: auto;
  }

  @media #{$sm} {
    height: auto;
  }


  img {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    transition: 0.5s;
    background-size: cover;

    @media (max-width: 1023px) {
      position: unset;
      width: 100%;
      height: auto;
    }

    &:nth-child(1) {
      transform: translatex(50%) scalex(2);
      opacity: 0;
      filter: blur(10px);

      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  &:hover {
    img:nth-child(2) {
      transform: translatex(-50%) scalex(2);
      opacity: 0;
      filter: blur(10px);

      @media (max-width: 1023px) {
        opacity: 1;
      }
         @media (max-width: 991px) {
       transform: translatex(0%) scalex(1.1);
      opacity: 1;
      filter: blur(0px);
      }
    }

    img:nth-child(1) {
      transform: translatex(0) scalex(1);
      opacity: 1;
      filter: blur(0);
    }
  }

}


// 
.row.reset-grid {
  --bs-gutter-x: 60px;

  @media #{$xl} {
    --bs-gutter-x: 40px;
  }

  @media #{$lg} {
    --bs-gutter-x: 30px;
  }

  @media #{$md} {
    --bs-gutter-x: 20px;
  }
}