/*----------------------------------------*/
/*  00. PRELOADER CSS START
/*----------------------------------------*/


@keyframes wcLoading {
  0% {
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}

.preloader {
  width: 100%;
  height: 100vh;
  background-color: var(--black-2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  .bar {
    width: 6px;
    height: 60px;
    background: var(--white);
    display: inline-block;
    transform-origin: bottom center;
    animation: wcLoading 1.5s ease-in-out infinite;
  }

  .bar1 {
    animation-delay: 0.1s;
  }

  .bar2 {
    animation-delay: 0.2s;
  }

  .bar3 {
    animation-delay: 0.3s;
  }

  .bar4 {
    animation-delay: 0.4s;
  }

  .bar5 {
    animation-delay: 0.5s;
  }

  .bar6 {
    animation-delay: 0.6s;
  }

  .bar7 {
    animation-delay: 0.7s;
  }

  .bar8 {
    animation-delay: 0.8s;
  }
}

 .vertical-centered-box {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    text-align: center;
    &:after {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    }
    .content {
       position: absolute;
         top: 50%;
  left: 50%;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      font-size: 0;
    }
  }



.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.1);
  margin-left: -60px;
  margin-top: -60px;
  // .animation(fade 1.2s infinite ease-in-out);
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(top, rgba(0,0,0,1), rgba(0,0,0,0));
  animation: rotate 1.2s infinite linear;
  .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.5);
  }
}





@keyframes rotate { 0% { transform: rotate(0deg);} 100% { transform: rotate(360deg);}}

@keyframes fade { 0% { opacity: 1;} 50% { opacity: 0.25;}}

@keyframes fade-in { 0% { opacity: 0;} 100% { opacity: 1;}}
